import { Typography } from "@mui/material";
import { FC } from "react";

export interface IHeaderTitleProps {
    variation: "page_header" | "panel_header" | "field_label" | "paragraph" | "sub_header",
    title: string;
}

export const HeaderTitle: FC<IHeaderTitleProps> = ({ variation, title }) => {

    const PageHeader = () => {
        return <Typography sx={{
            fontSize: "2.71428571rem",
            color: "textHeader.main"
        }}>{title}</Typography>
    }

    const PanelHeader = () => {
        return <Typography sx={{
            fontSize: "2rem",
            color: "textHeader.main"
        }}>{title}</Typography>
    }

    const FieldLabel = () => {
        return <Typography sx={{
            fontSize: "1.14rem",
            color: "textHeader.main"
        }}>{title}</Typography>
    }

    const Paragraph=()=>{
        return <Typography sx={{
            fontSize: "1rem",
            color: "textHeader.main"
        }}>{title}</Typography>
    }

    const SubHeader=()=>{
        return <Typography sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "textHeader.main"
        }}>{title}</Typography>
    }

    if (variation === "page_header") {
        return <PageHeader />
    } else if (variation === "panel_header") {
        return <PanelHeader />
    } else if(variation === "field_label") {
        return <FieldLabel />
    } else if(variation === "paragraph") {
        return <Paragraph />
    } else if(variation === "sub_header") {
        return <SubHeader />
    }

    return <>Header Title</>
}
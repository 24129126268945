import { Box, Card, Grid, ListItem } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { HeaderTitle } from "../header-title/header-title";

interface CollapsibleProps {
  defaultState?: boolean;
  title: string;
  children: React.ReactNode;
}

const Collapsible: FC<CollapsibleProps> = ({
  title,
  children,
  defaultState,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(defaultState ?? false);
  }, [defaultState]);

  return (
    <Grid>
      <ListItem
        onClick={toggleCollapsible}
        sx={{
          cursor: "pointer",
          backgroundColor: "adminMenuItem.main",
          borderBottom: "0.0714285714rem secondary.main #cccccc",
        }}
      >
        <HeaderTitle variation="sub_header" title={title} />
      </ListItem>
      <Box
        sx={{
          pl: 2,
        }}
      >
        {isOpen && children}
      </Box>
    </Grid>
  );
};

export default Collapsible;

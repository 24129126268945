import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseResponse, BaseResponseSingle } from "../../typings/interfaces";
import {
  IColorPintag,
  IServicePintag,
} from "../../typings/interfaces/pos/pintag";
import {
  CREATE_COLOR_PINTAG,
  CREATE_SERVICE_PINTAG,
  DELETE_COLOR_PINTAG,
  DELETE_SERVICE_PINTAG,
  GET_ALL_COLOR_PINTAGS,
  GET_ALL_SERVICE_PINTAGS,
  UPDATE_COLOR_PINTAG,
  UPDATE_COLOR_PINTAG_ORDER,
  UPDATE_SERVICE_PINTAG,
} from "../../configs";
import {
  ICreateColorPintagRequest,
  ICreateServicePintagRequest,
  IReOrderColorPintagRequest,
} from "../../typings/interfaces/requests/pintag-requests";

export const getAllColorPinTagsAction = createAsyncThunk(
  "pintag/color/all",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<IColorPintag>>(
        GET_ALL_COLOR_PINTAGS
      );
      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const createNewColorPinTagAction = createAsyncThunk(
  "pintag/color/create",
  async (
    { color, description, alias, orderIndex }: ICreateColorPintagRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<BaseResponseSingle<IColorPintag>>(
        CREATE_COLOR_PINTAG,
        { color, description, alias, orderIndex }
      );
      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const updateColorPinTagAction = createAsyncThunk(
  "pintag/color/update",
  async (
    { id, color, description, alias, orderIndex }: IColorPintag,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put<BaseResponseSingle<IColorPintag>>(
        UPDATE_COLOR_PINTAG,
        { id, color, description, alias, orderIndex }
      );
      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const deleteColorPinTagAction = createAsyncThunk(
  "pintag/color/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete<any>(DELETE_COLOR_PINTAG(id));
      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const reOrderColorPinTagAction = createAsyncThunk(
  "pintag/color/reorder",
  async (reOrder: IReOrderColorPintagRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<BaseResponse<IColorPintag>>(
        UPDATE_COLOR_PINTAG_ORDER,
        reOrder
      );
      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const createNewServicePinTagAction = createAsyncThunk(
  "pintag/service/create",
  async (
    { serviceTypeId, description, tagCount }: ICreateServicePintagRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<BaseResponseSingle<IServicePintag>>(
        CREATE_SERVICE_PINTAG,
        { serviceTypeId, description, tagCount }
      );
      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const updateServicePinTagAction = createAsyncThunk(
  "pintag/service/update",
  async (
    { id, serviceTypeId, description, tagCount }: IServicePintag,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put<BaseResponseSingle<IServicePintag>>(
        UPDATE_SERVICE_PINTAG,
        { id, serviceTypeId, description, tagCount }
      );
      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const deleteServicePinTagAction = createAsyncThunk(
  "pintag/service/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete<any>(DELETE_SERVICE_PINTAG(id));
      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const getAllServicePinTagsAction = createAsyncThunk(
  "pintag/service/all",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<IServicePintag>>(
        GET_ALL_SERVICE_PINTAGS
      );
      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

import { FC, useEffect, useState } from "react";
import CustomModal from "../../common/custom-modal/custom-modal";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import {
  getUserNotesByIdAction,
  addUserNoteAction,
} from "../../../redux/actions/customerAction";
import { CustomButton, CustomTextBox } from "../../atoms";
import { Colors } from "../../../configs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useParams } from "react-router-dom";
import {
  NoteOccasion,
  NoteOccasionLabels,
} from "../../../typings/enum/noteOccasions";
import UserNotesCard from "./userNotesCard";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";

export interface INotesModalProps {
  open: boolean;
  onClose(): void;
}

const UserNotesModal: FC<INotesModalProps> = ({ open, onClose }) => {
  const [newNote, setNewNote] = useState("");
  const [selectedOccations, setSelectedOccasions] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const params = useParams();
  const customerId = params.id;
  const { customerNotes, addUserNoteLoading } = useAppSelector(
    (state) => state.customer
  );
  const { user } = useAppSelector((state) => state.auth);
  const snackbar = useSnackbarContext();

  useEffect(() => {
    if (customerId) {
      dispatch(getUserNotesByIdAction(customerId as string));
    }
  }, [customerId]);

  const handleOnCheckBoxChange = (key: string) => {
    const index = selectedOccations.indexOf(key);
    if (index > -1) {
      setSelectedOccasions(selectedOccations.filter((item) => item !== key));
    } else {
      setSelectedOccasions([...selectedOccations, key]);
    }
  };

  const mapOccations = () => {
    return Object.keys(NoteOccasion).map((key) => (
      <FormControlLabel
        control={<Checkbox />}
        label={NoteOccasionLabels[key as keyof typeof NoteOccasion]}
        sx={{
          "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
          height: "1.55rem",
          width: "13rem",
        }}
        key={key} // You should also include a key for each mapped element
        value={key}
        checked={selectedOccations.includes(key)}
        onChange={() => handleOnCheckBoxChange(key)}
      />
    ));
  };

  const handleNewNoteTextChange = (note: string) => {
    setNewNote(note);
  };

  const handleOnAddNote = async () => {
    if (newNote.length > 0) {
      if (customerId && user) {
        await dispatch(
          addUserNoteAction({
            note: newNote,
            occasions: selectedOccations as NoteOccasion[],
            // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
            merchantUserId: 1,
            customerId: Number(customerId),
          })
        ).then((response) => {
          // if (response) {
          //   console.log(JSON.stringify(response));
          // } else {
          // }
        });
        setNewNote("");
        setSelectedOccasions([]);
        dispatch(getUserNotesByIdAction(customerId as string));
      }
    } else {
      snackbar.showSnackbar("Cannot add empty notes", "warning");
    }
  };

  return (
    <CustomModal
      title={"User Notes"}
      openModal={open}
      width="55.4464286rem"
      height="71.8571429rem"
      onClose={onClose}
    >
      <Box
        sx={{
          height: "34rem",
          overflowY: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          touchAction: "pan-y",
        }}
      >
        {/* <NotesCard
          description="lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          noteBy="John Doe"
          date={new Date()}
        />
        <NotesCard
          description="lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          noteBy="John Doe"
          date={new Date()}
        /> */}
        {addUserNoteLoading ? (
          <Box
            sx={{
              textAlign: "center",
              py: "14rem",
              fontSize: "1.8rem",
              opacity: 0.5,
            }}
          >
            <CircularProgress />
          </Box>
        ) : customerNotes == null || customerNotes.length === 0 ? (
          <Box
            sx={{
              textAlign: "center",
              py: "14rem",
              fontSize: "1.8rem",
              opacity: 0.5,
            }}
          >
            No notes for this user
          </Box>
        ) : (
          customerNotes.map((note) => (
            <UserNotesCard
              key={note.id}
              description={note.note}
              noteBy={`${note.reportedUserFirstName} ${note.reportedUserLastName}`}
              date={note.createdTime ? new Date(note.createdTime) : null}
              occasions={note.noteShowOccasion as NoteOccasion[]}
              noteId={note.id}
            />
          ))
        )}
      </Box>
      <Divider sx={{ marginTop: "1.42857143rem" }} />
      <Typography
        sx={{
          fontSize: "1.71428571rem",
          fontWeight: 600,
          color: Colors.TEXT_GRAY_DARK,
          marginTop: "1rem",
          marginBottom: "0.5rem",
        }}
      >
        Add new note
      </Typography>
      <CustomTextBox
        multiline
        sx={{
          width: "100%",
          "& .MuiInputBase-input": {
            height: "6.14285714rem",
            minHeight: "6.14285714rem",
            maxHeight: "6.14285714rem",
          },
        }}
        value={newNote}
        onChange={(event) => handleNewNoteTextChange(event.target.value)}
      />
      <Typography
        sx={{
          fontSize: "1.71428571rem",
          fontWeight: 600,
          color: Colors.TEXT_GRAY_DARK,
          marginTop: "1rem",
          marginBottom: "0.75rem",
        }}
      >
        When to show
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "1.75rem",
          rowGap: "0.38rem",
          paddingInline: "4.5rem", // Change according to the occassion names
        }}
      >
        {/* <FormControlLabel
          control={<Checkbox defaultChecked />}
          label={<span>Occasion 1</span>}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
            height: "1.55rem",
          }}
        />
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label={<span>Occasion 2</span>}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
            height: "1.55rem",
          }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label={<span>Occasion 3</span>}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
            height: "1.55rem",
          }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label={<span>Occasion 4</span>}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
            height: "1.55rem",
          }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label={<span>Occasion 5</span>}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
            height: "1.55rem",
          }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label={<span>Occasion 6</span>}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
            height: "1.55rem",
          }}
        /> */}
        {mapOccations()}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2rem",
        }}
      >
        <CustomButton
          sx={{
            background: Colors.PRIMARY,
            color: Colors.WHITE,
            textTransform: "capitalize",
            fontSize: "1.42857143rem",
            height: "3.85714286rem",
            width: "14.2857143rem",
          }}
          disabled={newNote.length === 0 || addUserNoteLoading}
          onClick={handleOnAddNote}
        >
          Add New Note
        </CustomButton>
      </Box>
    </CustomModal>
  );
};

export default UserNotesModal;

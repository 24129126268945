import { FC, useEffect, useState } from "react";
import { handleComponentClick } from "./styledTreeItem";
import { Box, FormControlLabel, Typography } from "@mui/material";
import IOSSwitch from "../custom-toggle-button/custom-toggle-button";
import dayjs, { Dayjs } from "dayjs";
import { Colors } from "../../../configs";
import StyledTreeItem from "./styledTreeItem";
import CustomDateTimePicker from "../custom-date-time-picker/custom-date-time-picker";
import getCheckOutDate from "../../../configs/checkoutDateRules";
import { formatCurrency } from "../../../util/common-functions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IStoreConfigResponse } from "../../../typings/interfaces/responses/store-config-response";

export interface ITreeParentNodeProps {
  children?: React.ReactNode;
  nodeID: string;
  service: string;
  special?: boolean;
  specialDate: Dayjs;
  specialTime: Dayjs;
  qty: number;
  total: number;
  onChangeSpecial(): void;
  onChangeSpecialDate(date: Dayjs): void;
  onChangeSpecialTime(date: Dayjs): void;
  masterSpecial: boolean;
  onClick?: () => void;
}

const TreeParentNode: FC<ITreeParentNodeProps> = ({
  children,
  nodeID,
  service,
  special = false,
  specialDate,
  specialTime,
  qty,
  total,
  onChangeSpecial,
  onChangeSpecialDate,
  onChangeSpecialTime,
  masterSpecial,
  onClick,
}) => {
  const dispatch = useAppDispatch();
  const getStoreConfigResponse = useAppSelector(
    (state) => state.store.getStoreConfigResponse
  );

  // const [specialDateValue, setSpecialDateValue] = useState<Dayjs>(specialDate);
  // const [specialTimeValue, setSpecialTimeValue] = useState<Dayjs>(specialTime);

  // useEffect(() => {
  //   setSpecialDateValue(specialDate);
  //   setSpecialTimeValue(specialTime);
  // }, [specialDate, specialTime]);

  const handleOnChangeSpecialDate = (date: Dayjs | null) => {
    let nxtDate;
    const checkInCutOffTimeString =
      getStoreConfigResponse?.body?.cutOffTime || "10:00:00";
    const pickupTimeString =
      getStoreConfigResponse?.body?.pickupTime || "15:00:00";

    const [
      checkInCutOffTimeHours,
      checkInCutOffTimeMinutes,
      checkInCutOffTimeSeconds,
    ] = checkInCutOffTimeString.split(":").map(Number);
    const checkInCutOffTimeDate = new Date();
    checkInCutOffTimeDate.setHours(
      checkInCutOffTimeHours,
      checkInCutOffTimeMinutes,
      checkInCutOffTimeSeconds,
      0
    );

    const [pickupTimeHours, pickupTimeMinutes, pickupTimeSeconds] =
      pickupTimeString.split(":").map(Number);
    const pickupTimeDate = new Date();
    pickupTimeDate.setHours(
      pickupTimeHours,
      pickupTimeMinutes,
      pickupTimeSeconds,
      0
    );

    if (date === null) {
      nxtDate = getCheckOutDate({
        isSpecialDay: special,
        checkInCutOffTime: checkInCutOffTimeDate,
        pickupTime: pickupTimeDate,
        workingDays: getStoreConfigResponse?.body?.workingDays ?? [],
        generalServiceDays: getStoreConfigResponse?.body?.daysToDeliver ?? 2,
        specialServiceDays:
          getStoreConfigResponse?.body?.specialDaysToDeliver ?? 0,
      });
    } else {
      nxtDate = date;
    }
    // setSpecialDateValue(nxtDate);
    onChangeSpecialDate(nxtDate);
  };
  const handleOnChangeSpecialTime = (time: Dayjs | null) => {
    let nxtTime;
    const checkInCutOffTimeString =
      getStoreConfigResponse?.body?.cutOffTime || "10:00:00";
    const pickupTimeString =
      getStoreConfigResponse?.body?.pickupTime || "15:00:00";

    const [
      checkInCutOffTimeHours,
      checkInCutOffTimeMinutes,
      checkInCutOffTimeSeconds,
    ] = checkInCutOffTimeString.split(":").map(Number);
    const checkInCutOffTimeDate = new Date();
    checkInCutOffTimeDate.setHours(
      checkInCutOffTimeHours,
      checkInCutOffTimeMinutes,
      checkInCutOffTimeSeconds,
      0
    );

    const [pickupTimeHours, pickupTimeMinutes, pickupTimeSeconds] =
      pickupTimeString.split(":").map(Number);
    const pickupTimeDate = new Date();
    pickupTimeDate.setHours(
      pickupTimeHours,
      pickupTimeMinutes,
      pickupTimeSeconds,
      0
    );
    if (time === null) {
      nxtTime = dayjs(
        getCheckOutDate({
          isSpecialDay: special,
          checkInCutOffTime: checkInCutOffTimeDate,
          pickupTime: pickupTimeDate,
          workingDays: getStoreConfigResponse?.body?.workingDays ?? [],
          generalServiceDays: getStoreConfigResponse?.body?.daysToDeliver ?? 2,
          specialServiceDays:
            getStoreConfigResponse?.body?.specialDaysToDeliver ?? 0,
        })
      );
    } else {
      nxtTime = time;
    }
    // setSpecialTimeValue(nxtTime);
    onChangeSpecialTime(nxtTime);
  };

  const handleOnChangeSpecial = () => {
    onChangeSpecial();
  };

  useEffect(() => {
    const checkInCutOffTimeString =
      getStoreConfigResponse?.body?.cutOffTime || "10:00:00";
    const pickupTimeString =
      getStoreConfigResponse?.body?.pickupTime || "15:00:00";

    const [
      checkInCutOffTimeHours,
      checkInCutOffTimeMinutes,
      checkInCutOffTimeSeconds,
    ] = checkInCutOffTimeString.split(":").map(Number);
    const checkInCutOffTimeDate = new Date();
    checkInCutOffTimeDate.setHours(
      checkInCutOffTimeHours,
      checkInCutOffTimeMinutes,
      checkInCutOffTimeSeconds,
      0
    );

    const [pickupTimeHours, pickupTimeMinutes, pickupTimeSeconds] =
      pickupTimeString.split(":").map(Number);
    const pickupTimeDate = new Date();
    pickupTimeDate.setHours(
      pickupTimeHours,
      pickupTimeMinutes,
      pickupTimeSeconds,
      0
    );
    handleOnChangeSpecialDate(
      getCheckOutDate({
        isSpecialDay: special,
        checkInCutOffTime: checkInCutOffTimeDate,
        pickupTime: pickupTimeDate,
        workingDays: getStoreConfigResponse?.body?.workingDays ?? [],
        generalServiceDays: getStoreConfigResponse?.body?.daysToDeliver ?? 2,
        specialServiceDays:
          getStoreConfigResponse?.body?.specialDaysToDeliver ?? 0,
      })
    );
  }, [special]);

  return (
    <StyledTreeItem
      nodeId={nodeID}
      label={
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.14285714rem",
              lineHeight: "1.37142857rem",
              color: Colors.BLACK,
              fontWeight: "bold",
              marginRight: "auto",
              width: "18rem",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {service}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.714285714rem",
            }}
            onClick={handleComponentClick}
          >
            <FormControlLabel
              checked={special}
              onChange={handleOnChangeSpecial}
              control={<IOSSwitch />}
              label={
                <Typography
                  sx={{
                    fontSize: "1.42857143rem",
                    lineHeight: "1.71428571rem",
                    fontWeight: 400,
                    mr: "0.964285714rem",
                  }}
                >
                  Special
                </Typography>
              }
              labelPlacement="start"
              sx={{ margin: 0, mr: "1.22214286rem" }}
            />
            <CustomDateTimePicker
              type="date"
              value={specialDate}
              onChange={handleOnChangeSpecialDate}
              disabled={masterSpecial && special}
            />
            <CustomDateTimePicker
              type="time"
              value={specialTime}
              onChange={handleOnChangeSpecialTime}
              disabled={masterSpecial && special}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "1.42857143rem",
              lineHeight: "1.71428571rem",
              color: Colors.TEXT_GRAY_DARK,
              // marginRight: "-5.1rem",
              marginRight: "4.7rem",
              marginLeft: "auto",
            }}
          >
            x{qty}
          </Typography>
          <Typography
            sx={{
              fontSize: "1.42857143rem",
              lineHeight: "1.71428571rem",
              color: Colors.TEXT_GRAY_DARK,
              width: "8.5rem",
              textAlign: "right",
            }}
          >
            {formatCurrency({
              amount: total,
              prefix: "$",
            })}
          </Typography>
        </Box>
      }
      type="parent"
      sx={{
        "& > .MuiTreeItem-content": {
          background: Colors.BACKGROUND_GRAY,
          width: "100%",
          height: "3.85714286rem",
          borderRadius: "0.428571429rem",
          display: "flex",
          alignItems: "center",
          padding: "1.07142857rem",
          border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
          "&:hover": {
            background: Colors.ACCENT_GRAY,
          },
        },
        "&[aria-expanded=true]>div:nth-child(2)": {
          marginBottom: "3.07142857rem",
        },
        "&[aria-expanded=false]>div:nth-child(2)": {
          marginBottom: "0.9998rem",
        },
      }}
      onClick={onClick}
    >
      {children}
    </StyledTreeItem>
  );
};

export default TreeParentNode;

import React, { useEffect, ReactNode, useState } from "react";
import { Colors } from "../configs";
import { Box, IconButton, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { CustomButton } from "../components";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CustomModal from "../components/common/custom-modal/custom-modal";

interface ResponsiveProviderProps {
  children: ReactNode;
}

const ResponsiveProvider: React.FC<ResponsiveProviderProps> = ({
  children,
}) => {
  const [showAspectRatioWarning, setShowAspectRatioWarning] = useState(false);
  const [showFullScreenWarning, setShowFullScreenWarning] = useState(false);

  const toggleFullScreen = () => {
    const element = document.body;

    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      }
    }
  };

  useEffect(() => {
    const adjustRootSizeAndDetectFullScreen = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const aspectRatio = width / height;
      const desiredAspectRatio = 16 / 9;

      // Detect if the screen is in fullscreen mode
      if (document.fullscreenElement) {
        setShowFullScreenWarning(false);
      } else {
        setShowFullScreenWarning(true);
      }

      // Define a small tolerance for floating-point precision
      const epsilon = 0.01;

      let newWidth, newHeight;

      // Check if the screen is 16:9
      if (Math.abs(aspectRatio - desiredAspectRatio) < epsilon) {
        newWidth = width;
        newHeight = height;
        setShowAspectRatioWarning(false);
      } else {
        // Calculate the largest 16:9 size that can fit in the screen
        if (aspectRatio > desiredAspectRatio) {
          newHeight = height;
          newWidth = height * desiredAspectRatio;
        } else {
          newWidth = width;
          newHeight = width / desiredAspectRatio;
        }
        document.fullscreenElement && setShowAspectRatioWarning(true);
      }

      // Calculate font size based on root's height
      const calculatedFontSize = newHeight * 0.012962963;

      // Apply styles to root and html
      const root = document.getElementById("root");
      if (root) {
        root.style.width = `${newWidth}px`;
        root.style.height = `${newHeight}px`;
        root.style.position = "absolute";
        root.style.top = "50%";
        root.style.left = "50%";
        root.style.transform = "translate(-50%, -50%)";
        root.style.background = Colors.WHITE;
      }

      document.documentElement.style.fontSize = `${calculatedFontSize}px`;
      document.documentElement.style.overflow = "hidden";
      document.body.style.background = Colors.ACCENT_GRAY_DISABLED;
    };

    // Event listener to detect fullscreen changes
    const handleFullscreenChange = () => {
      adjustRootSizeAndDetectFullScreen();
    };

    // Add event listeners
    adjustRootSizeAndDetectFullScreen();
    window.addEventListener("resize", adjustRootSizeAndDetectFullScreen);
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("keydown", (event) => {
      // this is here because the fullscreenchange event is not triggered when pressing F11
      if (event.key === "F11") {
        event.preventDefault();
        event.stopPropagation();
        toggleFullScreen();
      }
    });

    return () => {
      // Cleanup event listeners
      window.removeEventListener("resize", adjustRootSizeAndDetectFullScreen);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("keydown", (event) => {
        // this is here because the fullscreenchange event is not triggered when pressing F11
        if (event.key === "F11") {
          event.preventDefault();
          event.stopPropagation();
          toggleFullScreen();
        }
      });
    };
  }, []);

  return (
    <>
      {children}
      <CustomModal
        mode="popup"
        title=""
        onClose={() => setShowAspectRatioWarning(false)}
        openModal={showAspectRatioWarning}
        height="51.5rem"
        width="40rem"
        backdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(0.25rem)",
          },
        }}
      >
        <Box
          sx={{
            // background: Colors.WHITE,
            // borderRadius: "0.75rem",
            // padding: "5rem 3rem",
            // width: "38rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            alignItems: "center",
          }}
        >
          <ErrorIcon sx={{ fontSize: "12rem", color: Colors.SECONDARY }} />
          <Typography
            variant="h2"
            sx={{ fontWeight: 500, color: Colors.TEXT_GRAY_DARK }}
          >
            Warning
          </Typography>
          <Typography
            sx={{
              fontSize: "1.5rem",
              textAlign: "center",
              fontWeight: 500,
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            We recommend using 16:9 screen for better experience. in other
            aspect ratios, the UI might be not showing properly or will be
            cropped.
          </Typography>
          <CustomButton
            onClick={() => setShowAspectRatioWarning(false)}
            sx={{
              background: Colors.PRIMARY,
              mt: "2rem",
              fontSize: "2rem",
              padding: "0.75rem 3rem",
              borderRadius: "0.75rem",
            }}
          >
            Got it
          </CustomButton>
        </Box>
      </CustomModal>
      {/* <Box
        sx={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 99999999,
          background: "rgba(0, 0, 0, 0.6)",
          backdropFilter: "blur(0.25rem)",
          display: showAspectRatioWarning ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            background: Colors.WHITE,
            borderRadius: "0.75rem",
            padding: "5rem 3rem",
            width: "38rem",
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            alignItems: "center",
          }}
        >
          <ErrorIcon sx={{ fontSize: "12rem", color: Colors.SECONDARY }} />
          <Typography
            variant="h2"
            sx={{ fontWeight: 500, color: Colors.TEXT_GRAY_DARK }}
          >
            Warning
          </Typography>
          <Typography
            sx={{
              fontSize: "1.5rem",
              textAlign: "center",
              fontWeight: 500,
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            We recommend using 16:9 screen for better experience. in other
            aspect ratios, the UI might be not showing properly or will be
            cropped.
          </Typography>
          <CustomButton
            onClick={() => setShowAspectRatioWarning(false)}
            sx={{
              background: Colors.PRIMARY,
              mt: "2rem",
              fontSize: "2rem",
              padding: "0.75rem 3rem",
              borderRadius: "0.75rem",
            }}
          >
            Got it
          </CustomButton>
        </Box>
      </Box> */}

      <CustomModal
        mode="popup"
        title=""
        onClose={() => setShowFullScreenWarning(false)}
        openModal={showFullScreenWarning}
        width="40rem"
        height="30.8571429rem"
        backdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(0.25rem)",
          },
        }}
      >
        <Box
          sx={{
            // background: Colors.WHITE,
            // borderRadius: "0.75rem",
            // padding: "5rem 3rem",
            // width: "38rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {/* <ErrorIcon sx={{ fontSize: "12rem", color: Colors.SECONDARY }} /> */}
          <IconButton
            sx={{
              color: Colors.WHITE,
              background: Colors.ACCENT_GRAY_DISABLED,
              "&:hover": {
                background: Colors.ACCENT_GRAY_DISABLED,
              },
              alignSelf: "flex-end",
              mt: "-4rem",
              mr: "-1.5rem",
              mb: "3.65rem",
              width: "3rem",
              height: "3rem",
            }}
            onClick={() => setShowFullScreenWarning(false)}
          >
            <CloseIcon
              sx={{
                fontSize: "2.2rem",
              }}
            />
          </IconButton>
          <Typography
            sx={{
              fontSize: "1.5rem",
              textAlign: "center",
              fontWeight: 500,
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            Its recommended to use full screen mode for better experience.
          </Typography>
          <CustomButton
            onClick={toggleFullScreen}
            sx={{
              background: Colors.PRIMARY,
              mt: "2rem",
              fontSize: "1.8rem",
              padding: "0.75rem 3rem",
              borderRadius: "0.75rem",
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
            }}
          >
            <OpenInFullIcon
              sx={{
                fontSize: "1.8rem",
                ml: "-0.9rem",
              }}
            />{" "}
            Open Full Screen
          </CustomButton>
        </Box>
      </CustomModal>
      {/* <Box
        sx={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 99999999,
          background: "rgba(0, 0, 0, 0.4)",
          backdropFilter: "blur(0.1rem)",
          display: showFullScreenWarning ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            background: Colors.WHITE,
            borderRadius: "0.75rem",
            padding: "5rem 3rem",
            width: "38rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            alignItems: "center",
          }}
        >
          {// <ErrorIcon sx={{ fontSize: "12rem", color: Colors.SECONDARY }} />
          }
          <IconButton
            sx={{
              color: Colors.WHITE,
              background: Colors.ACCENT_GRAY_DISABLED,
              "&:hover": {
                background: Colors.ACCENT_GRAY_DISABLED,
              },
              alignSelf: "flex-end",
              mt: "-3rem",
              mr: "-1rem",
              mb: "2rem",
              width: "3rem",
              height: "3rem",
            }}
            onClick={() => setShowFullScreenWarning(false)}
          >
            <CloseIcon
              sx={{
                fontSize: "2.2rem",
              }}
            />
          </IconButton>
          <Typography
            sx={{
              fontSize: "1.5rem",
              textAlign: "center",
              fontWeight: 500,
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            Its recommended to use full screen mode for better experience.
          </Typography>
          <CustomButton
            onClick={toggleFullScreen}
            sx={{
              background: Colors.PRIMARY,
              mt: "2rem",
              fontSize: "1.8rem",
              padding: "0.75rem 3rem",
              borderRadius: "0.75rem",
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
            }}
          >
            <OpenInFullIcon
              sx={{
                fontSize: "1.8rem",
                ml: "-0.9rem",
              }}
            />{" "}
            Open Full Screen
          </CustomButton>
        </Box>
      </Box> */}
    </>
  );
};

export default ResponsiveProvider;

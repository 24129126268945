import { FC, useCallback, useEffect, useState } from "react";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../../configs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { DraggableCard } from "../../atoms/draggable-card/draggable-card";
import { CustomButton, CustomTextBox } from "../../atoms";
import { Height } from "@mui/icons-material";
import usePricing from "../../../hooks/usePricing";
import { sortArray } from "../../../util/array-functions";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { Controller, Resolver, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import {
  createNewServicePinTagAction,
  getAllServicePinTagsAction,
  updateServicePinTagAction,
} from "../../../redux/actions/pintagAction";
import { IServicePintag } from "../../../typings/interfaces/pos/pintag";
import { ITypeItem } from "../../../typings/interfaces/pos/typeItem";
import { CategoryResponse } from "../../../typings";
import { getCategoryAction } from "../../../redux/actions/pricingAction";

interface IAddServicePinTagDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  storeId: number;
  editMode?: boolean;
  populateData?: IServicePintag;
}

const schema = Yup.object().shape({
  serviceTypeId: Yup.number().required("Service is required"),
  description: Yup.string().required("Description is required"),
  tagCount: Yup.number().required("Tag Count is required").min(1, "Minimum 1"),
});
type FormData = Yup.InferType<typeof schema>;
const resolver: Resolver<FormData> = yupResolver(schema);

export const AddServicePinTagDrawer: FC<IAddServicePinTagDrawerProps> = ({
  open,
  toggleDrawer,
  storeId,
  editMode = false,
  populateData,
}) => {
  const [filteredServices, setFilteredServices] = useState<CategoryResponse[]>(
    []
  );
  const { categoryList } = usePricing();
  const { control, reset, setValue, handleSubmit } = useForm<FormData>({
    resolver,
  });
  const snackbar = useSnackbarContext();
  const dispatch = useAppDispatch();
  const {
    addServicePintagLoading,
    updateServicePintagLoading,
    getServicePintags,
  } = useAppSelector((state) => state.pintag);

  useEffect(() => {
    if (!getServicePintags?.body || !getServicePintags) {
      dispatch(getAllServicePinTagsAction());
    }
    if (!categoryList?.body || !categoryList) {
      dispatch(getCategoryAction());
    }
  }, []);

  useEffect(() => {
    if (getServicePintags?.body) {
      const addedServices =
        getServicePintags?.body?.map((item) => item.serviceTypeId) ?? [];
      const newServices =
        categoryList?.body.filter(
          (item: any) => !addedServices.includes(item.id) && item.showInMenu
        ) ?? [];
      setFilteredServices(newServices);
    }
  }, [categoryList, getServicePintags]);

  useEffect(() => {
    if (populateData) {
      setValue("serviceTypeId", populateData.serviceTypeId);
      setValue("description", populateData.description);
      setValue("tagCount", populateData.tagCount);
    }
  }, [editMode, populateData]);

  const handleOnSubmit = (data: FormData) => {
    if (editMode && populateData) {
      dispatch(
        updateServicePinTagAction({
          id: populateData?.id,
          serviceTypeId: data.serviceTypeId,
          description: data.description,
          tagCount: data.tagCount,
        })
      )
        .then(() => {
          snackbar.showSnackbar(
            "Service PinTag updated successfully",
            "success"
          );
          dispatch(getAllServicePinTagsAction());
          setValue("serviceTypeId", filteredServices[0]?.id ?? 0);
          setValue("description", "");
          setValue("tagCount", 0);
          toggleDrawer();
        })
        .catch((err) => snackbar.showSnackbar(err.message, "error"));
    } else {
      dispatch(
        createNewServicePinTagAction({
          serviceTypeId: data.serviceTypeId,
          description: data.description,
          tagCount: data.tagCount,
        })
      )
        .then(() => {
          snackbar.showSnackbar("Service PinTag added successfully", "success");
          dispatch(getAllServicePinTagsAction());
          setValue("serviceTypeId", filteredServices[0].id ?? 0);
          setValue("description", "");
          setValue("tagCount", 0);
          toggleDrawer();
        })
        .catch((err) => snackbar.showSnackbar(err.message, "error"));
    }
  };

  return (
    <CustomDrawer
      isOpen={open}
      toggleDrawer={toggleDrawer}
      sx={{
        width: "34.2857143rem",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "1.71428571rem",
      }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "3.57142857rem",
          height: "100%",
          flexGrow: 1,
        }}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <IconButton
          onClick={toggleDrawer}
          sx={{
            alignSelf: "flex-end",
            background: "none",
            border: `0.2rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            width: "2.5rem",
            height: "2.5rem",
          }}
        >
          <CloseIcon
            sx={{ fontSize: "1.8rem", color: Colors.ACCENT_GRAY_DISABLED }}
          />
        </IconButton>
        <Typography
          fontWeight={600}
          color={Colors.TEXT_GRAY_DARK}
          sx={{ fontSize: "2rem" }}
        >
          {editMode ? "Edit" : "Add New"}
          pintag book
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.71428571rem",
          }}
        >
          <Controller
            name="serviceTypeId"
            control={control}
            defaultValue={filteredServices[0]?.id}
            render={({ field, fieldState }) => (
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  fontSize: "1.25rem",
                }}
              >
                Service
                <Select
                  {...field}
                  error={fieldState.invalid}
                  input={<OutlinedInput label="Service" />}
                  label="Service"
                  sx={{
                    ".MuiSelect-select": {
                      padding: "0.65rem",
                      paddingLeft: "1.5rem",
                    },
                  }}
                >
                  {filteredServices.map((tab, i) => {
                    if (tab.showInMenu) {
                      return (
                        <MenuItem key={tab.id} value={tab.id}>
                          <ListItemText primary={tab.categoryName} />
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <MenuItem value={100000}>
                    <ListItemText primary={"Alteration"} />
                  </MenuItem>
                </Select>
              </label>
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  fontSize: "1.25rem",
                }}
              >
                Description
                <CustomTextBox
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              </label>
            )}
          />
          <Controller
            name="tagCount"
            control={control}
            render={({ field, fieldState }) => (
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  fontSize: "1.25rem",
                }}
              >
                Tag Count
                <CustomTextBox
                  type="number"
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              </label>
            )}
          />
        </Box>
        <CustomButton
          sx={{ mt: "auto", py: "0.8rem", fontSize: "1.4rem" }}
          type="submit"
        >
          {addServicePintagLoading || updateServicePintagLoading ? (
            <CircularProgress
              sx={{
                width: "1.5rem",
                height: "1.5rem",
                maxHeight: "1.5rem",
                maxWidth: "1.5rem",
                fontSize: "1.5rem",
              }}
            />
          ) : (
            "Save Pintag Book"
          )}
        </CustomButton>
      </form>
    </CustomDrawer>
  );
};

import { FC } from "react";
import { Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { Colors } from "../../../../configs/colors";
import usePricing from "../../../../hooks/usePricing";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartItem } from "../../../../typings/interfaces/pos/cart-item";
import { cloneDeep } from "lodash";
import {
  addCartItem,
  updateCartSessionItem,
} from "../../../../redux/actions/cartAction";
import { sortArray } from "../../../../util/array-functions";
import { ITypeItem } from "../../../../typings/interfaces/pos/typeItem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { CustomButton } from "../../../atoms";

export interface IGarmentStyleSectionProps {}

const GarmentStyleSection: FC<IGarmentStyleSectionProps> = ({}) => {
  const { getGarmentStyleList } = usePricing();
  const { selectedCartItem, selectedServiceType } = useAppSelector(
    (state) => state.cart
  );
  const dispatch = useAppDispatch();

  const garmentStyleBody =
    getGarmentStyleList(selectedCartItem?.garmentType?.id || 0) || [];
  const garmentStyleBodySorted = sortArray({
    array: garmentStyleBody,
    sortByKey: "name",
  });

  /* duplicate last item for ui testing purposes */
  // if (garmentStyleBodySorted && garmentStyleBodySorted.length > 0) {
  //   const duplicatedLastItem = { ...garmentStyleBodySorted[garmentStyleBodySorted.length - 1] };
  //   garmentStyleBodySorted.push(duplicatedLastItem);
  // }

  // const itemsPerColumn =
  //   (garmentStyleBodySorted?.length ?? 0) <= 12
  //     ? 4
  //     : Math.ceil((garmentStyleBodySorted?.length ?? 0) / 3);
  // const totalRows = Math.ceil(
  //   (garmentStyleBodySorted?.length || 0) / itemsPerColumn
  // );
  // let nextPrevCount: number;
  // let nextPrevLastIndex: number;

  const handleOnSelectType = (style: ITypeItem) => {
    let cartItem: CartItem;
    if (selectedCartItem) {
      cartItem = cloneDeep(selectedCartItem);
      cartItem.garmentStyle = style;
    } else {
      cartItem = {
        serviceType: selectedServiceType ? selectedServiceType : undefined,
        garmentType: undefined,
        materialType: undefined,
        texture: undefined,
        garmentStyle: style,
        garmentColor: undefined,
        qty: 1,
        itemId: uuidv4(),
        finalized: false,
        unitPrice: 0,
        instructions: [],
        damages: [],
        upcharges: [],
        spots: [],
        alterationItems: [],
        note: null,
      };
    }
    dispatch(updateCartSessionItem({ cartItem })).then(() => {
      if (cartItem.materialType && cartItem.garmentStyle) {
        dispatch(addCartItem());
      }
    });
  };

  return (
    <Box
      sx={{
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "73.78571rem",
        height: "13.1428571rem",
        boxSizing: "border-box",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "0.75rem",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "72rem",
          height: "10.6428571rem",
          overflowX: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          touchAction: "pan-x",
        }}
      >
        {[...Array(totalRows)].map((_, rowIndex) => {
          const previousItemsCount = nextPrevCount;
          const previousLastIndex = nextPrevLastIndex;

          const startingIndex =
            previousLastIndex !== undefined
              ? previousLastIndex
              : rowIndex * itemsPerColumn;
          const lastIndex = startingIndex + itemsPerColumn;
          const curentItemsCount =
            garmentStyleBodySorted?.slice(startingIndex, lastIndex).length || 0;

          const nextStartingIndex = lastIndex;
          const nextLastIndex = nextStartingIndex + itemsPerColumn;
          const nextItemsCount =
            garmentStyleBodySorted?.slice(nextStartingIndex, nextLastIndex)
              .length || 0;

          // const isPreviousShorter = previousLastIndex!==undefined && previousItemsCount!==0 && previousItemsCount < itemsPerColumn;

          const isNextShorter =
            nextItemsCount !== 0 && curentItemsCount - 1 > nextItemsCount;


          const currentRowItems = garmentStyleBodySorted
            ? garmentStyleBodySorted?.slice(
                // (isPreviousShorter? (startingIndex-1) : startingIndex),
                startingIndex,
                // (isNextShorter? lastIndex-(isPreviousShorter?2:1) : lastIndex-(isPreviousShorter?1:0))
                lastIndex
              )
            : [];

          nextPrevCount = curentItemsCount;
          nextPrevLastIndex = lastIndex;

          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1.0476190rem",
              }}
            >
              {currentRowItems?.map((style, i) => {
                return (
                  <CustomButton
                    sx={{
                      width: "17.21rem",
                      height: "3rem",
                      minWidth: "17.21rem",
                      minHeight: "3rem",
                      background:
                        selectedCartItem?.garmentStyle?.id === (style as any).id
                          ? Colors.SECONDARY
                          : Colors.MAIN_GRAY,
                      borderRadius: "0.71rem",
                      textTransform: "capitalize",
                      color: Colors.WHITE,
                      "&:hover": {
                        background: `${
                          selectedCartItem?.garmentStyle?.id ===
                          (style as any).id
                            ? Colors.SECONDARY
                            : Colors.MAIN_GRAY
                        } !important`,
                      },
                      fontSize: "1.14rem",
                      boxSizing: "border-box",
                      padding: "0",
                    }}
                    key={i}
                    onClick={() => handleOnSelectType(style as any)}
                  >
                    {(style as any).name}
                  </CustomButton>
                );
              })}
            </Box>
          );
        })}
      </Box> */}
      <Swiper
        style={{ width: "72rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(garmentStyleBodySorted.length / 12))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: "1.0476190rem",
                  rowGap: "0.75rem",
                  flexWrap: "wrap",
                  width: "72rem",
                  // height: "10.6428571rem",
                  // background: "red",
                }}
              >
                {garmentStyleBodySorted
                  .slice(swipePageIndex * 12, (swipePageIndex + 1) * 12)
                  .map((style, i) => (
                    // <CustomButton
                    //   sx={{
                    //     width: "4.79578571rem",
                    //     height: "2.541rem",
                    //     maxWidth: "4.79578571rem",
                    //     maxHeight: "2.541rem",
                    //     minWidth: "4.79578571rem",
                    //     minHeight: "2.541rem",
                    //     fontSize: "1rem",
                    //     background:
                    //       selectedCartItem?.texture === (texture as any).id
                    //         ? Colors.SECONDARY
                    //         : Colors.MAIN_GRAY,
                    //     borderRadius: "0.71rem",
                    //     textTransform: "capitalize",
                    //     color: Colors.WHITE,
                    //     "&:hover": {
                    //       background: `${
                    //         selectedCartItem?.texture === (texture as any).id
                    //           ? Colors.SECONDARY
                    //           : Colors.MAIN_GRAY
                    //       } !important`,
                    //     },
                    //     padding: "0",
                    //     boxSizing: "border-box",
                    //   }}
                    //   key={i}
                    //   onClick={() => handleOnSelectType((texture as any).id)}
                    // >
                    //   {(texture as any).label}
                    // </CustomButton>
                    <CustomButton
                      sx={{
                        width: "17.21rem",
                        height: "3rem",
                        minWidth: "17.21rem",
                        minHeight: "3rem",
                        background:
                          selectedCartItem?.garmentStyle?.id ===
                          (style as any).id
                            ? Colors.SECONDARY
                            : Colors.MAIN_GRAY,
                        borderRadius: "0.71rem",
                        textTransform: "capitalize",
                        color: Colors.WHITE,
                        "&:hover": {
                          background: `${
                            selectedCartItem?.garmentStyle?.id ===
                            (style as any).id
                              ? Colors.SECONDARY
                              : Colors.MAIN_GRAY
                          } !important`,
                        },
                        fontSize: "1.14rem",
                        boxSizing: "border-box",
                        padding: "0",
                      }}
                      key={i}
                      onClick={() => handleOnSelectType(style as any)}
                    >
                      {(style as any).name}
                    </CustomButton>
                  ))}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default GarmentStyleSection;

import { Box, Grid, IconButton } from "@mui/material";
import { FC } from "react";
import { HeaderTitle } from "../../atoms/header-title/header-title";
import { formatDistanceToNow } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../../configs";

interface INotificationItemProps {
  title: string;
  description: string;
  timestamp: Date | string | number;
  onClose(): void;
}

const NotificationItem: React.FC<INotificationItemProps> = ({
  title,
  description,
  timestamp,
  onClose,
}) => {
  const timeAgo = formatDistanceToNow(new Date(timestamp), {
    addSuffix: true,
  });
  return (
    <div
      style={{
        border: "0.0714285714rem solid #ccc",
        borderRadius: "0.357142857rem",
        padding: "1.14rem",
        marginBottom: "1.14rem",
        backgroundColor: "#fff",
        boxShadow: "0 0.142857143rem 0.285714286rem rgba(0,0,0,.1)",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ fontWeight: "bold", color: Colors.PRIMARY }}>{title}</div>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ marginTop: "0.57rem", marginBottom: "0.57rem" }}>
        {description}
      </div>
      <div style={{ fontSize: "1.142857143rem", color: "#888" }}>{timeAgo}</div>
    </div>
  );
};

export const NotificationDrawerContent: FC = () => {
  return (
    <Grid sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <HeaderTitle variation="panel_header" title="Notifications" />
      </Box>
      <Box sx={{ width: "100%" }}>
        <NotificationItem
          title="New order received"
          description="You have received a new order from John Doe for 2 items of dry cleaning."
          timestamp={new Date()}
          onClose={() => {}}
        />
        <NotificationItem
          title="Price alteration"
          description="The price of dry cleaningAfk has been updated to $10.00 per item."
          timestamp={new Date()}
          onClose={() => {}}
        />
      </Box>
    </Grid>
  );
};

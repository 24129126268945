import { createSlice } from "@reduxjs/toolkit";
import { BaseResponse, BaseResponseSingle } from "../../typings";
import {
  IColorPintag,
  IServicePintag,
} from "../../typings/interfaces/pos/pintag";
import {
  createNewColorPinTagAction,
  createNewServicePinTagAction,
  deleteColorPinTagAction,
  deleteServicePinTagAction,
  getAllColorPinTagsAction,
  getAllServicePinTagsAction,
  reOrderColorPinTagAction,
  updateColorPinTagAction,
  updateServicePinTagAction,
} from "../actions/pintagAction";

export interface PintagState {
  addColorPintag: BaseResponseSingle<IColorPintag> | null;
  addColorPintagLoading: boolean;
  addColorPintagError: string | null;

  getColorPintags: BaseResponse<IColorPintag> | null;
  getColorPintagsLoading: boolean;
  getColorPintagsError: string | null;

  updateColorPintag: BaseResponseSingle<IColorPintag> | null;
  updateColorPintagLoading: boolean;
  updateColorPintagError: string | null;

  deleteColorPintag: any | null;
  deleteColorPintagLoading: boolean;
  deleteColorPintagError: string | null;

  updateColorPintagOrder: any | null;
  updateColorPintagOrderLoading: boolean;
  updateColorPintagOrderError: string | null;

  addServicePintag: BaseResponseSingle<IServicePintag> | null;
  addServicePintagLoading: boolean;
  addServicePintagError: string | null;

  getServicePintags: BaseResponse<IServicePintag> | null;
  getServicePintagsLoading: boolean;
  getServicePintagsError: string | null;

  updateServicePintag: BaseResponseSingle<IServicePintag> | null;
  updateServicePintagLoading: boolean;
  updateServicePintagError: string | null;

  deleteServicePintag: any | null;
  deleteServicePintagLoading: boolean;
  deleteServicePintagError: string | null;
}

const initialState: PintagState = {
  addColorPintag: null,
  addColorPintagLoading: false,
  addColorPintagError: null,

  getColorPintags: null,
  getColorPintagsLoading: false,
  getColorPintagsError: null,

  updateColorPintag: null,
  updateColorPintagLoading: false,
  updateColorPintagError: null,

  deleteColorPintag: null,
  deleteColorPintagLoading: false,
  deleteColorPintagError: null,

  updateColorPintagOrder: null,
  updateColorPintagOrderLoading: false,
  updateColorPintagOrderError: null,

  addServicePintag: null,
  addServicePintagLoading: false,
  addServicePintagError: null,

  getServicePintags: null,
  getServicePintagsLoading: false,
  getServicePintagsError: null,

  updateServicePintag: null,
  updateServicePintagLoading: false,
  updateServicePintagError: null,

  deleteServicePintag: null,
  deleteServicePintagLoading: false,
  deleteServicePintagError: null,
};

const pintagSlice = createSlice({
  name: "pintag",
  initialState,
  reducers: {
    resetPintagState: (state) => {
      state.addColorPintag = null;
      state.addColorPintagLoading = false;
      state.addColorPintagError = null;

      state.getColorPintags = null;
      state.getColorPintagsLoading = false;
      state.getColorPintagsError = null;

      state.updateColorPintag = null;
      state.updateColorPintagLoading = false;
      state.updateColorPintagError = null;

      state.deleteColorPintag = null;
      state.deleteColorPintagLoading = false;
      state.deleteColorPintagError = null;

      state.updateColorPintagOrder = null;
      state.updateColorPintagOrderLoading = false;
      state.updateColorPintagOrderError = null;

      state.addServicePintag = null;
      state.addServicePintagLoading = false;
      state.addServicePintagError = null;

      state.getServicePintags = null;
      state.getServicePintagsLoading = false;
      state.getServicePintagsError = null;

      state.updateServicePintag = null;
      state.updateServicePintagLoading = false;
      state.updateServicePintagError = null;

      state.deleteServicePintag = null;
      state.deleteServicePintagLoading = false;
      state.deleteServicePintagError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Color Pintag
      .addCase(createNewColorPinTagAction.pending, (state) => {
        state.addColorPintag = null;
        state.addColorPintagLoading = true;
        state.addColorPintagError = null;
      })
      .addCase(createNewColorPinTagAction.fulfilled, (state, action) => {
        state.addColorPintagLoading = false;
        state.addColorPintag = action.payload.response;
        state.addColorPintagError = null;
      })
      .addCase(createNewColorPinTagAction.rejected, (state, action) => {
        state.addColorPintag = null;
        state.addColorPintagLoading = false;
        state.addColorPintagError = (action.payload as any).message;
      })

      .addCase(getAllColorPinTagsAction.pending, (state) => {
        state.getColorPintags = null;
        state.getColorPintagsLoading = true;
        state.getColorPintagsError = null;
      })
      .addCase(getAllColorPinTagsAction.fulfilled, (state, action) => {
        state.getColorPintagsLoading = false;
        state.getColorPintags = action.payload.response;
        state.getColorPintagsError = null;
      })
      .addCase(getAllColorPinTagsAction.rejected, (state, action) => {
        state.getColorPintags = null;
        state.getColorPintagsLoading = false;
        state.getColorPintagsError = (action.payload as any).message;
      })
      .addCase(updateColorPinTagAction.pending, (state) => {
        state.updateColorPintag = null;
        state.updateColorPintagLoading = true;
        state.updateColorPintagError = null;
      })
      .addCase(updateColorPinTagAction.fulfilled, (state, action) => {
        state.updateColorPintagLoading = false;
        state.updateColorPintag = action.payload.response;
        state.updateColorPintagError = null;
      })
      .addCase(updateColorPinTagAction.rejected, (state, action) => {
        state.updateColorPintag = null;
        state.updateColorPintagLoading = false;
        state.updateColorPintagError = (action.payload as any).message;
      })
      .addCase(deleteColorPinTagAction.pending, (state) => {
        state.deleteColorPintag = null;
        state.deleteColorPintagLoading = true;
        state.deleteColorPintagError = null;
      })
      .addCase(deleteColorPinTagAction.fulfilled, (state, action) => {
        state.deleteColorPintagLoading = false;
        state.deleteColorPintag = action.payload.response;
        state.deleteColorPintagError = null;
      })
      .addCase(deleteColorPinTagAction.rejected, (state, action) => {
        state.deleteColorPintag = null;
        state.deleteColorPintagLoading = false;
        state.deleteColorPintagError = (action.payload as any).message;
      })
      .addCase(reOrderColorPinTagAction.pending, (state) => {
        state.updateColorPintagOrder = null;
        state.updateColorPintagOrderLoading = true;
        state.updateColorPintagOrderError = null;
      })
      .addCase(reOrderColorPinTagAction.fulfilled, (state, action) => {
        state.updateColorPintagOrderLoading = false;
        state.updateColorPintagOrder = action.payload.response;
        state.updateColorPintagOrderError = null;
      })
      .addCase(reOrderColorPinTagAction.rejected, (state, action) => {
        state.updateColorPintagOrder = null;
        state.updateColorPintagOrderLoading = false;
        state.updateColorPintagOrderError = (action.payload as any).message;
      })
      // Service Pintag
      .addCase(createNewServicePinTagAction.pending, (state) => {
        state.addServicePintag = null;
        state.addServicePintagLoading = true;
        state.addServicePintagError = null;
      })
      .addCase(createNewServicePinTagAction.fulfilled, (state, action) => {
        state.addServicePintagLoading = false;
        state.addServicePintag = action.payload.response;
        state.addServicePintagError = null;
      })
      .addCase(createNewServicePinTagAction.rejected, (state, action) => {
        state.addServicePintag = null;
        state.addServicePintagLoading = false;
        state.addServicePintagError = (action.payload as any).message;
      })
      .addCase(getAllServicePinTagsAction.pending, (state) => {
        state.getServicePintags = null;
        state.getServicePintagsLoading = true;
        state.getServicePintagsError = null;
      })
      .addCase(getAllServicePinTagsAction.fulfilled, (state, action) => {
        state.getServicePintagsLoading = false;
        state.getServicePintags = action.payload.response;
        state.getServicePintagsError = null;
      })
      .addCase(getAllServicePinTagsAction.rejected, (state, action) => {
        state.getServicePintags = null;
        state.getServicePintagsLoading = false;
        state.getServicePintagsError = (action.payload as any).message;
      })
      .addCase(updateServicePinTagAction.pending, (state) => {
        state.updateServicePintag = null;
        state.updateServicePintagLoading = true;
        state.updateServicePintagError = null;
      })
      .addCase(updateServicePinTagAction.fulfilled, (state, action) => {
        state.updateServicePintagLoading = false;
        state.updateServicePintag = action.payload.response;
        state.updateServicePintagError = null;
      })
      .addCase(updateServicePinTagAction.rejected, (state, action) => {
        state.updateServicePintag = null;
        state.updateServicePintagLoading = false;
        state.updateServicePintagError = (action.payload as any).message;
      })
      .addCase(deleteServicePinTagAction.pending, (state) => {
        state.deleteServicePintag = null;
        state.deleteServicePintagLoading = true;
        state.deleteServicePintagError = null;
      })
      .addCase(deleteServicePinTagAction.fulfilled, (state, action) => {
        state.deleteServicePintagLoading = false;
        state.deleteServicePintag = action.payload.response;
        state.deleteServicePintagError = null;
      })
      .addCase(deleteServicePinTagAction.rejected, (state, action) => {
        state.deleteServicePintag = null;
        state.deleteServicePintagLoading = false;
        state.deleteServicePintagError = (action.payload as any).message;
      });
  },
});

export default pintagSlice.reducer;
export const pintagSliceActions = pintagSlice.actions;

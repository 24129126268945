import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { CustomButton, PosLayout } from "../../../components";
import LoaderModal from "../../../components/atoms/loader-modal/loader-modal";
import { set } from "lodash";
import useBarcodeScanner from "../../../hooks/useBarcodeScanner";
import { Colors } from "../../../configs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getAllRackBillsAction,
  registerRackBillAction,
} from "../../../redux/actions/rackAction";
import { AllRackBillsResponse } from "../../../typings/interfaces/responses";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";

export interface IBagRegisterPageProps {
  // Define your props here
}

// export interface IBagRack {
//   id: number;
//   date: string;
//   rackNumber: string;
//   billId: number;
// }

const BagRackRegisterPage: React.FC<IBagRegisterPageProps> = (props) => {
  // const bagRacks: IBagRack[] = [
  //   { id: 1, date: "2022-01-01", rackNumber: "A1", billId: 1001 },
  //   { id: 2, date: "2022-01-02", rackNumber: "B2", billId: 1002 },
  //   // Add more bag racks here
  // ];

  const bagCodeRef = useRef<HTMLInputElement>(null);
  const rackCodeRef = useRef<HTMLInputElement>(null);

  const [bagCode, setBagCode] = useState("");
  const [rackCode, setRackCode] = useState("");
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [bagRacks, setBagRacks] = useState<AllRackBillsResponse[]>([]);

  const dispatch = useAppDispatch();
  const { getAllRackBillsLoading, registerRackBillLoading } = useAppSelector(
    (state) => state.rack
  );
  const snackbar = useSnackbarContext();

  const loadData = () => {
    dispatch(getAllRackBillsAction()).then((res: any) => {
      if (res?.payload?.response?.status === "SUCCESS") {
        setBagRacks(res?.payload?.response?.body);
      } else {
        snackbar.showSnackbar(res?.payload?.response?.message, "error");
      }
    });
  };
  useEffect(() => {
    loadData();
  }, []);

  // const isModalLoading = bagCode !== "" && rackCode !== "";

  const handleBagCodeKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && rackCodeRef.current) {
      rackCodeRef.current.focus();
    }
  };

  const handleRackKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      enterRow();
    }
  };

  const handleOnBagCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBagCode(event.target.value);
  };

  const handleOnRackCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRackCode(event.target.value);
  };

  const code = useBarcodeScanner();

  const enterRow = () => {
    if (bagCode && rackCode) {
      setIsModalLoading(true);
      setTimeout(() => {
        // setBagRacks([
        //   ...bagRacks,
        //   {
        //     id: bagRacks.length + 1,
        //     date: new Date().toISOString().split("T")[0],
        //     rackNumber: rackCode,
        //     billId: parseInt(bagCode),
        //   },
        // ]);
        dispatch(
          registerRackBillAction({
            rackId: parseInt(rackCode),
            billId: parseInt(bagCode),
          })
        ).then((res: any) => {
          if (res?.payload?.response?.status === "SUCCESS") {
            loadData();
          } else {
            snackbar.showSnackbar(res?.payload?.response?.message, "error");
          }
        });

        setBagCode("");
        setRackCode("");
        setIsModalLoading(false);
      }, 1000);
    } else {
      snackbar.showSnackbar(
        "Please enter both bag and rack code to proceed",
        "warning"
      );
    }
  };

  // useEffect(() => {
  //   if (bagCode && rackCode) {
  //     setIsModalLoading(true);
  //     setTimeout(() => {
  //       setIsModalLoading(false);
  //       // enterRow();
  //     }, 1000);
  //   }
  // }, [bagCode, rackCode]);

  useEffect(() => {
    if (code) {
      let extractedCode = code.split("000");
      if (extractedCode[0] === "13") {
        setRackCode(extractedCode[1]);
      } else if (extractedCode[0] === "11") {
        setBagCode(extractedCode[1]);
      }
    }
    if (bagCode && rackCode) {
      enterRow();
    }
  }, [code]);

  return (
    <PosLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2.5rem",
        }}
      >
        <LoaderModal open={isModalLoading} />
        <Box
          sx={{
            background: Colors.BACKGROUND_GRAY,
            border: `0.03rem solid ${Colors.ACCENT_GRAY}`,
            padding: "2rem 3rem",
            borderRadius: "0.8rem",
            display: "flex",
            gap: "1rem",
          }}
        >
          <TextField
            onChange={handleOnBagCodeChange}
            label="Bag Code"
            inputRef={bagCodeRef}
            onKeyDown={handleBagCodeKeyPress}
            value={bagCode}
            type="number"
            sx={{
              "& input": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
              },
            }}
          />
          <TextField
            onChange={handleOnRackCodeChange}
            label="Rack Code"
            inputRef={rackCodeRef}
            onKeyDown={handleRackKeyPress}
            value={rackCode}
            type="number"
            sx={{
              "& input": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
              },
            }}
          />
          <CustomButton
            onClick={enterRow}
            sx={{
              background: Colors.SECONDARY,
              color: Colors.WHITE,
              textTransform: "Capitalize",
              padding: "1rem 1.5rem",
              fontSize: "1rem",
            }}
            disabled={registerRackBillLoading}
          >
            Add Record
          </CustomButton>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Rack Number</TableCell>
              <TableCell>Bill Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getAllRackBillsLoading || registerRackBillLoading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : null}
            {!(registerRackBillLoading || getAllRackBillsLoading) &&
              bagRacks.map((bagRack, i) => (
                <TableRow key={i}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>
                    {new Date(bagRack.billDate).toISOString().split("T")[0]}
                  </TableCell>
                  <TableCell>{bagRack.rackId}</TableCell>
                  <TableCell>{bagRack.billId}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </PosLayout>
  );
};

export default BagRackRegisterPage;

import { Box, Typography, SxProps, Theme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { PinTagButton } from "../../../atoms/pintag-button/pintag-button";
import { CustomTextBox } from "../../../atoms";
import { Colors } from "../../../../configs";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import {
  getAllColorPinTagsAction,
  getAllServicePinTagsAction,
} from "../../../../redux/actions/pintagAction";
import { IColorPintag } from "../../../../typings/interfaces/pos/pintag";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";
import { cloneDeep } from "lodash";
import { addSession } from "../../../../redux/actions/cartAction";

export interface IPinTagsSectionProps {
  sx?: SxProps<Theme>;
  itemsCount: number;
  serviceTypeId: number;
  sessionId: string;
  isPintagModalSection?: boolean;
}

export const PinTagsSection: FC<IPinTagsSectionProps> = ({
  sx = {},
  itemsCount,
  serviceTypeId,
  sessionId,
  isPintagModalSection = false,
}) => {
  const [sortedPinTags, setSortedPinTags] = useState<IColorPintag[]>([]);
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [textBoxCount, setTextBoxCount] = useState<number>(1);
  // const [sessionTagsLength, setSessionTagsLength] = useState<number>(0);

  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();

  const { getColorPintags, getServicePintags } = useAppSelector(
    (state) => state.pintag
  );
  const { drafts, cart, selectedSession, selectedServiceType } = useAppSelector(
    (state) => state.cart
  );
  const [serviceTagCount, setServiceTagCount] = useState<number>(1);

  useEffect(() => {
    const session = cart.find((session) => session.sessionId === sessionId);
    const selectedTagsLength = Math.ceil(
      (session?.cartItems.length ?? 1) / serviceTagCount
    );

    if (session) {
      setSelectedColor(session.pinTag?.color ?? null);
      selectedTagsLength !== 9 &&
        setSelectedTags(
          session.pinTag?.tags.concat(
            Array.from({ length: 9 - selectedTagsLength }).map(() => "")
          )
        );
      setTextBoxCount(selectedTagsLength);
    }
  }, [cart, serviceTagCount]);

  useEffect(() => {
    dispatch(getAllColorPinTagsAction()).catch((error) => {
      snackbar.showSnackbar(error.message, "error");
    });
    dispatch(getAllServicePinTagsAction()).catch((error) => {
      snackbar.showSnackbar(error.message, "error");
    });
  }, []);

  useEffect(() => {
    if ((getColorPintags?.body?.length ?? 0) > 0) {
      const sortedPinTags = [...(getColorPintags?.body ?? [])].sort(
        (a, b) => a.orderIndex - b.orderIndex
      );
      setSortedPinTags(sortedPinTags);
    }
  }, [getColorPintags]);
  useEffect(() => {
    if ((getServicePintags?.body?.length ?? 0) > 0) {
      const servicePintag = getServicePintags?.body.find(
        (servicePintag) => servicePintag.serviceTypeId === serviceTypeId
      );
      setServiceTagCount(servicePintag?.tagCount ?? 1);
    }
  }, [getServicePintags]);

  const handleAssignPinTag = (color: string | null, tags: string[]) => {
    let newSessionClone = cloneDeep(
      cart.find((session) => session.sessionId === sessionId)
    );
    if (newSessionClone) {
      newSessionClone.pinTag = {
        color: color,
        tags: tags,
      };
      dispatch(addSession(newSessionClone)).then(() => {
        snackbar.showSnackbar("Pin Tag assigned successfully", "success");
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
      }}
    >
      <Box sx={{ display: "flex", gap: "1.71428571rem" }}>
        <Typography
          sx={{
            width: "11rem",
            fontSize: "1.5rem",
            fontWeight: "bold",
            flexShrink: 0,
          }}
        >
          Color Code
        </Typography>
        <Box
          sx={{ display: "flex", gap: "0.8rem", flexWrap: "wrap", flexGrow: 1 }}
        >
          {sortedPinTags.map((pinTag) => {
            return (
              <PinTagButton
                key={pinTag.id}
                color={pinTag.color}
                code={pinTag.alias}
                isSelected={selectedColor === pinTag.alias}
                onClick={() => {
                  setSelectedColor(pinTag.alias);
                  handleAssignPinTag(pinTag.alias, selectedTags);
                }}
              />
            );
          })}
          {Array.from({ length: 10 - sortedPinTags.length }).map((_, index) => {
            return (
              <PinTagButton
                key={index}
                color={Colors.BACKGROUND_GRAY}
                code={"N/A"}
                isSelected={false}
                onClick={() => {}}
                disabled={true}
              />
            );
          })}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "1.71428571rem" }}>
        <Typography
          sx={{
            width: "11rem",
            fontSize: "1.5rem",
            fontWeight: "bold",
            flexShrink: 0,
          }}
        >
          Tag Number(s)
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "0.571428571rem",
            flexWrap: "wrap",
            justifyContent: isPintagModalSection
              ? "space-between"
              : "flex-start",
          }}
        >
          {Array.from({ length: 9 }).map((_, index) => {
            return (
              <CustomTextBox
                disabled={!(textBoxCount >= index + 1)}
                sx={{
                  width: "6.5rem",
                  "& input": {
                    backgroundColor: !(textBoxCount >= index + 1)
                      ? `${Colors.BACKGROUND_GRAY} !important`
                      : Colors.WHITE,
                  },
                  "& fieldset": {
                    border: !(textBoxCount >= index + 1)
                      ? `none !important`
                      : `0.19rem solid ${Colors.ACCENT_GRAY_DISABLED} !important`,
                  },
                  // "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  //   {
                  //     "-webkit-appearance": "none !important",
                  //     margin: 0,
                  //   },
                  // "& input[type=number]": {
                  //   MozAppearance: "textfield !important",
                  // },
                }}
                type="number"
                value={selectedTags[index] ?? ""}
                onChange={(e) => {
                  const newSelectedTags = [...selectedTags];
                  newSelectedTags[index] = e.target.value;
                  setSelectedTags(newSelectedTags);
                  handleAssignPinTag(selectedColor, newSelectedTags);
                }}
              />
            );
          })}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "1.71428571rem" }}>
        <Typography
          sx={{
            width: "11rem",
            fontSize: "1.5rem",
            fontWeight: "bold",
            flexShrink: 0,
          }}
        >
          Item Count
        </Typography>
        <CustomTextBox
          type="number"
          value={itemsCount}
          disabled
          sx={{
            flexGrow: 1,
            "& input": {
              backgroundColor: `${Colors.ACCENT_GRAY} !important`,
            },
          }}
        />
      </Box>
    </Box>
  );
};

import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import { CustomButton, CustomTextBox } from "../../components";
import { PosLayout } from "../../components/layouts/pos-layout/PosLayout";
import { Colors } from "../../configs";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  changePasswordAction,
  loginAction,
} from "../../redux/actions/authAction";

import { useTranslation } from "../../util/translation-provider";
import { IChangePasswordRequest } from "../../interfaces/requests/ChangePasswordRequest";
import { Location, useLocation } from "react-router-dom";
import { FC, useEffect } from "react";

const ChangePassword: FC = () => {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IChangePasswordRequest>();
  const { loading, loginResponse } = useAppSelector((state) => state.auth);
  const translation = useTranslation();
  const location = useLocation();

  const onSubmit: SubmitHandler<IChangePasswordRequest> = (data) => {
    dispatch(changePasswordAction(data));
  };

  useEffect(() => {
    console.log("Location: ", location);
  }, [location]);

  return (
    <PosLayout>
      <Grid display="flex" sx={{ height: "100%" }}>
        <Grid
          flex={7}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={translation.loginLogo} alt="Green" />
        </Grid>
        <Grid
          flex={5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box sx={{ backgroundColor: "#F8F8F8", borderRadius: "0.71rem" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid style={{ width: "33rem", padding: "3.42857143rem" }}>
                <Typography
                  sx={{
                    fontSize: "2.57142857rem",
                    lineHeight: "3rem",
                    color: "#4D4D4D",
                    textAlign: "center",
                  }}
                >
                  Change Password
                </Typography>

                <Grid sx={{ width: "100%", pt: "0.857142857rem" }}>
                  <label htmlFor="loginFormPass">
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: "#4D4D4D",
                        fontWeight: "bold",
                      }}
                    >
                      Old Password
                    </Typography>
                  </label>
                  <Controller
                    name="oldPassword"
                    control={control}
                    rules={{
                      required: {
                        message: "Old Password is required",
                        value: true,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <CustomTextBox
                        {...field}
                        error={fieldState.invalid}
                        type="password"
                        sx={{
                          width: "100%",
                          backgroundColor: Colors.WHITE,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid sx={{ width: "100%", pt: "0.857142857rem" }}>
                  <label htmlFor="loginFormPass">
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: "#4D4D4D",
                        fontWeight: "bold",
                      }}
                    >
                      New Password
                    </Typography>
                  </label>
                  <Controller
                    name="newPassword"
                    control={control}
                    rules={{
                      required: {
                        message: "New Password is required",
                        value: true,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <CustomTextBox
                        {...field}
                        error={fieldState.invalid}
                        type="password"
                        sx={{
                          width: "100%",
                          backgroundColor: Colors.WHITE,
                        }}
                      />
                    )}
                  />
                </Grid>
                {/* Email address */}
                <Controller
                  name="email"
                  defaultValue={location?.state.email}
                  control={control}
                  render={({ field, fieldState }) => (
                    <input {...field} type="hidden" />
                  )}
                />
                <Grid sx={{ py: "1.71428571rem" }}>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    sx={{
                      textTransform: "none",
                      width: "100%",
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Change Password"
                    )}
                  </CustomButton>
                </Grid>
                <Grid style={{ color: "red" }}>
                  {loginResponse?.status === "failed" && loginResponse?.message}
                </Grid>
                <Typography
                  sx={{
                    fontSize: "0.857142857rem",
                    color: Colors.BLACK,
                  }}
                >
                  In a case of forgotten password or difficulty in logging in
                  please contact your manager. If you are a manager,{" "}
                  <a style={{ textDecoration: "underline" }}>Click here</a> to
                  access the system admin panel.
                </Typography>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </PosLayout>
  );
};

export { ChangePassword };

import { FC, useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Colors } from "../../configs";
import { PosLayout } from "../../components";
import InfoSection from "../../components/pos/info-section/Info-section";
import ServicesSection from "../../components/pos/services-section/services-section";
import TopRightButton from "../../components/common/top-right-button/top-right-button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { CartSliceAction } from "../../redux/slice/cartSlice";

import { CustomerSliceAction } from "../../redux/slice/customerSlice";
import ConfirmDialog from "../../components/atoms/confirm-box/confirm-box";
import { IDraft } from "../../typings/interfaces/pos/draft";
import { formatPhoneNumber } from "../../util/common-functions";
import { useSnackbarContext } from "../../providers/SnackbarProvider";

export interface IPosDashboardPageProps {}

const PosDashboardPage: FC<IPosDashboardPageProps> = () => {
  const [draftConfirmationDialogOpen, setDraftConfirmationDialog] =
    useState<boolean>(false);
  const { drafts, cart } = useAppSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedCustomer } = useAppSelector((state) => state.cart);
  const { showSnackbar } = useSnackbarContext();

  const handleOnClickTopRight = () => {
    // let isEmptySession = true;
    // cart.forEach((session) => {
    //   if (session.cartItems?.filter((item) => item.finalized).length > 0) {
    //     isEmptySession = false;
    //   } else {
    //     isEmptySession = true;
    //     return;
    //   }
    // });
    let isNonEmptySession = false;
    cart.forEach((session) => {
      if (session.cartItems?.filter((item) => item.finalized).length > 0) {
        isNonEmptySession = true;
        return;
      } else {
        isNonEmptySession = false;
      }
    });
    if (isNonEmptySession) {
      setDraftConfirmationDialog(true);
    } else {
      navigate("/pos/lobby");
    }
    // setDraftConfirmationDialog(true);
  };

  useEffect(() => {
    return () => {
      dispatch(CustomerSliceAction.clearCustomer());
      dispatch(CustomerSliceAction.clearCustomerNotes());
    };
  }, []);

  const handleOnDraftConfirm = () => {
    if (selectedCustomer) {
      let finalizedSession = cart.map((session) => {
        return {
          ...session,
          cartItems: session.cartItems?.filter((item) => item.finalized),
        };
      });
      let finalizedCart = finalizedSession.filter(
        (session) => session.cartItems?.length
      );
      const finalizedDraft: IDraft = {
        draftId: drafts.length + 1,
        customer: selectedCustomer,
        draftSessions: finalizedCart,
        createdAt: new Date(),
        totalPieces: finalizedCart.reduce(
          (acc, session) =>
            acc + session.cartItems!.reduce((acc, item) => acc + item.qty, 0),
          0
        ),
        totalAmount: finalizedCart.reduce(
          (acc, session) =>
            acc +
            session.cartItems!.reduce(
              (acc, item) => acc + item.qty * item.unitPrice,
              0
            ),
          0
        ),
      };
      dispatch(CartSliceAction.setDrafts([...drafts, finalizedDraft]));
      navigate("/pos/lobby");
      dispatch(CartSliceAction.clearSession(null));
    } else {
      showSnackbar("Customer not found", "error");
    }
  };

  return (
    <PosLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          gap: "0.57rem",
        }}
      >
        <ConfirmDialog
          open={draftConfirmationDialogOpen}
          title="Exit Confirmation"
          message={
            <Typography>
              Do you want to save customer sessions as drafts?
            </Typography>
          }
          positiveButtonText="Save and exit"
          negativeButtonText="Cancel"
          onCancel={() => setDraftConfirmationDialog(false)}
          onConfirm={() => handleOnDraftConfirm()}
        />
        <InfoSection />
        <Box
          sx={{ width: "0.142857143rem", background: Colors.ACCENT_GRAY }}
        ></Box>
        <ServicesSection />
        <TopRightButton variation="Back" onClick={handleOnClickTopRight} />
      </Box>
    </PosLayout>
  );
};

export default PosDashboardPage;

import React, { FC, useEffect, useState } from "react";
import { CartItem } from "../../../typings/interfaces/pos/cart-item";
import CustomModal from "../../common/custom-modal/custom-modal";
import { Box, Typography } from "@mui/material";
import { Colors } from "../../../configs";
import { CustomButton } from "../../atoms";
import { updateCartSessionItem } from "../../../redux/actions/cartAction";
import { cloneDeep } from "lodash";
import { useAppDispatch } from "../../../hooks";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";

export interface IItemNoteModalProps {
  open: boolean;
  onClose(): void;
  cartItem: CartItem;
}

const ItemNoteModal: FC<IItemNoteModalProps> = ({
  open,
  onClose,
  cartItem,
}) => {
  const [note, setNote] = useState(cartItem.note || "");
  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();

  const addOrUpdateCartItemNote = async () => {
    if (note.length > 0) {
      if (cartItem.note !== note) {
        const tempCartItem = cloneDeep(cartItem);
        tempCartItem.note = note;
        await dispatch(updateCartSessionItem({ cartItem: tempCartItem }));
        onClose();
      } else {
        snackbar.showSnackbar("note must be edited before update", "warning");
      }
    } else {
      snackbar.showSnackbar("note can't be empty", "warning");
    }
  };
  useEffect(() => {
    setNote(cartItem.note || "");
  }, [cartItem.note, open]);

  const removeCartItemNote = async () => {
    const tempCartItem = cloneDeep(cartItem);
    tempCartItem.note = null;
    await dispatch(updateCartSessionItem({ cartItem: tempCartItem }));
    onClose();
  };

  return (
    <CustomModal
      title={"Cart Item Note"}
      openModal={open}
      width="45rem"
      height="35rem"
      onClose={onClose}
    >
      <Box
        sx={{
          paddingTop: "2rem",
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          <Typography sx={{ color: Colors.TEXT_GRAY_DARK, fontSize: "1.5rem" }}>
            Cart item note:
          </Typography>
          <textarea
            placeholder="Your note here"
            style={{
              border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              borderRadius: "0.714285714rem",
              padding: "0.6rem 0.8rem",
              fontSize: "1.14285714rem",
              height: "10rem",
              width: "100%",
              resize: "none",
            }}
            onChange={(e) => setNote(e.target.value)}
            value={note}
          ></textarea>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <CustomButton
            sx={{
              height: "3.85714286rem",
              width: "14.2857143rem",
              fontSize: "1.42857143rem",
              background: "none",
              color: Colors.TEXT_GRAY_DARK,
              border: `0.1114285714rem solid ${Colors.TEXT_GRAY_DARK}`,
            }}
            disabled={!cartItem?.note}
            onClick={removeCartItemNote}
          >
            Remove Note
          </CustomButton>
          <CustomButton
            sx={{
              height: "3.85714286rem",
              width: "14.2857143rem",
              fontSize: "1.42857143rem",
            }}
            disabled={note === cartItem?.note || note === ""}
            onClick={addOrUpdateCartItemNote}
          >
            {cartItem?.note ? "Update Note" : "Add Note"}
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default ItemNoteModal;

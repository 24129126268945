import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import { CustomButton, CustomTextBox } from "../../components";
import { PosLayout } from "../../components/layouts/pos-layout/PosLayout";
import { Colors } from "../../configs";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loginAction } from "../../redux/actions/authAction";
import { useTranslation } from "../../util/translation-provider";
import { useNavigate } from "react-router-dom";
import { getTenantId } from "../../util/common-functions";

type LoginFormInputs = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tenantId = getTenantId();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<LoginFormInputs>();
  const { loading, loginResponse } = useAppSelector((state) => state.auth);
  const translation = useTranslation();
  const [formData, setFormData] = useState<LoginFormInputs | null>(null);

  const onSubmit: SubmitHandler<LoginFormInputs> = (data) => {
    setFormData(data);
    dispatch(loginAction({ ...data, whiteLabel: tenantId }));
  };

  useEffect(() => {
    if (loginResponse?.status === "first-time") {
      navigate("/auth/password/change", { state: { email: formData?.email } });
    }
  }, [loginResponse]);

  return (
    <PosLayout>
      <Grid display="flex" sx={{ height: "100%" }}>
        <Grid
          flex={7}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={translation.loginLogo}
            alt="Green"
            style={{ width: "55rem" }}
          />
        </Grid>
        <Grid
          flex={5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: Colors.BACKGROUND_GRAY,
              borderRadius: "0.71rem",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid style={{ width: "33rem", padding: "3.42857143rem" }}>
                <Typography
                  sx={{
                    fontSize: "2.57142857rem",
                    lineHeight: "3rem",
                    color: Colors.TEXT_GRAY_DARK,
                    textAlign: "center",
                  }}
                >
                  Login to {translation.companyName}
                </Typography>
                <Grid sx={{ width: "100%", pt: "2.14285714rem" }}>
                  <label htmlFor="loginFormUser">
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: Colors.TEXT_GRAY_DARK,
                        fontWeight: "bold",
                      }}
                    >
                      Username
                    </Typography>
                  </label>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        message: "Username is required",
                        value: true,
                      },
                      pattern: {
                        message: "Please insert valid email",
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <CustomTextBox
                        {...field}
                        error={fieldState.invalid}
                        sx={{
                          width: "100%",
                          backgroundColor: Colors.WHITE,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid sx={{ width: "100%", pt: "0.857142857rem" }}>
                  <label htmlFor="loginFormPass">
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: Colors.TEXT_GRAY_DARK,
                        fontWeight: "bold",
                      }}
                    >
                      Password
                    </Typography>
                  </label>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: {
                        message: "Password is required",
                        value: true,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <CustomTextBox
                        {...field}
                        error={fieldState.invalid}
                        type="password"
                        sx={{
                          width: "100%",
                          backgroundColor: Colors.WHITE,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid sx={{ py: "1.71428571rem" }}>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    sx={{
                      textTransform: "none",
                      width: "100%",
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Login"
                    )}
                  </CustomButton>
                </Grid>
                <Grid style={{ color: "red" }}>
                  {(loginResponse?.status === "un-verified" ||
                    loginResponse?.status === "wrong") &&
                    loginResponse?.message}
                </Grid>
                <Typography
                  sx={{
                    fontSize: "0.857142857rem",
                    color: Colors.BLACK,
                  }}
                >
                  In a case of forgotten password or difficulty in logging in
                  please contact your manager. If you are a manager,{" "}
                  <a style={{ textDecoration: "underline" }}>Click here</a> to
                  access the system admin panel.
                </Typography>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </PosLayout>
  );
};

export { LoginPage };

import { Box, TextField, TextFieldProps, Typography } from "@mui/material";
import { FC, forwardRef, useEffect, useState } from "react";
import { Colors } from "../../../../configs";
import { CustomButton, CustomTextBox } from "../../../atoms";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

interface ICardSectionProps {
  paidAmount: number;
  returnPaidAmount: (value: number) => void;
  billValue: number;
  // balance?: number;
  returnBalance: (value: number) => void;
}

type ICardSectionPropsWithRef = ICardSectionProps & TextFieldProps;

const CardManual: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.714285714rem",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.357142857rem",
        width: "46.4285714rem",
        height: "18.0714286rem",
        px: "2.13428571rem",
        py: "2.21428571rem",
        pt: "1.78571429rem",
        position: "relative",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.71428571rem",
          fontWeight: "bold",
          color: Colors.TEXT_GRAY_DARK,
        }}
      >
        Payment Info
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.42857142857rem",
            color: Colors.TEXT_GRAY_DARK,
            fontWeight: 500,
          }}
        >
          Name on card
        </Typography>
        <TextField
          size="small"
          sx={{
            /*   width: "17.2142857rem !important",*/
            width: "28.6428571rem",
            minHeight: "3rem !important",
            height: "3rem !important",
            maxHeight: "3rem !important",
            borderRadius: "0.357142857rem",
            background: Colors.WHITE,
            border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            boxShadow: "none !important",
            padding: "0 !important",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& input": {
              padding: "0",
              margin: "0 !important",
              textAlign: "left",
              fontSize: "1.57142857rem",
              fontWeight: 500,
              px: "1.32785714rem",
              width: "28.6428571rem",
              boxSizing: "border-box",
            },
            "& fieldset": {
              border: "none !important",
              height: "3rem !important",
            },
          }}
          placeholder="Vicktor Salamanca"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.42857142857rem",
            color: Colors.TEXT_GRAY_DARK,
            fontWeight: 500,
          }}
        >
          Card number
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1.07142857rem",
          }}
        >
          <TextField
            size="small"
            sx={{
              /*   width: "17.2142857rem !important",*/
              width: "6.35714286rem",
              minHeight: "3rem !important",
              height: "3rem !important",
              maxHeight: "3rem !important",
              borderRadius: "0.357142857rem",
              background: Colors.WHITE,
              border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              boxShadow: "none !important",
              padding: "0 !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& input": {
                padding: "0",
                margin: "0 !important",
                textAlign: "left",
                fontSize: "1.57142857rem",
                fontWeight: 500,
                px: "1.32785714rem",
                width: "6.35714286rem",
                boxSizing: "border-box",
                MozAppearance: "textfield",
              },
              "& fieldset": {
                border: "none !important",
                height: "3rem !important",
              },
              "& input::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "& input::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{ maxLength: 4, min: 0, max: 9999 }}
            placeholder="1234"
          />
          <TextField
            size="small"
            sx={{
              /*   width: "17.2142857rem !important",*/
              width: "6.35714286rem",
              minHeight: "3rem !important",
              height: "3rem !important",
              maxHeight: "3rem !important",
              borderRadius: "0.357142857rem",
              background: Colors.WHITE,
              border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              boxShadow: "none !important",
              padding: "0 !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& input": {
                padding: "0",
                margin: "0 !important",
                textAlign: "left",
                fontSize: "1.57142857rem",
                fontWeight: 500,
                px: "1.32785714rem",
                width: "6.35714286rem",
                boxSizing: "border-box",
                MozAppearance: "textfield",
              },
              "& fieldset": {
                border: "none !important",
                height: "3rem !important",
              },
              "& input::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "& input::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{ maxLength: 4, min: 0, max: 9999 }}
            placeholder="1234"
          />
          <TextField
            size="small"
            sx={{
              /*   width: "17.2142857rem !important",*/
              width: "6.35714286rem",
              minHeight: "3rem !important",
              height: "3rem !important",
              maxHeight: "3rem !important",
              borderRadius: "0.357142857rem",
              background: Colors.WHITE,
              border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              boxShadow: "none !important",
              padding: "0 !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& input": {
                padding: "0",
                margin: "0 !important",
                textAlign: "left",
                fontSize: "1.57142857rem",
                fontWeight: 500,
                px: "1.32785714rem",
                width: "6.35714286rem",
                boxSizing: "border-box",
                MozAppearance: "textfield",
              },
              "& fieldset": {
                border: "none !important",
                height: "3rem !important",
              },
              "& input::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "& input::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{ maxLength: 4, min: 0, max: 9999 }}
            placeholder="1234"
          />
          <TextField
            size="small"
            sx={{
              /*   width: "17.2142857rem !important",*/
              width: "6.35714286rem",
              minHeight: "3rem !important",
              height: "3rem !important",
              maxHeight: "3rem !important",
              borderRadius: "0.357142857rem",
              background: Colors.WHITE,
              border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              boxShadow: "none !important",
              padding: "0 !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& input": {
                padding: "0",
                margin: "0 !important",
                textAlign: "left",
                fontSize: "1.57142857rem",
                fontWeight: 500,
                px: "1.32785714rem",
                width: "6.35714286rem",
                boxSizing: "border-box",
                MozAppearance: "textfield",
              },
              "& fieldset": {
                border: "none !important",
                height: "3rem !important",
              },
              "& input::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "& input::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{ maxLength: 4, min: 0, max: 9999 }}
            placeholder="1234"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.42857142857rem",
            color: Colors.TEXT_GRAY_DARK,
            fontWeight: 500,
          }}
        >
          Expiry Date
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1.07142857rem",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            sx={{
              /*   width: "17.2142857rem !important",*/
              width: "6.35714286rem",
              minHeight: "3rem !important",
              height: "3rem !important",
              maxHeight: "3rem !important",
              borderRadius: "0.357142857rem",
              background: Colors.WHITE,
              border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              boxShadow: "none !important",
              padding: "0 !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& input": {
                padding: "0",
                margin: "0 !important",
                textAlign: "left",
                fontSize: "1.57142857rem",
                fontWeight: 500,
                px: "1.32785714rem",
                width: "6.35714286rem",
                boxSizing: "border-box",
                MozAppearance: "textfield",
              },
              "& fieldset": {
                border: "none !important",
                height: "3rem !important",
              },
              "& input::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "& input::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{ maxLength: 2, min: 0, max: 99 }}
            placeholder="YY"
          />
          <TextField
            size="small"
            sx={{
              /*   width: "17.2142857rem !important",*/
              width: "6.35714286rem",
              minHeight: "3rem !important",
              height: "3rem !important",
              maxHeight: "3rem !important",
              borderRadius: "0.357142857rem",
              background: Colors.WHITE,
              border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              boxShadow: "none !important",
              padding: "0 !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& input": {
                padding: "0",
                margin: "0 !important",
                textAlign: "left",
                fontSize: "1.57142857rem",
                fontWeight: 500,
                px: "1.32785714rem",
                width: "6.35714286rem",
                boxSizing: "border-box",
                MozAppearance: "textfield",
              },
              "& fieldset": {
                border: "none !important",
                height: "3rem !important",
              },
              "& input::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "& input::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{ maxLength: 2, min: 0, max: 99 }}
            placeholder="MM"
          />
          <Typography
            sx={{
              fontSize: "1.42857142857rem",
              color: Colors.TEXT_GRAY_DARK,
              fontWeight: 500,
              /*   width: "17.2142857rem !important",*/
              width: "6.35714286rem",
            }}
          >
            CVV
          </Typography>
          <TextField
            size="small"
            sx={{
              /*   width: "17.2142857rem !important",*/
              width: "6.35714286rem",
              minHeight: "3rem !important",
              height: "3rem !important",
              maxHeight: "3rem !important",
              borderRadius: "0.357142857rem",
              background: Colors.WHITE,
              border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              boxShadow: "none !important",
              padding: "0 !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& input": {
                padding: "0",
                margin: "0 !important",
                textAlign: "left",
                fontSize: "1.57142857rem",
                fontWeight: 500,
                px: "1.32785714rem",
                width: "6.35714286rem",
                boxSizing: "border-box",
                MozAppearance: "textfield",
              },
              "& fieldset": {
                border: "none !important",
                height: "3rem !important",
              },
              "& input::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "& input::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{ maxLength: 3, min: 0, max: 999 }}
            placeholder="123"
          />
        </Box>
      </Box>
    </Box>
  );
};
const CardSwipe: FC = () => {
  const [punchAmount, setPunchAmount] = useState(-1);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.814285714rem",
        alignItems: "flex-end",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.357142857rem",
        width: "46.4285714rem",
        height: "18.0714286rem",
        px: "2.13428571rem",
        py: "2.21428571rem",
        pt: "1.78571429rem",
        position: "relative",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.71428571rem",
          fontWeight: "bold",
          color: Colors.TEXT_GRAY_DARK,
          alignSelf: "flex-start",
          mb: "0.1rem",
        }}
      >
        Payment Info
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.42857142857rem",
            color: Colors.TEXT_GRAY_DARK,
            fontWeight: 500,
          }}
        >
          Punched Amount
        </Typography>
        <TextField
          size="small"
          sx={{
            /*   width: "17.2142857rem !important",*/
            width: "28.6428571rem",
            minHeight: "3rem !important",
            height: "3rem !important",
            maxHeight: "3rem !important",
            borderRadius: "0.357142857rem",
            background: Colors.ACCENT_GRAY,
            border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            boxShadow: "none !important",
            padding: "0 !important",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& input": {
              padding: "0",
              margin: "0 !important",
              textAlign: "right",
              fontSize: "1.57142857rem",
              fontWeight: 500,
              px: "1.32785714rem",
              width: "28.6428571rem",
              boxSizing: "border-box",
            },
            "& fieldset": {
              border: "none !important",
              height: "3rem !important",
            },
          }}
          placeholder="Pending"
          value={punchAmount < 0 ? "" : punchAmount}
          type="number"
          disabled
        />
      </Box>
      <CustomButton
        sx={{
          width: "18.1428571rem",
          height: "6.42857143rem",
          borderRadius: "0.357142857rem",
          border: `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
          fontSize: "1.42857143rem",
          fontWeight: 500,
          color: Colors.MAIN_GRAY,
          background:
            punchAmount < 0
              ? Colors.WHITE
              : punchAmount === 0 ||
                punchAmount === null ||
                punchAmount === undefined
              ? Colors.NOTIFIC_RED
              : Colors.NOTIFIC_GREEN,
          textTransform: "capitalize",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "1.20642857rem",
          "&:hover": {
            background:
              punchAmount < 0
                ? Colors.WHITE
                : punchAmount === 0 ||
                  punchAmount === null ||
                  punchAmount === undefined
                ? Colors.NOTIFIC_RED
                : Colors.NOTIFIC_GREEN,
          },
        }}
      >
        {punchAmount > 0 ? (
          <CheckCircleIcon sx={{ fontSize: "1.72991071rem" }} />
        ) : punchAmount === 0 ||
          punchAmount === null ||
          punchAmount === undefined ? (
          <CancelIcon sx={{ fontSize: "1.72991071rem" }} />
        ) : (
          <Box sx={{ display: "flex", gap: "0.21428571428rem" }}>
            <Box
              sx={{
                width: "1.07142857rem",
                height: "1.07142857rem",
                background: Colors.SECONDARY,
                borderRadius: "50%",
              }}
            ></Box>
            <Box
              sx={{
                width: "1.07142857rem",
                height: "1.07142857rem",
                background: "none",
                borderRadius: "50%",
                border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              }}
            ></Box>
          </Box>
        )}
        {punchAmount < 0 ? "Punch Pending" : "Punch Success"}
      </CustomButton>
    </Box>
  );
};

export const CardSection: FC<ICardSectionPropsWithRef> = forwardRef(
  (props, ref) => {
    const { returnPaidAmount, billValue, returnBalance } = props;

    const [cardScanType, setCardScanType] = useState("swipe");
    const [insideAmount, setInsideAmount] = useState(billValue ?? 0);

    useEffect(() => {
      setInsideAmount(billValue ?? 0);
    }, [billValue]);
    useEffect(() => {
      returnBalance && returnBalance(insideAmount - billValue);
      returnPaidAmount && returnPaidAmount(insideAmount);
    }, [insideAmount]);

    // (ref as any)?.current?.focus();

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.48rem",
          height: "23.8571429rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "2.85714286rem",
            fontWeight: "bold",
            color: Colors.TEXT_GRAY_DARK,
          }}
        >
          Card Information
        </Typography>
        <Box
          sx={{ display: "flex", flexDirection: "row", gap: "1.64285714rem" }}
        >
          <Box
            sx={{
              background: Colors.BACKGROUND_GRAY,
              borderRadius: "0.357142857rem",
              py: "1.78571429rem",
              px: "2.20392857rem",
              width: "37rem",
              height: "18.0714286rem",
              display: "flex",
              flexDirection: "column",
              gap: "1.28571428571rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.71428571rem",
                color: Colors.TEXT_GRAY_DARK,
                fontWeight: "bold",
              }}
            >
              Payment Amount
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.42857142857rem",
                  color: Colors.TEXT_GRAY_DARK,
                  fontWeight: 500,
                }}
              >
                Amount
              </Typography>
              <CustomTextBox
                type="currency"
                value={insideAmount ?? 0}
                onChange={(e) => setInsideAmount(Number(e.target.value ?? 0))}
                style={{
                  width: "23.7857143rem",
                }}
                inputRef={ref}
                autoFocus={true}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1.18500rem",
              }}
            >
              <CustomButton
                sx={{
                  flexGrow: 1,
                  fontSize: "1.42857143rem",
                  fontWeight: "bold",
                  color: Colors.MAIN_GRAY,
                  width: "10.0787157rem",
                  height: "6.42857143rem",
                  borderRadius: "0.357142857rem",
                  background:
                    cardScanType === "manual"
                      ? Colors.NOTIFIC_GREEN
                      : Colors.WHITE,
                  border: `0.0714285714rem solid ${Colors.MAIN_GRAY}`,
                  lineHeight: "1.71428571rem",
                  textTransform: "capitalize",
                  px: "1.96285714rem",
                  py: "1.5rem",
                }}
                onClick={() => setCardScanType("manual")}
                disabled={true}
              >
                Enter Details
              </CustomButton>
              <CustomButton
                sx={{
                  flexGrow: 1,
                  fontSize: "1.42857143rem",
                  fontWeight: "bold",
                  color: Colors.MAIN_GRAY,
                  width: "10.0787157rem",
                  height: "6.42857143rem",
                  borderRadius: "0.357142857rem",
                  background:
                    cardScanType === "swipe"
                      ? Colors.NOTIFIC_GREEN
                      : Colors.WHITE,
                  border: `0.0714285714rem solid ${Colors.MAIN_GRAY}`,
                  lineHeight: "1.71428571rem",
                  textTransform: "capitalize",
                  px: "1.96285714rem",
                  py: "1.5rem",
                }}
                onClick={() => setCardScanType("swipe")}
              >
                Swipe Card
              </CustomButton>
            </Box>
          </Box>
          {cardScanType === "manual" ? <CardManual /> : <CardSwipe />}
        </Box>
      </Box>
    );
  }
);

import { Box, TextField, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Colors } from "../../../../configs";
import CashValues from "../../../atoms/cash-value/cash-value";
import { CustomTextBox } from "../../../atoms";

interface ICashSectionProps {
  billValue: number;
  returnBalance: (value: number) => void;
  returnPaidAmount: (value: number) => void;
  type?: "checkin" | "storeCredit";
  balance: number;
  saveBalanceToCredits?: boolean;
}
export const CashSection: FC<ICashSectionProps> = ({
  billValue,
  returnBalance,
  returnPaidAmount,
  type = "checkin",
  saveBalanceToCredits = false,
  balance,
}) => {
  const [hundredNoteCount, setHundredsNoteCount] = useState(0);
  const [fiftyNoteCount, setFiftyNoteCount] = useState(0);
  const [twentyNoteCount, setTwentyNoteCount] = useState(0);
  const [tenNoteCount, setTenNoteCount] = useState(0);
  const [fiveNoteCount, setFiveNoteCount] = useState(0);
  const [oneNoteCount, setOneNoteCount] = useState(0);

  const [paidAmount, setPaidAmount] = useState(0);
  // const [balance, setBalance] = useState(0);
  const [paidAmountInputType, setPaidAmountInputType] = useState<
    "buttons" | "keyboard"
  >("buttons");

  const handleHundredNoteCountChange = (value: number) => {
    setHundredsNoteCount(value);
  };
  const handleFiftyNoteCountChange = (value: number) => {
    setFiftyNoteCount(value);
  };
  const handleTwentyNoteCountChange = (value: number) => {
    setTwentyNoteCount(value);
  };
  const handleTenNoteCountChange = (value: number) => {
    setTenNoteCount(value);
  };
  const handleFiveNoteCountChange = (value: number) => {
    setFiveNoteCount(value);
  };
  const handleOneNoteCountChange = (value: number) => {
    setOneNoteCount(value);
  };

  const handlePaidAmountChange = (value: number) => {
    setPaidAmountInputType("keyboard");
    setPaidAmount(value);
    returnPaidAmount(value);
  };

  useEffect(() => {
    if (paidAmountInputType === "keyboard") {
    } else {
      handlePaidAmountChange(
        hundredNoteCount * 100 +
          fiftyNoteCount * 50 +
          twentyNoteCount * 20 +
          tenNoteCount * 10 +
          fiveNoteCount * 5 +
          oneNoteCount
      );
    }
    returnBalance(paidAmount - billValue);
    setPaidAmountInputType("buttons");
    returnBalance(paidAmount - billValue);
  }, [
    hundredNoteCount,
    fiftyNoteCount,
    twentyNoteCount,
    tenNoteCount,
    fiveNoteCount,
    oneNoteCount,
    billValue,
    paidAmount,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.48rem",
        height: "23.8571429rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "2.85714286rem",
          fontWeight: "bold",
          color: Colors.TEXT_GRAY_DARK,
        }}
      >
        Cash Information
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1.64285714rem",
        }}
      >
        <Box
          sx={{
            background: Colors.BACKGROUND_GRAY,
            borderRadius: "0.357142857rem",
            py: "1.78571429rem",
            px: "2.20392857rem",
            width: "37rem",
            height: "18.0714286rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.714285714rem",
            // 1.28571428571-0.714285714=0.57142857141
          }}
        >
          <Typography
            sx={{
              fontSize: "1.71428571rem",
              color: Colors.TEXT_GRAY_DARK,
              fontWeight: "bold",
              marginBottom: "0.57142857141rem",
            }}
          >
            Payment Amount
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.42857142857rem",
                color: Colors.TEXT_GRAY_DARK,
                fontWeight: 500,
              }}
            >
              Paid
            </Typography>
            <CustomTextBox
              type="currency"
              value={paidAmount}
              onChange={(e) => handlePaidAmountChange(+e.target.value)}
              styles={{
                width: "23.7857143rem",
              }}
              onKeyUp={(e) => {
                // if current value zero replace it with the new value
                if (paidAmount === 0) {
                  setPaidAmountInputType("keyboard");
                  setPaidAmount(+e.key);
                }
              }}
              autoFocus
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.42857142857rem",
                color: Colors.TEXT_GRAY_DARK,
                fontWeight: 500,
              }}
            >
              Amount
            </Typography>
            <CustomTextBox
              type="currency"
              value={billValue}
              disabled
              styles={{
                /*   width: "17.2142857rem !important",*/
                width: "23.7857143rem",
                minHeight: "3rem",
                height: "3rem",
                maxHeight: "3rem",
                borderRadius: "0.357142857rem",
                background: Colors.ACCENT_GRAY,
                border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                boxShadow: "none !important",
                padding: "0 !important",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: Colors.ACCENT_GRAY_DISABLED,
                paddingRight: "0.987857143rem",
                fontSize: "1.57142857rem",
                margin: "0 !important",
                fontWeight: 500,
                boxSizing: "border-box",
              }}
            />
          </Box>
          {/* {type === "checkin" ? (
              <> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.42857142857rem",
                color: Colors.TEXT_GRAY_DARK,
                fontWeight: 500,
              }}
            >
              Balance
            </Typography>
            <CustomTextBox
              size="small"
              styles={{
                /*   width: "17.2142857rem !important",*/
                width: "23.7857143rem",
                minHeight: "3rem",
                height: "3rem",
                maxHeight: "3rem",
                borderRadius: "0.357142857rem",
                background: Colors.ACCENT_GRAY,
                border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                boxShadow: "none !important",
                padding: "0 !important",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: Colors.ACCENT_GRAY_DISABLED,
                paddingRight: "0.987857143rem",
                fontSize: "1.57142857rem",
                margin: "0 !important",
                fontWeight: 500,
                boxSizing: "border-box",
              }}
              type="currency"
              value={saveBalanceToCredits ? 0 : balance < 0 ? 0 : balance}
              disabled
            />
          </Box>
          {/*  </>
             ) : (
               <></>
             )*/}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.714285714rem",
            background: Colors.BACKGROUND_GRAY,
            borderRadius: "0.357142857rem",
            width: "46.4285714rem",
            height: "18.0714286rem",
            px: "2.13428571rem",
            py: "2.21428571rem",
            pt: "1.78571429rem",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.71428571rem",
              fontWeight: "bold",
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            Cash Taken
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              // gap: "0.65rem",
            }}
          >
            <CashValues
              count={hundredNoteCount}
              onChange={handleHundredNoteCountChange}
              noteValue={100}
            />
            <CashValues
              count={fiftyNoteCount}
              onChange={handleFiftyNoteCountChange}
              noteValue={50}
            />
            <CashValues
              count={twentyNoteCount}
              onChange={handleTwentyNoteCountChange}
              noteValue={20}
            />
            <CashValues
              count={tenNoteCount}
              onChange={handleTenNoteCountChange}
              noteValue={10}
            />
            <CashValues
              count={fiveNoteCount}
              onChange={handleFiveNoteCountChange}
              noteValue={5}
            />
            <CashValues
              count={oneNoteCount}
              onChange={handleOneNoteCountChange}
              noteValue={1}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import { FC, useState } from "react";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import { Box, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import { CustomSelectBox } from "../../atoms/custom-select-box/custom-select-box";
import { DiscountMode } from "../../../typings";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Controller, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createDiscount } from "../../../redux/actions/discountAction";
import { getCategoryAction } from "../../../redux/actions/pricingAction";

const schema = Yup.object().shape({
  id: Yup.string().notRequired(),
  discountName: Yup.string().required("Discount Name is required"),
  discountAmount: Yup.number().required("Discount Amount is required"),
  discountMode: Yup.string().required("Discount Mode is required"),
  description: Yup.string().required("Description is required"),
  serviceIds: Yup.array().required("Service Ids is required"),
  minimumBillValue: Yup.number().required("Minimum Bill Value is required"),
});

type FormData = Yup.InferType<typeof schema>;
const resolver: Resolver<FormData> = yupResolver(schema);

export interface ICreateDiscountDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
}

const CreateDiscountDrawer: FC<ICreateDiscountDrawerProps> = ({
  open,
  toggleDrawer,
}) => {
  const dispatch = useAppDispatch();
  const { categoryResponse } = useAppSelector((state) => state.pricing);
  const { adminDashboardSelectedMerchant, adminDashboardSelectedStore } =
    useAppSelector((state) => state.master);

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<FormData>({
    resolver: resolver,
  });

  const handleOnChangeServices = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    setValue(
      "serviceIds",
      typeof value === "string"
        ? (value as string).split(",")
        : (value as number[])
    );
  };

  const handleFormSubmit = (data: FormData) => {
    if (adminDashboardSelectedMerchant && adminDashboardSelectedStore)
      dispatch(
        createDiscount({
          discountName: data.discountName,
          discountAmount: data.discountAmount,
          discountMode: data.discountMode as DiscountMode,
          description: data.description,
          merchantId: adminDashboardSelectedMerchant?.merchantId,
          storeId: adminDashboardSelectedStore?.storeId,
          serviceIds: data.serviceIds,
          minimumBillValue: data.minimumBillValue,
        })
      ).then(() => {
        toggleDrawer();
        dispatch(getCategoryAction());
      });
  };

  return (
    <CustomDrawer
      isOpen={open}
      toggleDrawer={toggleDrawer}
      sx={{
        width: "28.65rem",
      }}
    >
      <form
        onSubmit={handleSubmit((data) => {
          handleFormSubmit(data);
        })}
      >
        <Box
          sx={{
            // width: "27.2857143rem",
            paddingInline: "1.85714286rem",
            paddingTop: "2rem",
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1.42857143rem",
              lineHeight: "1.71428571rem",
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            Add a New Discount
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.857142857rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.214285714rem",
              }}
            >
              <Typography>Discount Name</Typography>
              <Controller
                name="discountName"
                control={control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextBox
                    {...field}
                    error={fieldState.invalid}
                    sx={{
                      width: "100%",
                      backgroundColor: Colors.WHITE,
                    }}
                    placeholder="Discount Name"
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.214285714rem",
              }}
            >
              <Typography>Description</Typography>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextBox
                    {...field}
                    error={fieldState.invalid}
                    sx={{
                      width: "100%",
                      backgroundColor: Colors.WHITE,
                    }}
                    placeholder="Description"
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.857142857rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.214285714rem",
                  width: "50%",
                }}
              >
                <Typography>Discount Mode</Typography>
                <Controller
                  name="discountMode"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <CustomSelectBox
                      {...field}
                      error={fieldState.invalid}
                      sx={{
                        width: "100%",
                        backgroundColor: Colors.WHITE,
                      }}
                    >
                      <MenuItem value={DiscountMode.PERCENTAGE}>
                        {DiscountMode.PERCENTAGE}
                      </MenuItem>
                      <MenuItem value={DiscountMode.FIXED}>
                        {DiscountMode.FIXED}
                      </MenuItem>
                    </CustomSelectBox>
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.214285714rem",
                  width: "50%",
                }}
              >
                <Typography>Discount Amount</Typography>
                <Controller
                  name="discountAmount"
                  control={control}
                  defaultValue={0}
                  render={({ field, fieldState }) => (
                    <CustomTextBox
                      {...field}
                      error={fieldState.invalid}
                      type="number"
                      sx={{
                        width: "100%",
                        backgroundColor: Colors.WHITE,
                      }}
                      placeholder="Discount Amount"
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.214285714rem",
              }}
            >
              <Typography>Minimum Bill</Typography>
              <Controller
                name="minimumBillValue"
                control={control}
                defaultValue={0}
                render={({ field, fieldState }) => (
                  <CustomTextBox
                    {...field}
                    error={fieldState.invalid}
                    sx={{
                      width: "100%",
                      backgroundColor: Colors.WHITE,
                    }}
                    placeholder="Minimum Bill"
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.214285714rem",
              }}
            >
              <Typography>Service</Typography>
              <Controller
                name="serviceIds"
                control={control}
                defaultValue={[]}
                render={({ field, fieldState }) => (
                  <CustomSelectBox
                    {...field}
                    error={fieldState.invalid}
                    multiple
                    sx={{
                      width: "100%",
                      backgroundColor: Colors.WHITE,
                    }}
                    onChange={handleOnChangeServices}
                  >
                    {categoryResponse?.body.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {service.categoryName}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                )}
              />
            </Box>

            <CustomButton
              sx={{
                width: "9.28571429rem",
                height: "2.64285714rem",
                marginTop: "0.928571429rem",
              }}
              type="submit"
            >
              Create
            </CustomButton>
          </Box>
        </Box>
      </form>
    </CustomDrawer>
  );
};

export default CreateDiscountDrawer;

import { Grid } from "@mui/material";
import { FC } from "react";
import { AdminSideNav } from "../../common/admin-side-nav/admin-side-nav";
import { AdminTopNav } from "../../common/admin-top-nav/admin-top-nav";

export interface IAdminLayoutProps {
  children: React.ReactNode;
}

export const AdminLayout: FC<IAdminLayoutProps> = ({ children }) => {
  return (
    <Grid sx={{ width: "100%", height: "100%", display: "flex" }}>
      <AdminSideNav />
      <Grid flex={1} sx={{ display: "flex", flexDirection: "column" }}>
        <AdminTopNav />
        <Grid flex={1} sx={{ px: "2.85714286rem" }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

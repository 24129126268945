import { FC, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { Colors } from "../../../../configs/colors";
import usePricing from "../../../../hooks/usePricing";
import { FabricType } from "../../../../typings";
import { CartItem } from "../../../../typings/interfaces/pos/cart-item";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { cloneDeep } from "lodash";
import {
  addCartItem,
  updateCartSessionItem,
} from "../../../../redux/actions/cartAction";
import { sortArray } from "../../../../util/array-functions";
import ServicesSection from "../../../pos/services-section/services-section";
import { getFabricTypes } from "../../../../redux/actions/pricingAction";
import { FabricTypeResponse } from "../../../../typings/interfaces/responses/fabricTypeResponse";
import { ITypeItem } from "../../../../typings/interfaces/pos/typeItem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { CustomButton } from "../../../atoms";

export interface IGarmentMaterialSectionProps {}

const GarmentMaterialSection: FC<IGarmentMaterialSectionProps> = ({}) => {
  const { selectedCartItem, selectedServiceType } = useAppSelector(
    (state) => state.cart
  );

  const { materialTypes } = usePricing();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedServiceType?.id)
      dispatch(getFabricTypes({ id: selectedServiceType?.id }));
  }, [selectedServiceType]);

  const handleOnSelectType = (materialType: ITypeItem) => {
    let cartItem: CartItem;
    if (selectedCartItem) {
      cartItem = cloneDeep(selectedCartItem);
      cartItem.materialType = materialType;
    } else {
      cartItem = {
        serviceType: selectedServiceType ? selectedServiceType : undefined,
        garmentType: undefined,
        materialType: materialType,
        texture: undefined,
        garmentStyle: undefined,
        garmentColor: undefined,
        qty: 1,
        itemId: uuidv4(),
        finalized: false,
        unitPrice: 0,
        instructions: [],
        damages: [],
        upcharges: [],
        spots: [],
        alterationItems: [],
        note: null,
      };
    }
    dispatch(updateCartSessionItem({ cartItem })).then(() => {
      if (cartItem.garmentType && cartItem.garmentStyle) {
        dispatch(addCartItem());
      }
    });
  };

  // const garmentMaterialSorted = materialTypes || [];
  const garmentMaterialSorted = sortArray({
    array: materialTypes || [],
    sortByKey: "name",
  });
  /* duplicate last item for ui testing purposes */
  // if (garmentMaterialSorted && garmentMaterialSorted.length > 0) {
  //   const duplicatedLastItem = { ...garmentMaterialSorted[garmentMaterialSorted.length - 1] };
  //   garmentMaterialSorted.push(duplicatedLastItem);
  //   garmentMaterialSorted.push(duplicatedLastItem);
  //   garmentMaterialSorted.push(duplicatedLastItem);
  // }

  const itemsPerColumn =
    garmentMaterialSorted?.length <= 24
      ? 8
      : Math.ceil(garmentMaterialSorted?.length / 3);
  const totalRows = Math.ceil(
    (garmentMaterialSorted?.length || 0) / itemsPerColumn
  );
  let nextPrevCount: number;
  let nextPrevLastIndex: number;

  return (
    <Box
      sx={{
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "51.64rem",
        height: "11.57rem",
        boxSizing: "border-box",
        py: "1.4118rem",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "0.608571rem",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "50.13214rem",
          height: "8.7464rem",
          overflowX: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          touchAction: "pan-x",
        }}
      >
        {[...Array(totalRows)].map((_, rowIndex) => {
          const previousItemsCount = nextPrevCount;
          const previousLastIndex = nextPrevLastIndex;

          const startingIndex =
            previousLastIndex !== undefined
              ? previousLastIndex
              : rowIndex * itemsPerColumn;
          const lastIndex = startingIndex + itemsPerColumn;
          const curentItemsCount = garmentMaterialSorted.slice(
            startingIndex,
            lastIndex
          ).length;

          const nextStartingIndex = lastIndex;
          const nextLastIndex = nextStartingIndex + itemsPerColumn;
          const nextItemsCount = garmentMaterialSorted.slice(
            nextStartingIndex,
            nextLastIndex
          ).length;

          const previousStartingIndex = (rowIndex - 1) * itemsPerColumn;

          // const isPreviousShorter = previousLastIndex!==undefined && previousItemsCount!==0 && previousItemsCount < itemsPerColumn;
          const isNextShorter =
            nextItemsCount !== 0 && curentItemsCount - 1 > nextItemsCount;


          const currentRowItems = garmentMaterialSorted
            ? garmentMaterialSorted?.slice(
                // (isPreviousShorter? (startingIndex-1) : startingIndex),
                startingIndex,
                // (isNextShorter? lastIndex-(isPreviousShorter?2:1) : lastIndex-(isPreviousShorter?1:0))
                lastIndex
              )
            : [];

          nextPrevCount = curentItemsCount;
          nextPrevLastIndex = lastIndex;

          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.47908163rem",
              }}
              key={rowIndex}
            >
              {currentRowItems.map((material, i) => (
                <CustomButton
                  sx={{
                    width: "5.81rem",
                    height: "2.509642rem",
                    minWidth: "5.81rem",
                    minHeight: "2.509642rem",
                    fontSize: "1rem",
                    background:
                      selectedCartItem?.materialType?.id ===
                      (material as any).id
                        ? Colors.SECONDARY
                        : Colors.MAIN_GRAY,
                    borderRadius: "0.71rem",
                    textTransform: "capitalize",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: Colors.WHITE,
                    "&:hover": {
                      background: `${
                        selectedCartItem?.materialType?.id ===
                        (material as any).id
                          ? Colors.SECONDARY
                          : Colors.MAIN_GRAY
                      } !important`,
                    },
                    boxSizing: "border-box",
                  }}
                  key={i}
                  onClick={() => handleOnSelectType(material)}
                >
                  {(material as any).name}
                </CustomButton>
              ))}
            </Box>
          );
        })}
      </Box> */}
      <Swiper
        style={{ width: "50.13214rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(garmentMaterialSorted.length / 24))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: "0.47908163rem",
                  flexWrap: "wrap",
                  width: "50.13214rem",
                }}
              >
                {garmentMaterialSorted
                  .slice(swipePageIndex * 24, (swipePageIndex + 1) * 24)
                  .map((material, i) => (
                    <CustomButton
                      sx={{
                        width: "5.81rem",
                        height: "2.509642rem",
                        minWidth: "5.81rem",
                        minHeight: "2.509642rem",
                        fontSize: "1rem",
                        background:
                          selectedCartItem?.materialType?.id ===
                          (material as any).id
                            ? Colors.SECONDARY
                            : Colors.MAIN_GRAY,
                        borderRadius: "0.71rem",
                        textTransform: "capitalize",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color: Colors.WHITE,
                        "&:hover": {
                          background: `${
                            selectedCartItem?.materialType?.id ===
                            (material as any).id
                              ? Colors.SECONDARY
                              : Colors.MAIN_GRAY
                          } !important`,
                        },
                        boxSizing: "border-box",
                      }}
                      key={i}
                      onClick={() => handleOnSelectType(material as ITypeItem)}
                    >
                      {(material as any).name}
                    </CustomButton>
                  ))}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default GarmentMaterialSection;

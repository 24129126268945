import { FC, useEffect } from "react";
import { Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import SwiperCore, { Mousewheel } from 'swiper';
// import SwiperCore, { Mousewheel } from 'swiper/core';
// SwiperCore.use([Mousewheel]);
// import { register } from 'swiper/element/bundle';

import { Colors } from "../../../../configs";
import GarmentTypeButton from "../../../atoms/garment-type-button/garment-type-button";
import usePricing from "../../../../hooks/usePricing";
import { SubcategoryResponse } from "../../../../typings";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { cloneDeep } from "lodash";
import { CartItem } from "../../../../typings/interfaces/pos/cart-item";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { sortArray } from "../../../../util/array-functions";
import { getSubCategoryAction } from "../../../../redux/actions/pricingAction";
import { ITypeItem } from "../../../../typings/interfaces/pos/typeItem";
import { sub } from "date-fns";

export interface IGarmentTypeSectionProps {}

const GarmentTypeSection: FC<IGarmentTypeSectionProps> = ({}) => {
  const { selectedCartItem, selectedServiceType, previousServiceType } =
    useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const { subCategories } = usePricing();

  useEffect(() => {
    if (selectedServiceType?.id) {
      dispatch(getSubCategoryAction({ id: selectedServiceType.id }));
    }
  }, [selectedServiceType?.id]);

  const handleOnSelectType = (garmentType: ITypeItem) => {
    if (
      !(
        selectedServiceType?.categoryName === "Alteration" &&
        selectedCartItem?.garmentStyle &&
        selectedCartItem.materialType &&
        selectedCartItem.garmentType
      )
    ) {
      let cartItem: CartItem;
      if (selectedCartItem && selectedCartItem.finalized == false) {
        cartItem = cloneDeep(selectedCartItem);
        cartItem.garmentType = garmentType;
        cartItem.garmentStyle = undefined;
      } else {
        cartItem = {
          serviceType: selectedServiceType ? selectedServiceType : undefined,
          garmentType: garmentType,
          materialType: undefined,
          texture: undefined,
          garmentStyle: undefined,
          garmentColor: undefined,
          alterationSubCategory: undefined,
          qty: 1,
          itemId: uuidv4(),
          finalized: false,
          unitPrice: 0,
          instructions: [],
          damages: [],
          upcharges: [],
          spots: [],
          alterationItems: [],
          note: null,
        };
      }
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };

  // console.log("Sub Categories: ", subCategories);

  // Cards column sorting
  const garmentTypeBodySorted = sortArray({
    array: subCategories,
    sortByKey: "name",
    fixedLastItem: "Miscellaneous",
  });

  /* duplicate last item for ui testing purposes */
  // if (garmentTypeBodySorted && garmentTypeBodySorted.length > 0) {
  //   const duplicatedLastItem = { ...garmentTypeBodySorted[garmentTypeBodySorted.length - 1] };
  //   garmentTypeBodySorted.push(duplicatedLastItem);
  // }

  const itemsPerColumn =
    (garmentTypeBodySorted?.length ?? 0) <= 16
      ? 8
      : Math.ceil((garmentTypeBodySorted?.length ?? 0) / 2);
  const totalRows = Math.ceil(
    (garmentTypeBodySorted?.length || 0) / itemsPerColumn
  );
  let nextPrevCount: number;
  let nextPrevLastIndex: number;

  return (
    <Box
      sx={{
        width: "48.071rem",
        height: "20.786rem",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "1.285rem",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexWrap: "wrap",
          height: "18rem",
          width: "46.5rem",
          overflowX: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          touchAction: "pan-x",
        }}
      >
        {[...Array(totalRows)].map((_, rowIndex) => {
          const previousItemsCount = nextPrevCount;
          const previousLastIndex = nextPrevLastIndex;

          const startingIndex =
            previousLastIndex !== undefined
              ? previousLastIndex
              : rowIndex * itemsPerColumn;
          const lastIndex = startingIndex + itemsPerColumn;
          const curentItemsCount =
            garmentTypeBodySorted?.slice(startingIndex, lastIndex).length || 0;

          const nextStartingIndex = lastIndex;
          const nextLastIndex = nextStartingIndex + itemsPerColumn;
          const nextItemsCount =
            garmentTypeBodySorted?.slice(nextStartingIndex, nextLastIndex)
              .length || 0;

          const previousStartingIndex = (rowIndex - 1) * itemsPerColumn;

          // const isPreviousShorter = previousLastIndex!==undefined && previousItemsCount!==0 && previousItemsCount < itemsPerColumn;
          const isNextShorter =
            nextItemsCount !== 0 && curentItemsCount - 1 > nextItemsCount;


          const currentRowItems = garmentTypeBodySorted
            ? garmentTypeBodySorted?.slice(
                // (isPreviousShorter? (startingIndex-1) : startingIndex),
                startingIndex,
                // (isNextShorter? lastIndex-(isPreviousShorter?2:1) : lastIndex-(isPreviousShorter?1:0))
                lastIndex
              )
            : [];

          return (
            <Box
              key={rowIndex}
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "8.357rem",
                width: "46.5rem",
                gap: "0.357rem",
              }}
            >
              {currentRowItems.map((mapItem, i) => (
                <GarmentTypeButton
                  data={mapItem as any}
                  selected={
                    selectedCartItem?.garmentType?.id === (mapItem as any).id
                  }
                  onClick={() => handleOnSelectType(mapItem as any)}
                  key={i}
                />
              ))}
            </Box>
          );
        })}
      </Box> */}
      <Swiper
        style={{ width: "47.25rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(garmentTypeBodySorted.length / 16))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.357rem",
                  flexWrap: "wrap",
                  width: "47.25rem",
                  height: "17.071rem",
                }}
              >
                {garmentTypeBodySorted
                  .slice(swipePageIndex * 16, (swipePageIndex + 1) * 16)
                  .map((mapItem, i) => (
                    <GarmentTypeButton
                      data={mapItem as any}
                      selected={
                        selectedCartItem?.garmentType?.id ===
                        (mapItem as any).id
                      }
                      onClick={() => handleOnSelectType(mapItem as any)}
                      key={i}
                    />
                  ))}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default GarmentTypeSection;

import React, { FC, useState, MouseEvent, useEffect } from "react";
import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StoreIcon from "@mui/icons-material/Store";
import { useAppDispatch } from "../../../../hooks";
import { getAllMerchants } from "../../../../redux/actions/merchantAction";
import { useAppSelector } from "../../../../hooks";

interface IMerchantsPageProps {}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const MerchantsPage: FC<IMerchantsPageProps> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openContextMenu = Boolean(anchorEl);
  const [contextMenuMerchant, setContextMenuMerchant] = useState<null | string>(
    null
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const merchantList = useAppSelector(
    (state) => state.merchant.merchantListResponse?.body
  );
  const merchantListLoading = useAppSelector(
    (state) => state.merchant.merchantListLoading
  );

  useEffect(() => {
    dispatch(getAllMerchants());
  }, []);

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    merchant: string
  ) => {
    setAnchorEl(event.currentTarget);
    setContextMenuMerchant(merchant);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setContextMenuMerchant(null);
  };

  const openManageStores = () => {
    navigate(
      `/admin/config/merchants/${contextMenuMerchant}/stores?merchantId=1`
    );
    handleClose();
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          height: "57.5rem",
          marginTop: "4.35714286rem",
          paddingInline: "5rem",
          width: "100%",
          maxHeight: "57.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: Colors.TEXT_GRAY_DARK }}>
            Merchants
          </Typography>
          <Link to="/admin/config/merchants/create">
            <CustomButton
              sx={{
                width: "11.0714286rem",
                height: "3.07142857rem",
              }}
            >
              Add New
            </CustomButton>
          </Link>
        </Box>
        <Box
          sx={{
            maxHeight: "51.42857143rem",
            width: "100%",
            overflowY: "auto",
            // make scrollbar style looks better
            "&::-webkit-scrollbar": {
              width: "0.5rem",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Merchant Name</StyledTableCell>
                <StyledTableCell>address</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Phone</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {merchantList?.map((row) => (
                <StyledTableRow key={row.merchantId}>
                  <StyledTableCell>{row.companyName}</StyledTableCell>
                  <StyledTableCell>N/A</StyledTableCell>
                  <StyledTableCell>{row.companyEmail}</StyledTableCell>
                  <StyledTableCell>{row.phoneNumber}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={(e) => handleClick(e, row.whitelabel)}>
                      <MoreHorizIcon
                        sx={{ fontSize: "2rem" }}
                        color="primary"
                      />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {(merchantList ?? []).length === 0 && !merchantListLoading && (
                <StyledTableRow>
                  <StyledTableCell colSpan={5}>
                    No merchant found
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {merchantListLoading && (
                <StyledTableRow>
                  <StyledTableCell colSpan={5} sx={{ textAlign: "center" }}>
                    <CircularProgress />
                  </StyledTableCell>
                </StyledTableRow>
              )}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openContextMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={openManageStores}>
                  <ListItemIcon>
                    <StoreIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Manage Stores</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Merchant</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete Merchant</ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default MerchantsPage;

import { FC } from "react";
import Services from "./services/services";
import { useAppSelector } from "../../../hooks";
import { Service } from "../../../typings/enum/Service";
import Customization from "./customization/customization";
import { Box } from "@mui/material";

export interface InforSection {}

const ServicesSection: FC<InforSection> = () => {
  const { activeServiceTab } = useAppSelector((state) => state.pos);
  return (
    <Box
      sx={{
        flex: 5,
        display: "flex",
        flexDirection: "column",
        gap: "1.42857143rem",
        height: "100%",
      }}
    >
      {activeServiceTab === Service.GARMENT_WASH_AND_CLEAN && <Services />}
      {activeServiceTab === Service.CUSTOMIZATION && <Customization />}
    </Box>
  );
};

export default ServicesSection;

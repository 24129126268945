import { FC } from "react";
import React from "react";
import { Box, Button } from "@mui/material";
import { Colors } from "../../../../configs/colors";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartItem } from "../../../../typings/interfaces/pos/cart-item";
import { cloneDeep } from "lodash";
import {
  addCartItem,
  updateCartSessionItem,
} from "../../../../redux/actions/cartAction";
import usePricing from "../../../../hooks/usePricing";
import { IAlterationItem } from "../../../../typings/interfaces/pos/alterationItem";
import { ITypeItem } from "../../../../typings/interfaces/pos/typeItem";
import { PricingResponse } from "../../../../typings/interfaces/responses/pricing-response";
import { CustomButton } from "../../../atoms";

export interface IAlterationSecondSubSectionProps {}

const AlterationSecondSubSelection: FC<
  IAlterationSecondSubSectionProps
> = () => {
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const { alterationModificationType, alterationPrices } = useAppSelector(
    (state) => state.pricing
  );
  const { alterationSecondSubCategories } = usePricing();
  const dispatch = useAppDispatch();

  const handleOnSelectType = (selectedType: ITypeItem) => {
    let cartItem: CartItem;
    if (selectedCartItem) {
      cartItem = cloneDeep(selectedCartItem);

      // Find uncompleted alterationItems and update their alterationType
      const uncompletedItemIndex = cartItem.alterationItems.findIndex(
        (item) => !item.alterationPrice
      );

      if (uncompletedItemIndex !== -1) {
        console.log("AlterdationModifications", alterationModificationType);
        const dcPrice = alterationPrices?.filter((item: PricingResponse) => {
          return (
            item.subCategoryId === selectedCartItem.garmentType?.id &&
            item.secondSubCategoryId === selectedType.id &&
            item.materialId ===
              cartItem.alterationItems[uncompletedItemIndex]
                .alterationSubCategory?.id
          );
        });

        console.log("Price: ", dcPrice);

        const price = (dcPrice && dcPrice[0]?.price) || 0;
        cartItem.alterationItems[uncompletedItemIndex].alterationPrice =
          selectedType;
        cartItem.alterationItems[uncompletedItemIndex].unitPrice = price;
      } else {
        // If no uncompleted item is found, add a new item
        const newItem: IAlterationItem = { alterationPrice: selectedType };
        cartItem.alterationItems.push(newItem);
      }

      dispatch(updateCartSessionItem({ cartItem })).then(() => {
        dispatch(addCartItem());
      });
    }
  };

  const getIncompleteItemType = (cartItem: CartItem | null) => {
    const incompleteItem = cartItem?.alterationItems.find(
      (item) => !item.alterationPrice
    );
    return incompleteItem ? incompleteItem.alterationPrice?.name : null;
  };

  const incompleteItemType = getIncompleteItemType(selectedCartItem);

  const itemsPerColumn =
    alterationSecondSubCategories?.length <= 24
      ? 6
      : Math.ceil(alterationSecondSubCategories?.length / 4);
  const totalRows = Math.ceil(
    (alterationSecondSubCategories?.length || 0) / itemsPerColumn
  );

  return (
    <Box
      sx={{
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        padding: "0.928571429rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "26.5714286rem",
        width: "73.6428571rem",
      }}
    >
      <Box
        sx={{
          height: "22.4rem",
          maxHeight: "22.4rem",
          width: "71.15rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignContent: "flex-start",
          gap: "0.660285714rem",
          overflowX: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          touchAction: "pan-x",
        }}
      >
        {[...Array(totalRows)].map((_, rowIndex) => {
          const startingIndex = rowIndex * itemsPerColumn;
          const lastIndex = startingIndex + itemsPerColumn;

          const currentRowItems = alterationSecondSubCategories
            ? alterationSecondSubCategories.slice(startingIndex, lastIndex)
            : [];

          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.660285714rem",
              }}
              key={rowIndex}
            >
              {currentRowItems.map((variation: any, i) => (
                <CustomButton
                  sx={{
                    width: "11.2689286rem",
                    background:
                      incompleteItemType === variation.name
                        ? Colors.SECONDARY
                        : Colors.MAIN_GRAY,
                    borderRadius: "0.71rem",
                    textTransform: "capitalize",
                    color: Colors.WHITE,
                    fontSize: "1rem",
                    lineHeight: "1.17214286rem",
                    padding: "1.14rem 0.928571429rem",
                    height: "5.07142857rem",
                    "&:hover": {
                      background: `${Colors.MAIN_GRAY} !important`,
                    },
                  }}
                  key={i}
                  onClick={() => handleOnSelectType(variation)}
                >
                  {variation.name}
                </CustomButton>
              ))}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default AlterationSecondSubSelection;

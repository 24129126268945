import { FC, useEffect, useRef, useState } from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import Grid from "@mui/material/Grid";
import { Colors } from "../../../configs";
import { format } from "date-fns";
import { useTranslation } from "../../../util/translation-provider";
import { lockProfileAction } from "../../../redux/actions/authAction";
import DraftDrawer from "../../pos/draft-drawer/draftDrawer";

const DATE_FORMAT = "dd/MM/yyyy EEEE hh:mm:ss aaa";

const TopBar: FC = () => {
  const [open, setOpen] = useState(false);
  const [draftDrawerOpen, setDraftDrawerOpen] = useState(false);
  const dispatch = useAppDispatch();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { user } = useAppSelector((state) => state.auth);
  const [time, setTime] = useState(format(new Date(), DATE_FORMAT));
  const translations = useTranslation();

  const toggleDraftDrawer = () => {
    setDraftDrawerOpen((prev) => !prev);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(format(new Date(), DATE_FORMAT));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleOnLock = () => {
    dispatch(lockProfileAction());
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Grid
      sx={{
        display: "flex",
        backgroundColor: "primary.main",
        height: "2.14285714rem",
        alignItems: "center",
        px: "1.14rem",
        width: "100%",
      }}
    >
      <div>
        <Typography sx={{ fontSize: "1.14rem", color: Colors.WHITE }}>
          {translations.companyName} | {translations.companyAddress} |{" "}
          {translations.companyPhone} | {translations.companyEmail}
        </Typography>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Button
            sx={{
              color: "white",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              textTransform: "none",
            }}
            onClick={toggleDraftDrawer}
          >
            <InsertDriveFileIcon />
            <Typography>Saved Drafts</Typography>
          </Button>
          <DraftDrawer
            isOpen={draftDrawerOpen}
            toggleDrawer={toggleDraftDrawer}
          />
          <Typography sx={{ fontSize: "1.14rem", color: Colors.WHITE }}>
            {time}&nbsp;|&nbsp;
          </Typography>
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Typography sx={{ fontSize: "1.14rem", color: Colors.WHITE }}>
              {user?.firstName && ` ${user?.firstName} ${user?.lastName}`}
            </Typography>
          </Button>
        </Box>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleOnLock}>Lock</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <IconButton
        style={{ color: "white", marginLeft: "1.43rem" }}
        onClick={() => {
          const element = document.body;

          if (document.fullscreenElement) {
            if (document.exitFullscreen) {
              document.exitFullscreen();
            }
          } else {
            if (element.requestFullscreen) {
              element.requestFullscreen();
            }
          }
        }}
      >
        <FullscreenIcon />
      </IconButton>
    </Grid>
  );
};

export { TopBar };

import { FC } from "react";
import { Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { Colors } from "../../../../configs";
import GarmentColor from "../../../../typings/enum/color";
import { CartItem } from "../../../../typings/interfaces/pos/cart-item";
import { cloneDeep } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { getAccentColor } from "../../../../util/common-functions";
import { sortArray } from "../../../../util/array-functions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { CustomButton } from "../../../atoms";

export interface IGarmentColorSectionProps {}

const colorsList = [
  {
    name: "Red",
    hex: "#FF0000",
    id: GarmentColor.RED,
  },
  {
    name: "Yellow",
    hex: "#FFFF00",
    id: GarmentColor.YELLOW,
  },
  {
    name: "Brown",
    hex: "#663300",
    id: GarmentColor.BROWN,
  },
  {
    name: "Green",
    hex: "#178515",
    id: GarmentColor.GREEN,
  },
  {
    name: "Blue",
    hex: "#0070C0",
    id: GarmentColor.BLUE,
  },
  {
    name: "Purple",
    hex: "#7030A0",
    id: GarmentColor.PURPLE,
  },
  {
    name: "Black",
    hex: "#000000",
    id: GarmentColor.BLACK,
  },
  {
    name: "White",
    hex: "#FFFFFF",
    id: GarmentColor.WHITE,
  },
  {
    name: "Gray",
    hex: "#A6A6A6",
    id: GarmentColor.GRAY,
  },
  {
    name: "Pink",
    hex: "#FF33CC",
    id: GarmentColor.PINK,
  },
  {
    name: "Burgundy",
    hex: "#990033",
    id: GarmentColor.BURGUNDY,
  },
  {
    name: "Orange",
    hex: "#FF6600",
    id: GarmentColor.ORANGE,
  },
  {
    name: "Peach",
    hex: "#FFCC99",
    id: GarmentColor.PEACH,
  },
  {
    name: "Tan",
    hex: "#D2B48C",
    id: GarmentColor.TAN,
  },
  {
    name: "Silver",
    hex: "#F2F2F2",
    id: GarmentColor.SILVER,
  },
  {
    name: "Cream",
    hex: "#FFFFCC",
    id: GarmentColor.CREAM,
  },
  {
    name: "L.Blue",
    hex: "#58A8D7",
    id: GarmentColor.L_BLUE,
  },
  {
    name: "L.Green",
    hex: "#9DDF70",
    id: GarmentColor.L_GREEN,
  },
  {
    name: "Navy",
    hex: "#002060",
    id: GarmentColor.NAVY,
  },
  {
    name: "Teal",
    hex: "#008080",
    id: GarmentColor.TEAL,
  },
  {
    name: "Beige",
    hex: "#B29574",
    id: GarmentColor.BEIGE,
  },
  {
    name: "Gold",
    hex: "#F8B200",
    id: GarmentColor.GOLD,
  },
  {
    name: "Lavender",
    hex: "#9E9EFF",
    id: GarmentColor.LAVENDER,
  },
  {
    name: "Olive",
    hex: "#A9AC01",
    id: GarmentColor.OLIVE,
  },
];

const GarmentColorSection: FC<IGarmentColorSectionProps> = () => {
  const { selectedCartItem, selectedServiceType } = useAppSelector(
    (state) => state.cart
  );
  const dispatch = useAppDispatch();

  const handleOnSelectType = (color: GarmentColor) => {
    let cartItem: CartItem;
    if (selectedCartItem) {
      cartItem = cloneDeep(selectedCartItem);
      // cartItem.garmentColor = [...(cartItem.garmentColor ?? []), color];
      // if color is already in array, then remove color
      if (cartItem.garmentColor?.includes(color)) {
        cartItem.garmentColor = cartItem.garmentColor?.filter(
          (c) => c !== color
        );
      } else {
        cartItem.garmentColor = [...(cartItem.garmentColor ?? []), color];
      }
    } else {
      cartItem = {
        serviceType: selectedServiceType ? selectedServiceType : undefined,
        garmentType: undefined,
        materialType: undefined,
        texture: undefined,
        garmentStyle: undefined,
        garmentColor: [color],
        // garmentColor: [color],
        qty: 1,
        itemId: uuidv4(),
        finalized: false,
        unitPrice: 0,
        instructions: [],
        damages: [],
        upcharges: [],
        spots: [],
        alterationItems: [],
        note: null,
      };
    }
    dispatch(updateCartSessionItem({ cartItem }));
  };

  const garmentColorSorted = sortArray({
    array: colorsList,
    sortByKey: "name",
  });

  // const itemsPerColumn =
  //   garmentColorSorted?.length <= 24
  //     ? 4
  //     : Math.ceil(garmentColorSorted?.length / 6);
  // const totalRows = Math.ceil(
  //   (garmentColorSorted?.length || 0) / itemsPerColumn
  // );
  // let nextPrevCount: number;
  // let nextPrevLastIndex: number;

  return (
    <Box
      sx={{
        minWidth: "24.928571rem",
        width: "24.928571rem",
        height: "20.78571rem",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      {/* <Box sx={{display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        gap: "0.293571rem",
        rowGap: "0.5571rem",
        width: "23.40357142857rem",
        height: "19.10857142857rem",
        overflowX: "auto",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        touchAction: "pan-x",}}>

          {[...Array(totalRows)].map((_, rowIndex) => {
          const previousItemsCount = nextPrevCount;
          const previousLastIndex = nextPrevLastIndex;

          const startingIndex = previousLastIndex!==undefined ? previousLastIndex : rowIndex*itemsPerColumn;
          const lastIndex = startingIndex+itemsPerColumn;
          const curentItemsCount = garmentColorSorted.slice(startingIndex, lastIndex).length;

          const nextStartingIndex = lastIndex;
          const nextLastIndex = nextStartingIndex+itemsPerColumn;
          const nextItemsCount = garmentColorSorted.slice(nextStartingIndex, nextLastIndex).length;

          const previousStartingIndex = (rowIndex-1) * itemsPerColumn;

          // const isPreviousShorter = previousLastIndex!==undefined && previousItemsCount!==0 && previousItemsCount < itemsPerColumn;
          const isNextShorter = nextItemsCount!==0 && curentItemsCount-1 > nextItemsCount;


          const currentRowItems = garmentColorSorted
            ? garmentColorSorted?.slice(
                // (isPreviousShorter? (startingIndex-1) : startingIndex),
                startingIndex,
                // (isNextShorter? lastIndex-(isPreviousShorter?2:1) : lastIndex-(isPreviousShorter?1:0))
                (lastIndex)
              )
            : [];
          
            nextPrevCount = curentItemsCount;
            nextPrevLastIndex = (lastIndex);

          return (
            <Box sx={{display: "flex", flexDirection: "row", gap: "0.293571rem"}}>
              {currentRowItems.map((color, i) => (
                <CustomButton
                sx={{
                  background: (color as any).hex,
                  borderRadius: "0.71rem",
                  textTransform: "capitalize",
                  color: getAccentColor((color as any).hex),
                  border: `${selectedCartItem?.garmentColor === (color as any).id ? 0.21428571 : 0.071428571}rem solid ${
                    selectedCartItem?.garmentColor === (color as any).id ? Colors.SECONDARY : Colors.COLOR_BUTTON_BORDER
                  }`,
                  height: "2.71rem",
                  width: "5.6rem",
                  padding: "0rem",
                  minWidth: "5.6rem",
                  fontSize: "1.14rem",
                  "&:hover": {
                    background: `${(color as any).hex} !important`,
                  },
                }}
                key={i}
                onClick={() => handleOnSelectType((color as any).id)}
              >
                {(color as any).name}
              </CustomButton>
              ))}
            </Box>
          );
        })}

      </Box> */}
      <Swiper
        style={{ width: "23.40357142857rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(garmentColorSorted.length / 24))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.293571rem",
                  rowGap: "0.45rem",
                  flexWrap: "wrap",
                  width: "23.40357142857rem",
                  // height: "19.10857142857rem",
                }}
              >
                {garmentColorSorted
                  .slice(swipePageIndex * 24, (swipePageIndex + 1) * 24)
                  .map((color, i) => (
                    <CustomButton
                      sx={{
                        background: (color as any).hex,
                        borderRadius: "0.71rem",
                        textTransform: "capitalize",
                        color: getAccentColor((color as any).hex),
                        border: `${
                          selectedCartItem?.garmentColor?.includes(
                            (color as any).id
                          )
                            ? 0.21428571
                            : 0.071428571
                        }rem solid ${
                          selectedCartItem?.garmentColor?.includes(
                            (color as any).id
                          )
                            ? Colors.SECONDARY
                            : Colors.COLOR_BUTTON_BORDER
                        }`,
                        height: "2.71rem",
                        width: "5.6rem",
                        padding: "0rem",
                        minWidth: "5.6rem",
                        fontSize: "1.14rem",
                        "&:hover": {
                          background: `${(color as any).hex} !important`,
                        },
                      }}
                      key={i}
                      onClick={() => handleOnSelectType((color as any).id)}
                    >
                      {(color as any).name}
                    </CustomButton>
                  ))}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default GarmentColorSection;

import { FC, useEffect, useState } from "react";
import CustomModal from "../../common/custom-modal/custom-modal";
import { AlertColor } from "@mui/material";
import { useAppSelector } from "../../../hooks/reduxHooks";
import CustomTabContainer from "../../atoms/custom-tab-panel/customTabPanel";
import ManageStoreCreditContent from "./add-store-credit";
import StoreCreditHistory from "./store-credit-history";
import { useParams } from "react-router-dom";
import { NoteOccasion } from "../../../typings/enum/noteOccasions";
import { useUserNote } from "../../../providers/UserNoteProvider";

export interface IStoreCreditsModalProps {
  open: boolean;
  onClose(): void;
  isCustomerEdited: boolean;
  resetDirtyState(isDirty: Boolean): void;
}
interface toastInterface {
  open: boolean;
  message: string;
  type: AlertColor;
}

const StoreCreditsModal: FC<IStoreCreditsModalProps> = ({
  open,
  onClose,
  isCustomerEdited,
  resetDirtyState,
}) => {
  const param = useParams();
  const { customerNotes, customerLoading } = useAppSelector(
    (state) => state.customer
  );
  const { showNote } = useUserNote();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (newValue: number) => {
    setSelectedTab(newValue);
  };

  // const PayOptionSections: { [key: string]: JSX.Element } = {
  //   CARD: <CardSection />,
  //   CASH: (
  //     <CashSection
  //       amount={amount}
  //       setAmount={setAmount}
  //       returnBalance={setBalance}
  //     />
  //   ),
  //   CHECK: <CheckSection returnAmount={setBalance} />,
  //   STORE_CREDIT: <StoreCreditSection returnAmount={setAmount} />,
  // };

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open && customerNotes && customerNotes.length > 0)
      showNote(NoteOccasion.ON_ADD_STORE_CREDIT, customerNotes);
  }, [customerNotes, open]);

  // const handleAddStoreCredit = () => {
  //   if (balance >= 0) {
  //     if (user && customer) {
  //       dispatch(
  //         addStoreCreditAction({
  //           storeUserId: user.id,
  //           customerId: customer.id,
  //           paymentType: paymentType,
  //           amount: amount,
  //         })
  //       ).then((res: any) => {
  //         if (res.payload.status === "SUCCESS") {
  //           dispatch(getCustomerByIdAction(String(customer.id)));
  //           showSnackbar("Store credit added successfully", "success");
  //         } else {
  //           showSnackbar(res.payload.message, "error");
  //         }
  //       });
  //     } else {

  //     }
  //   } else {

  //   }
  // };

  return (
    <CustomModal
      title="Manage Store Credits"
      openModal={open}
      width="95.0714286rem"
      height="69.7857143rem"
      onClose={handleOnClose}
    >
      <CustomTabContainer
        tabs={[
          {
            label: "Add Store Credit",
            disabled: customerLoading,
            content: (
              <ManageStoreCreditContent
                onSuccessfulAddStoreCredit={handleChangeTab}
                isCustomerEdited={isCustomerEdited}
                resetDirtyState={resetDirtyState}
              />
            ),
          },
          {
            label: "History",
            content: param.id ? (
              <StoreCreditHistory
                customerID={Number(param.id)}
                isCustomerEdited={isCustomerEdited}
                resetDirtyState={resetDirtyState}
              />
            ) : (
              <>Cutomer Not Found</>
            ),
          },
        ]}
        outsideConrol={{
          selectedTab: selectedTab,
          changeTab: handleChangeTab,
        }}
      />
    </CustomModal>
  );
};

export default StoreCreditsModal;

import { FC, useState } from "react";
import { Box } from "@mui/material";

import SingleDetail from "../../../atoms/single-detail/single-detail";
import { Colors } from "../../../../configs";
import { useAppSelector } from "../../../../hooks";
import useCart from "../../../../hooks/useCart";
import DiscountModal from "../../../pos/discount-modal/discountModal";
import TaxExceptionModal from "../../../pos/tax-exception-modal/TaxExceptionModal";
import { formatCurrency } from "../../../../util/common-functions";

export interface IOrderDetailSectionProps {}

const OrderDetailSection: FC<IOrderDetailSectionProps> = () => {
  const [discountModalOpen, setDiscountModalOpen] = useState(false);
  const [taxExceptionModalOpen, setTaxExceptionModalOpen] = useState(false);
  const { getPieceCount, sessionTotal, selectedSessionDiscount } = useCart();
  const { selectedSession } = useAppSelector((state) => state.cart);

  const closeDiscountModal = () => {
    setDiscountModalOpen(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        padding: "0.71rem",
        gap: "0.428571429rem",
      }}
    >
      <DiscountModal open={discountModalOpen} onClose={closeDiscountModal} />
      <TaxExceptionModal
        open={taxExceptionModalOpen}
        onClose={() => setTaxExceptionModalOpen(false)}
      />
      <SingleDetail
        label="Piece Count"
        value={getPieceCount()}
        numberOfColumns={1}
        color={Colors.PRIMARY}
      />
      <SingleDetail
        label="Subtotal"
        value={formatCurrency({ amount: sessionTotal, prefix: "$" })}
        numberOfColumns={1}
        color={Colors.PRIMARY}
      />
      <SingleDetail
        label="Discounts"
        value={formatCurrency({ amount: selectedSessionDiscount, prefix: "$" })}
        numberOfColumns={1}
        color={Colors.PRIMARY}
        onClick={() => setDiscountModalOpen(true)}
      />
      <SingleDetail
        label="Tax"
        value={formatCurrency({ amount: 0, prefix: "$" })}
        numberOfColumns={1}
        color={Colors.PRIMARY}
        onClick={() => setTaxExceptionModalOpen(true)}
      />
      <SingleDetail
        label="Total"
        value={formatCurrency({
          amount: sessionTotal - selectedSessionDiscount,
          prefix: "$",
        })}
        numberOfColumns={1}
        color={Colors.PRIMARY}
      />
    </Box>
  );
};

export default OrderDetailSection;

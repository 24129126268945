import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { deepOrange, deepPurple } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { CustomTextBox } from "../atoms";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AuthSliceAction } from "../../redux/slice/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { lockProfileAction, loginAction } from "../../redux/actions/authAction";
import { getTenantId } from "../../util/common-functions";
import { LoginUser } from "../../typings/interfaces/responses/LoginUser";

const colors = [
  deepPurple[500],
  "#001f3f",
  "#00441b",
  deepOrange[500],
  "#8b0000",
  "#8b008b",
];

interface InactivityTrackerProps {
  timeoutInSeconds?: number;
  onInactive?: () => void;
  onActive?: () => void;
}

const InactivityTracker: React.FC<InactivityTrackerProps> = ({
  timeoutInSeconds = /*180*/ 600,
  onInactive,
  onActive,
}) => {
  const [selectedUser, setSelectedUser] = useState<LoginUser>();
  const [isActive, setIsActive] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const dispatch = useAppDispatch();
  const { locked, user, token, loginUsers, loading } = useAppSelector(
    (state) => state.auth
  );
  const tenantId = getTenantId();

  useEffect(() => {
    setSelectedUser({
      email: user?.email || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
    });
  }, [user]);

  useEffect(() => {
    if (token) {
      let inactivityTimer: NodeJS.Timeout;

      const handleUserActivity = () => {
        setIsActive(true);
        clearTimeout(inactivityTimer);

        inactivityTimer = setTimeout(() => {
          setIsActive(false);

          dispatch(lockProfileAction());
          if (onInactive) {
            onInactive();
          }
        }, timeoutInSeconds * 1000);
      };

      // Attach the event listeners
      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("touchmove", handleUserActivity);

      // Set up initial timer
      inactivityTimer = setTimeout(() => {
        setIsActive(false);
        dispatch(lockProfileAction());
        if (onInactive) {
          onInactive();
        }
      }, timeoutInSeconds * 1000);

      // Clean up the event listeners
      return () => {
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("touchmove", handleUserActivity);
        clearTimeout(inactivityTimer);
      };
    }
  }, [onInactive, timeoutInSeconds, token]);

  useEffect(() => {
    // Notify when the component becomes active
    if (isActive && onActive) {
      onActive();
    }

    if (!isActive) {
      setPassword("");
    }
  }, [isActive, onActive]);

  const handleOnPasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleOnClick = () => {
    if (password !== "") {
      dispatch(
        loginAction({
          email: selectedUser?.email || "",
          password: password,
          whiteLabel: tenantId,
        })
      ).then((res) => {
        const payload: any = res.payload;
        setPasswordError(payload?.response?.message);
      });
    }
  };

  const handleOnEnter = (e: any) => {
    if (e.key === "Enter") {
      handleOnClick();
    }
  };

  const handleOnClickProfile = (user: LoginUser) => {
    setSelectedUser(user);
  };

  return (
    <Box>
      {!locked ? (
        <></>
      ) : (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.2)",
            backdropFilter: "blur(1.07142857rem)",
            width: "100vw",
            height: "100vh",
            zIndex: 9999999,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              p: 3,
              width: "23.2142857rem",
              borderRadius: "0.285714286rem",
              boxShadow: "0rem 0rem 0.571428571rem rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{ bgcolor: colors[4], width: 96, height: 96 }}
                alt={selectedUser?.firstName.charAt(0)}
                src="/broken-image.jpg"
              >
                {selectedUser?.firstName.charAt(0)}
                {selectedUser?.lastName.charAt(0)}
              </Avatar>
              <Box sx={{ my: 2 }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.42857143rem !important" }}
                >
                  {selectedUser?.firstName} {selectedUser?.lastName}
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "0.0714285714rem solid #ccc",
                  borderRadius: "0.285714286rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CustomTextBox
                  onKeyDown={handleOnEnter}
                  error={passwordError !== ""}
                  type="password"
                  onChange={handleOnPasswordChange}
                  placeholder="Passcode"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "0",
                      border: "none !important",
                    },
                  }}
                />
                <IconButton
                  disabled={loading}
                  onClick={handleOnClick}
                  aria-label="delete"
                  size="medium"
                  sx={{
                    width: "2.85714286rem",
                    height: "2.85714286rem",
                  }}
                >
                  {loading ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress size="3rem" />
                    </Box>
                  ) : (
                    <ArrowForwardIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ mt: 8, display: "flex", justifyContent: "center" }}>
              <Stack
                direction="row"
                flexWrap={"wrap"}
                justifyContent={"center"}
              >
                {loginUsers
                  .filter((item) => item.email !== selectedUser?.email)
                  .map((item, i) => {
                    return (
                      <IconButton
                        key={i}
                        aria-label="delete"
                        size="medium"
                        sx={{
                          width: "4.57142857rem",
                          height: "4.57142857rem",
                        }}
                        onClick={() => handleOnClickProfile(item)}
                      >
                        <Avatar
                          sx={{ bgcolor: colors[1], width: 48, height: 48 }}
                          alt={item.firstName}
                          src="/broken-image.jpg"
                        >
                          {item.firstName.charAt(0)}
                          {item.lastName.charAt(0)}
                        </Avatar>
                      </IconButton>
                    );
                  })}
              </Stack>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default InactivityTracker;

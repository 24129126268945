import { TreeItem, TreeItemProps, treeItemClasses } from "@mui/x-tree-view";
import { Colors } from "../../../configs/colors";
import { Collapse, styled } from "@mui/material";
import { forwardRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import { TransitionProps } from "@mui/material/transitions";

interface StyledTreeItemProps {
  type?: "parent" | "child";
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 1.42857143}rem,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const CustomTreeItem = forwardRef(
  (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
  )
);

const StyledTreeItem = styled(CustomTreeItem)<StyledTreeItemProps>(
  ({ theme, type = "child" }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: "2.57142857rem",
      // paddingLeft: "2.21428571rem",
      borderLeft: `0.28571428571rem solid ${Colors.ACCENT_GRAY}`,
      transition: "all 0.3s ease-in-out",
    },
    [`& .${treeItemClasses.content}`]: {
      padding: 0,
    },
    [`& .${treeItemClasses.content}::before`]: {
      content: `""`,
      display: type === "parent" ? "none" : "inline-block",
      minWidth: "2.21428571rem",
      marginLeft: "-2.28571428286rem",
      marginRight: "-1.2696428571428rem",
      height: "0.28571428571rem",
      background: Colors.ACCENT_GRAY,
      // background: "red"
    },
    [`&[aria-expanded=true] .${treeItemClasses.group}`]: {
      paddingTop: "0.714285714rem",
    },
    [`&[aria-expanded=false] .${treeItemClasses.group}`]: {
      paddingTop: "0rem",
    },
  })
);

const handleComponentClick = (event: React.MouseEvent) => {
  // Prevent the click event from propagating and affecting TreeView
  event.stopPropagation();
};

export default StyledTreeItem;
export { handleComponentClick };

import { Box, Button, IconButton } from "@mui/material";
import { FC } from "react";
import GarmentTypeSection from "../../../../common/pos/garment-type-section/garment-type-section";
import GarmentColorSection from "../../../../common/pos/garment-color-section/garment-color-section";
import GarmentMaterialSection from "../../../../common/pos/garment-material-section/garment-material-section";
import GarmentTextureSection from "../../../../common/pos/garment-texture-section/garment-texture-section";
import GarmentStyleSection from "../../../../common/pos/garment-style-section/garment-style-section";
import MoreIcon from "../../../../../assets/images/more";
import { Colors } from "../../../../../configs";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { addCartItem } from "../../../../../redux/actions/cartAction";
import { Link, useParams } from "react-router-dom";
import { CustomButton } from "../../../../atoms";

const Leather: FC = () => {
  const params = useParams();
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const disableAddButton = !(
    selectedCartItem?.garmentType !== undefined &&
    selectedCartItem.materialType !== undefined &&
    selectedCartItem.garmentStyle !== undefined
  );

  const handleOnAddItem = () => {
    dispatch(addCartItem());
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.57rem",
          justifyContent: "space-between",
        }}
      >
        <GarmentTypeSection />
        <GarmentColorSection />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.57rem",
          justifyContent: "space-between",
        }}
      >
        <GarmentMaterialSection />
        <GarmentTextureSection />
      </Box>
      <GarmentStyleSection />
      <Box
        sx={{
          marginTop: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0rem 0.928571429rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "2.5rem",
          }}
        >
          <IconButton
            style={{
              width: "6.39rem",
              height: "5.85rem",
              borderRadius: "50%",
            }}
          >
            <MoreIcon color={Colors.ACCENT_GRAY_DISABLED} size={45} />
          </IconButton>

          <Link to={`/pos/customer/pickup/${params.id}/`}>
            <CustomButton
              sx={{
                width: "18.92rem",
                height: "5.85rem",
                background: "none",
                border: `0.214285714rem solid ${Colors.TEXT_GRAY_DARK}`,
                color: Colors.TEXT_GRAY_DARK,
                fontSize: "2.57rem",
                lineHeight: "3rem",
                padding: "1.15142857rem 3rem",
                borderRadius: "0.71rem",
                textTransform: "capitalize",
                fontWeight: 400,
              }}
            >
              Pickups
            </CustomButton>
          </Link>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1.21428571rem",
            height: "5.85rem",
          }}
        >
          <IconButton
            sx={{
              height: "100%",
              aspectRatio: "1/1",
              background: Colors.SECONDARY,
              borderRadius: "0.71rem",
              "&:hover": {
                background: `${Colors.SECONDARY} !important`,
              },
            }}
          >
            <ReplayIcon style={{ fontSize: "3rem", color: Colors.WHITE }} />
          </IconButton>
          <CustomButton
            onClick={handleOnAddItem}
            disabled={disableAddButton}
            sx={{
              background: Colors.PRIMARY,
              color: Colors.WHITE,
              fontSize: "2.57142857rem",
              lineHeight: "3rem",
              padding: "1.15142857rem857rem 3rem",
              borderRadius: "0.71rem",
              textTransform: "capitalize",
              fontWeight: 400,
              height: "100%",
              whiteSpace: "nowrap",
              "&:hover": {
                background: `${Colors.PRIMARY} !important`,
              },
            }}
          >
            {selectedCartItem?.finalized ? "Update Item" : "Add Item"}
          </CustomButton>
        </Box> */}
      </Box>
    </>
  );
};

export default Leather;

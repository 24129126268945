import { FC, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Link, useParams } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";

import { Colors } from "../../../../configs";

import SingleDetail from "../../../atoms/single-detail/single-detail";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  formatCurrency,
  formatPhoneNumber,
  formatUSDate,
} from "../../../../util/common-functions";
import {
  getCustomerByIdAction,
  getCustomerCartInfoByIdAction,
} from "../../../../redux/actions/customerAction";
import { CustomerSliceAction } from "../../../../redux/slice/customerSlice";
import { capitalize } from "lodash";
import PreferenceShortCodes from "../../../../typings/enum/preference";
import { DiscountSliceAction } from "../../../../redux/slice/discountSlice";
import { ICustomerResponse } from "../../../../typings";

export interface ICustomerDetailSectionProps {
  showAnalysisButton: boolean;
  showEditButton: boolean;
  showHistoryButton: boolean;
}

const CustomerDetailSection: FC<ICustomerDetailSectionProps> = ({
  showAnalysisButton,
  showEditButton,
  showHistoryButton,
}) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const customerId = params.id;
  const { customerCartInfo, customerCartInfoLoading, customer } =
    useAppSelector((state) => state.customer);

  useEffect(() => {
    return () => {
      if (customerCartInfo !== null) {
        dispatch(CustomerSliceAction.clearCustomerCartInfo());
        dispatch(CustomerSliceAction.clearCustomer());
      }
    };
  }, []);

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomerCartInfoByIdAction(customerId as string));
      dispatch(getCustomerByIdAction(customerId as string)).then((res: any) => {
        if (res.payload) {
          dispatch(
            DiscountSliceAction.setSelectedDiscount(res.payload.discount)
          );
        }
      });
    }
  }, [customerId]);

  const customerPreferenceText: string =
    customerCartInfo?.preferences
      ?.map((preference) => PreferenceShortCodes[preference])
      .join("-") || "N/A";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        padding: "0.57rem 0.57rem",
        gap: "0.57rem",
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontSize: "1.714rem", lineHeight: "1.714rem", margin: 0 }}
        >
          Customer Details
        </Typography>
        <Box>
          <Link
            to={"/pos/customer/" + customerCartInfo?.customerId + "/analysis"}
            style={{ display: showAnalysisButton ? "inline" : "none" }}
          >
            <IconButton className="noneBackButton">
              <TrendingUpIcon
                sx={{ color: Colors.PRIMARY, fontSize: "1.75rem" }}
              />
            </IconButton>
          </Link>
          <Link
            to={"/pos/customer/" + customerCartInfo?.customerId + "/update"}
            style={{ display: showEditButton ? "inline" : "none" }}
          >
            <IconButton className="noneBackButton">
              <EditIcon sx={{ color: Colors.PRIMARY, fontSize: "1.75rem" }} />
            </IconButton>
          </Link>
          <Link
            to={"/pos/customer/" + customerCartInfo?.customerId + "/history"}
            style={{ display: showHistoryButton ? "inline" : "none" }}
          >
            <IconButton className="noneBackButton">
              <HistoryIcon
                sx={{ color: Colors.PRIMARY, fontSize: "1.75rem" }}
              />
            </IconButton>
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          gap: "1.78571429rem",
          rowGap: "0.357142857rem",
        }}
      >
        <SingleDetail
          label="Customer Name"
          value={customerCartInfo ? customerCartInfo?.customerName : "N/A"}
          numberOfColumns={2}
          color={Colors.SECONDARY}
        />
        <SingleDetail
          label="Preference"
          value={customerPreferenceText}
          numberOfColumns={2}
          color={Colors.SECONDARY}
        />
        <SingleDetail
          label="Customer Number"
          value={
            customerCartInfo ? String(customerCartInfo?.customerId) : "N/A"
          }
          numberOfColumns={2}
          color={Colors.SECONDARY}
        />
        <SingleDetail
          label="Customer Since"
          value={
            customerCartInfo?.customerSince
              ? formatUSDate(new Date(customerCartInfo?.customerSince))
              : "N/A"
          }
          numberOfColumns={2}
          color={Colors.SECONDARY}
        />
        <SingleDetail
          label="Mobile Phone"
          value={formatPhoneNumber(customerCartInfo?.customerPhoneNumber)}
          numberOfColumns={2}
          color={Colors.SECONDARY}
        />
        <SingleDetail
          label="Last Visit"
          value={
            customerCartInfo?.lastVisitedDate
              ? formatUSDate(new Date(customerCartInfo?.lastVisitedDate))
              : "N/A"
          }
          numberOfColumns={2}
          color={Colors.SECONDARY}
        />
        <SingleDetail
          label="Home Phone"
          value={formatPhoneNumber(customerCartInfo?.customerLandline)}
          numberOfColumns={2}
          color={Colors.SECONDARY}
        />
        <SingleDetail
          label="Spent"
          value={formatCurrency({
            amount: customerCartInfo?.totalSpent ?? 0,
            prefix: "$",
          })}
          numberOfColumns={2}
          color={Colors.SECONDARY}
        />
        <SingleDetail
          label="Address"
          value={customerCartInfo?.customerAddress || "N/A"}
          numberOfColumns={2}
          color={Colors.SECONDARY}
        />
        <SingleDetail
          label="Customer Type"
          value={
            capitalize(customerCartInfo?.customerType?.replace(/_/g, " ")) ||
            "N/A"
          }
          numberOfColumns={2}
          color={Colors.SECONDARY}
        />
      </Box>
    </Box>
  );
};

export default CustomerDetailSection;

import { FC } from "react";
import { CustomButton } from "../button/button";
import { getAccentColor } from "../../../util/common-functions";
import { Colors } from "../../../configs";

export interface IPinTagButtonProps {
  color: string;
  code: string;
  onClick: () => void;
  isSelected?: boolean;
  disabled?: boolean;
}

export const PinTagButton: FC<IPinTagButtonProps> = ({
  color,
  code,
  onClick,
  isSelected = false,
  disabled = false,
}) => {
  return (
    <CustomButton
      sx={{
        background: color,
        color: disabled ? Colors.ACCENT_GRAY : getAccentColor(color),
        width: "3.65rem",
        height: "3.65rem",
        maxWidth: "3.65rem",
        maxHeight: "3.65rem",
        minWidth: "3.65rem",
        minHeight: "3.65rem",
        border: isSelected
          ? `0.3rem solid ${Colors.TEXT_GRAY_DARK}`
          : disabled
          ? `0.19rem dashed ${Colors.ACCENT_GRAY}`
          : "none",
        fontWeight: "bold",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {code}
    </CustomButton>
  );
};

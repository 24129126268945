import { Box, Grid, Menu, MenuItem, Skeleton } from "@mui/material";
import { FC, useState } from "react";
import { Colors } from "../../../configs";
import { useTranslation } from "../../../util/translation-provider";
import { HeaderTitle } from "../../atoms/header-title/header-title";
import NotificationButton from "../notification-button/notification-button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { logoutAction } from "../../../redux/actions/authAction";
import ConfirmDialog from "../../atoms/confirm-box/confirm-box";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import { NotificationDrawerContent } from "./notification-drawe-content";
import { StoreSelectorDrawerContent } from "./store-selector-drawer-content";
import { MasterSliceActions } from "../../../redux/slice/masterSlice";

const StoreSlector: FC<{ onClick(): void }> = ({ onClick }) => {
  const { adminDashboardSelectedMerchant, adminDashboardSelectedStore } =
    useAppSelector((state) => state.master);
  return (
    <Box
      sx={{
        border: "0.0714285714rem solid",
        borderColor: "textHeader.main",
        borderRadius: "0.71rem",
        display: "flex",
        cursor: "pointer",
        "&:hover": {
          opacity: "0.9",
        },
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          color: Colors.WHITE,
          backgroundColor: "textHeader.main",
          padding: "0.57rem",
          borderRadius: "0.71rem",
          lineHeight: "1.57142857rem",
        }}
      >
        <span>Laundry</span>
      </Box>
      <Box
        sx={{
          padding: "0.57rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>
          {adminDashboardSelectedMerchant?.companyName} -{" "}
          {adminDashboardSelectedStore?.storeName}
        </span>
        <KeyboardArrowDownIcon />
      </Box>
    </Box>
  );
};

export const AdminTopNav: FC = () => {
  const translations = useTranslation();
  const dispatch = useAppDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] =
    useState(false);
  const [isStoreDrawerOpen, setIsStoreDrawerOpen] = useState(false);
  const { storeSelectorDrawerOpen } = useAppSelector((state) => state.master);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOnProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    handleClose();
  };

  const handleOpenConfirm = (openState: boolean) => {
    setConfirmOpen(openState);
  };

  const handleConfirm = () => {
    handleLogout();
    handleOpenConfirm(false);
  };

  const toggleNotificationDrawer = () => {
    setIsNotificationDrawerOpen((prevOpen) => !prevOpen);
  };

  const toggleStoreSelectorDrawer = () => {
    dispatch(
      MasterSliceActions.setStoreSelectorDrawerOpen(!storeSelectorDrawerOpen)
    );
  };

  return (
    <Grid
      sx={{
        display: "flex",
        padding: "1.14rem",
        justifyContent: "space-between",
        alignItems: "center",
        px: "2.85714286rem",
      }}
    >
      <HeaderTitle variation="page_header" title={translations.companyName} />
      <Grid sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ pr: "1.42857143rem" }}>
          <StoreSlector onClick={toggleStoreSelectorDrawer} />
        </Box>
        <Box sx={{ pr: "1.42857143rem" }}>
          <NotificationButton onClick={toggleNotificationDrawer} />
        </Box>
        <Skeleton
          variant="circular"
          width={"4rem"}
          height={"4rem"}
          sx={{ cursor: "pointer" }}
          onClick={handleOnProfileClick}
        />
      </Grid>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0rem 0rem 0.142857143rem rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => handleOpenConfirm(true)}>Logout</MenuItem>
      </Menu>
      <ConfirmDialog
        open={confirmOpen}
        title="Confirmation"
        message="Are you sure you want to proceed?"
        onCancel={() => handleOpenConfirm(false)}
        onConfirm={handleConfirm}
      />

      {/* Notification drawer */}
      <CustomDrawer
        isOpen={isNotificationDrawerOpen}
        toggleDrawer={toggleNotificationDrawer}
      >
        <NotificationDrawerContent />
      </CustomDrawer>

      {/* Store selector drawer */}
      <CustomDrawer
        isOpen={storeSelectorDrawerOpen}
        toggleDrawer={toggleStoreSelectorDrawer}
      >
        <StoreSelectorDrawerContent />
      </CustomDrawer>
    </Grid>
  );
};

import { Box, FormControlLabel, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { FC, useState } from "react";
import { useAppSelector } from "../../../hooks";
import { Colors } from "../../../configs/colors";
import IOSSwitch from "../../atoms/custom-toggle-button/custom-toggle-button";

import { TreeView } from "@mui/x-tree-view";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ParentNode from "../../atoms/tree-view/parentNode";
import ChildNode from "../../atoms/tree-view/childNode";
import CustomDateTimePicker from "../../atoms/custom-date-time-picker/custom-date-time-picker";
import { Dayjs } from "dayjs";
import { upperFirst } from "lodash";
import { CustomButton, CustomTextBox } from "../../atoms";
import useCart from "../../../hooks/useCart";
import { formatCurrency } from "../../../util/common-functions";
import { PinTagsSection } from "../../common/pos/assign-pin-tag-modal/pin-tags-section";

export interface IPaymentModalData {
  paymentMode: string;
  paidAmount: number;
}

interface ISessionDate {
  id: string;
  specialDate: Dayjs;
}
interface ISessionTime {
  id: string;
  specialTime: Dayjs;
}
interface ISessionSwitch {
  id: string;
  checked: boolean;
}
export interface ICheckInSummaryProps {
  onCheckout(data: IPaymentModalData): void;
  masterSwitch: boolean;
  sessionSwitches: ISessionSwitch[];
  masterDate: Dayjs | null;
  masterTime: Dayjs | null;
  sessionDates: ISessionDate[];
  sessionTimes: ISessionTime[];
  // setSessionDates(prevSessionDates:ISessionDate[]): void;
  // setSessionTimes(prevSessionTimes:ISessionTime[]): void;
  // setSessionSwitches(prevSessionSwitches:ISessionSwitch[]): void;
  // setMasterSwitch(prevState: any): void;
  // setMasterDate(date: Dayjs | null): void;
  // setMasterTime(time: Dayjs | null): void;
  handleOnChangeMasterSwitch(): void;
  handleOnChangeMasterDate(date: Dayjs | null): void;
  handleOnChangeMasterTime(time: Dayjs | null): void;
  handleOnChangeSessionSwitch(id: string): void;
  handleOnChangeSessionDate(id: string, date: Dayjs | null): void;
  handleOnChangeSessionTime(id: string, time: Dayjs | null): void;
}

const CheckInSummary: FC<ICheckInSummaryProps> = ({
  onCheckout,
  masterSwitch,
  sessionSwitches,
  masterDate,
  masterTime,
  sessionDates,
  sessionTimes,
  // setSessionDates,
  // setSessionTimes,
  // setSessionSwitches,
  // setMasterSwitch,
  // setMasterDate,
  // setMasterTime
  handleOnChangeMasterSwitch,
  handleOnChangeMasterDate,
  handleOnChangeMasterTime,
  handleOnChangeSessionSwitch,
  handleOnChangeSessionDate,
  handleOnChangeSessionTime,
}) => {
  const { eligibleDiscounts } = useAppSelector((state) => state.discount);
  const {
    cartTotal,
    getSessionTotal,
    getSessionItemCount,
    getCartItemPrice,
    totalDiscountAmount,
  } = useCart();
  const [selectedPaymentMode, setSelectedPaymentMode] =
    useState<string>("CASH");
  const [paidAmount, setPaidAmount] = useState<number>(0);
  const { customer } = useAppSelector((state) => state.customer);
  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(
    null
  );

  const { cart } = useAppSelector((state) => state.cart);
  const totalItemCount = cart.reduce((acc, item) => {
    return acc + item.cartItems.length;
  }, 0);

  const handleOnClickCheckout = () => {
    const data: IPaymentModalData = {
      paymentMode: selectedPaymentMode,
      paidAmount: paidAmount,
    };
    onCheckout(data);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: "1.21428571rem" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1.78571rem",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "48.6428571rem", height: "2.21428571rem" }}></Box>
        <Box
          sx={{
            width: "33.5rem",
            height: "3.57142857rem",
            background: Colors.BACKGROUND_GRAY,
            borderRadius: "0.5rem",
            py: "0.964285714rem",
            px: "1.75rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.714285714rem",
          }}
        >
          <FormControlLabel
            control={<IOSSwitch />}
            checked={masterSwitch}
            onChange={handleOnChangeMasterSwitch}
            label={
              <Typography
                sx={{
                  fontSize: "1.42857143rem",
                  lineHeight: "1.71428571rem",
                  fontWeight: 400,
                  mr: "0.964285714rem",
                }}
              >
                Special
              </Typography>
            }
            labelPlacement="start"
            sx={{ margin: 0, mr: "1.22214286rem" }}
          />
          <CustomDateTimePicker
            type="date"
            value={masterDate}
            onChange={handleOnChangeMasterDate}
            disabled={!masterSwitch}
          />
          <CustomDateTimePicker
            type="time"
            value={masterTime}
            onChange={handleOnChangeMasterTime}
            disabled={!masterSwitch}
          />
        </Box>
      </Box>
      <Box
        sx={{
          background: Colors.WHITE,
          width: "83.8571429rem",
          height: "23rem",
          borderRadius: "0.5rem",
          border: `0.0714285714rem solid ${Colors.ACCENT_GRAY}`,
          padding: "1.14285714rem",
          boxSizing: "border-box",
          overflowY: "auto",
          "&::-webkit-scrollbar": { width: "0.5rem" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ADADAD",
            borderRadius: "0rem",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "0rem",
          },
        }}
      >
        <TreeView
          aria-label="customized"
          defaultExpanded={["1"]}
          defaultExpandIcon={<ExpandMoreIcon />}
          defaultCollapseIcon={<ExpandLessIcon />}
          sx={{ overflowX: "hidden" }}
          onNodeToggle={() => false} // Disable expansion
        >
          {cart?.map((item, index) => {
            return (
              <ParentNode
                nodeID={item.sessionId}
                service={
                  item?.cartItems[0]?.serviceType?.categoryName +
                    "-" +
                    ("00" + (index + 1)).slice(-3) || item.sessionId
                }
                qty={getSessionItemCount(item.sessionId) ?? 0}
                total={getSessionTotal(item.sessionId)}
                special={sessionSwitches[index]?.checked}
                onChangeSpecial={() =>
                  handleOnChangeSessionSwitch(item.sessionId)
                }
                specialDate={sessionDates[index]?.specialDate}
                specialTime={sessionTimes[index]?.specialTime}
                onChangeSpecialDate={(date) =>
                  handleOnChangeSessionDate(item.sessionId, date)
                }
                onChangeSpecialTime={(time) =>
                  handleOnChangeSessionTime(item.sessionId, time)
                }
                masterSpecial={masterSwitch}
                onClick={() => {
                  selectedSessionId === item.sessionId
                    ? setSelectedSessionId(null)
                    : setSelectedSessionId(item.sessionId);
                }}
              >
                {item.cartItems.map((child, index) => {
                  return (
                    <ChildNode
                      nodeID={child.itemId}
                      qty={child.qty}
                      total={getCartItemPrice(item.sessionId, index)}
                      items={[
                        child.garmentType?.name,
                        child.garmentStyle?.name,
                        child.materialType?.name,
                        child.garmentColor
                          ?.map((color) => upperFirst(color.toLowerCase()))
                          .join(", "),
                        child.texture,
                      ]}
                    />
                  );
                })}
              </ParentNode>
            );
          })}
        </TreeView>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0.928571429rem 2.17857143rem",
          background: Colors.ACCENT_GRAY,
          borderRadius: "0.5rem",
        }}
      ></Box>
      <Divider
        variant="middle"
        sx={{
          width: "63.9285714rem",
          alignSelf: "center",
          height: "0.285714286rem",
          borderRadius: "1rem",
          background: Colors.ACCENT_GRAY,
        }}
      />
      <Box
        sx={{
          height: "17.2857143rem",
          display: "flex",
          flexDirection: "row",
          gap: "1.21428571rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.785714286rem",
            flexGrow: 1,
          }}
        >
          {cart.find((item) => item.sessionId === selectedSessionId)
            ?.cartItems[0]?.serviceType?.id &&
            cart.find((item) => item.sessionId === selectedSessionId)?.cartItems
              .length && (
              <PinTagsSection
                serviceTypeId={
                  cart.find((item) => item.sessionId === selectedSessionId)
                    ?.cartItems[0]?.serviceType?.id ?? 0
                }
                itemsCount={
                  // cart.find((item) => item.sessionId === selectedSessionId)
                  //   ?.cartItems.length ?? 0
                  cart
                    .find((item) => item.sessionId === selectedSessionId)
                    ?.cartItems.reduce((acc, item) => {
                      return item.finalized ? acc + item.qty : acc;
                    }, 0) ?? 0
                }
                sessionId={selectedSessionId ?? ""}
              />
            )}
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{
            width: "0.285714286rem",
            alignSelf: "center",
            height: "15rem",
            borderRadius: "1rem",
            background: Colors.ACCENT_GRAY,
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "33.1428571rem",
            height: "17.2857143rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.357142857rem",
            }}
          >
            <Box
              sx={{
                height: "7.21428571rem",
                background: Colors.BACKGROUND_GRAY,
                borderRadius: "0.571428571rem",
                display: "flex",
                flexDirection: "column",
                px: "1.41071429rem",
                py: "0.4rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "1.42857143rem" }}>
                  Total
                </Typography>
                <Typography>
                  {formatCurrency({
                    amount: cartTotal,
                    prefix: "$",
                  })}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "1.42857143rem" }}>
                  Total Discount
                </Typography>
                <Typography>
                  {formatCurrency({
                    amount: totalDiscountAmount,
                    prefix: "$",
                  })}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "2.57142857rem",
                background: Colors.BACKGROUND_GRAY,
                borderRadius: "0.571428571rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                px: "1.41071429rem",
                py: "0.285714286rem",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: "1.42857143rem" }}
              >
                Net Total:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.71428571rem",
                }}
              >
                {formatCurrency({
                  amount: cartTotal - totalDiscountAmount,
                  prefix: "$",
                })}
              </Typography>
            </Box>
          </Box>
          <CustomButton
            onClick={handleOnClickCheckout}
            sx={{
              background: Colors.PRIMARY,
              color: Colors.WHITE,
              textTransform: "capitalize",
              height: "5.85714286rem",
              fontSize: "2.57142857rem",
              width: "32.9285714rem",
              fontWeight: "bold",
              borderRadius: "0.714285714rem",
              "&:hover": {
                background: Colors.PRIMARY,
              },
            }}
          >
            Proceed to Cart
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckInSummary;

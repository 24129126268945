import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs/colors";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  addRackAction,
  getAllRacksAction,
} from "../../../../redux/actions/rackAction";
import { rackSliceActions } from "../../../../redux/slice/rackSlice";
import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import {
  Barcode,
  Br,
  Cut,
  Line,
  Printer,
  Row,
  Text,
  Image,
  render,
} from "react-thermal-printer";
import useThermalPrinter from "../../../../hooks/thermalPrinter";
import { BaseResponse, SingleRackResponse } from "../../../../typings";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

export interface IRackConfigProps {}

interface IColumn {
  id: "id" | "rackLocation";
  label: string;
  align?: "right" | "center" | "left";
}
const columns: readonly IColumn[] = [
  { id: "id", label: "Rack ID", align: "left" },
  { id: "rackLocation", label: "Rack Location", align: "center" },
];

// interface IRow {
//   id: number;
//   rackLocation: string;
// }
function createRow(id: number, rackLocation: string): SingleRackResponse {
  return { id, rackLocation };
}

// const rows = [
//   createRow(1, "Something 1"),
//   createRow(2, "Something 2"),
//   createRow(3, "Something 3"),
//   createRow(4, "Something 4"),
//   createRow(5, "Something 5"),
//   createRow(6, "Something 6"),
//   createRow(7, "Something 7"),
//   createRow(8, "Something 8"),
//   createRow(9, "Something 9"),
//   createRow(10, "Something 10"),
// ];

// export interface IResponseType {
//   body: IRow[];
//   status: number;
//   message: string;
// }

const RackConfig: FC<IRackConfigProps> = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [responseData, setResponseData] =
    useState<BaseResponse<SingleRackResponse> | null>(null);
  const [rows, setRows] = useState<SingleRackResponse[]>([]);
  const [newRackLocation, setNewRackLocation] = useState("");
  const snackbar = useSnackbarContext();

  const { initialize, print, configure, isConfigured } = useThermalPrinter();

  const dispatch = useAppDispatch();
  const { addRacks, addRacksLoading, getRacks, getRacksLoading } =
    useAppSelector((state) => state.rack);

  const loadData = () => {
    dispatch(getAllRacksAction()).then((res) => {
      const responseData: any = res.payload;
      setResponseData(responseData.response);
    });
    //setResponseData(getRacks);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (responseData != null) {
      const tempRows: SingleRackResponse[] = [];
      responseData.body.forEach((element: SingleRackResponse) => {
        tempRows.push(createRow(element.id, element.rackLocation));
      });
      setRows(tempRows);
    }
  }, [responseData]);

  const handleAddRack = (rackLocation: string) => {
    if (newRackLocation === null || newRackLocation === "") {
      snackbar.showSnackbar("Please enter a valid rack location", "warning");
    } else {
      dispatch(addRackAction(rackLocation)).then((res) => {
        const responseData: any = res.payload;
        //setResponseData(responseData.response);
        loadData();
      });
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    initialize();
  }, []);

  // const printRackSticker = (rackId: number) => {

  //   printMe();
  // };

  const printMe = async (rackId: number) => {
    const renderData = await render(
      <Printer type="epson" width={42}>
        {/* <Barcode type="UPC-A" content="13000308" /> */}
        <Barcode
          align="center"
          type="CODE39"
          content={"13000" + rackId}
          height={80}
        />
        <Cut />
      </Printer>
    );
    // const port = await window.navigator.serial.requestPort();
    // await port.open({ baudRate: 9600 });

    // const writer = port.writable?.getWriter();
    // if (writer != null) {
    //   await writer.write(data);
    //   writer.releaseLock();
    // }
    print(renderData);
  };

  // const handleOnCheckout = async (data: IPaymentModalData) => {
  //   if (tenantId)
  //     dispatch(newBillAction({ tenant: tenantId })).then(async (res) => {
  //       setOpenCheckInMdal(false);
  //       const payload: any = res?.payload;
  //       const subTotal = getSubTotal();
  //       const now = new Date();
  //       const localDate = now.toLocaleDateString();
  //       const printerRenderData = await render(
  //         <Printer type="epson" width={60} characterSet="korea">
  //           <Text size={{ width: 2, height: 2 }}>9,500원</Text>
  //           <Text>==============================</Text>
  //           <Barcode type="UPC-A" content="111111111111" />
  //           <Line character="=" />
  //         </Printer>
  //       );
  //       print(printerRenderData);
  //     });
  // };

  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "2rem 4rem",
          gap: "3rem",
        }}
      >
        <Typography variant="h2">Rack Config</Typography>
        <form>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "2rem 4rem",
              borderRadius: "0.75rem",
              border: `0.0625rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              background: Colors.BACKGROUND_GRAY,
              gap: "1.5rem",
              width: "40rem",
            }}
          >
            <TextField
              sx={{ flex: 1 }}
              label="Rack Location"
              variant="outlined"
              value={newRackLocation}
              onChange={(e) => setNewRackLocation(e.target.value)}
            />
            <CustomButton
              variant="contained"
              color="primary"
              disabled={addRacksLoading}
              sx={{
                background: Colors.PRIMARY,
                color: Colors.WHITE,
                padding: "0.65rem 2rem",
                fontSize: "1.15rem",
                textTransform: "capitalize",
                whiteSpace: "nowrap",
              }}
              onClick={() => handleAddRack(newRackLocation)}
            >
              {addRacksLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Add Rack"
              )}
            </CustomButton>
            <CustomButton
              onClick={configure}
              disabled={isConfigured}
              type="button"
              sx={{ background: Colors.ACCENT_GRAY }}
            >
              Config Printer
            </CustomButton>
          </Box>
        </form>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{ fontWeight: "bold" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell
                    key="Actions"
                    align={"right"}
                    sx={{ fontWeight: "bold" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!getRacksLoading &&
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          })}
                          <TableCell key={"Actions"} align="right">
                            <CustomButton
                              variant="contained"
                              size="small"
                              sx={{
                                background: Colors.SECONDARY,
                                color: Colors.WHITE,
                                textTransform: "capitalize",
                                whiteSpace: "nowrap",
                              }}
                              onClick={() => {
                                printMe(row.id);
                              }}
                            >
                              Print
                            </CustomButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {getRacksLoading ? (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        </Paper>
      </Box>
    </AdminLayout>
  );
};

export default RackConfig;

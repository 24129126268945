import { FC, useEffect, useState } from "react";

import { Box, IconButton, Typography, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { Colors } from "../../../../configs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { cloneDeep } from "lodash";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";

export interface ICartQtyHandlerSectionProps {}
const CartQtyHandlerSection: FC<ICartQtyHandlerSectionProps> = () => {
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const disabled = selectedCartItem === null;

  const doArithmatic = (value: number) => {
    const temItem = cloneDeep(selectedCartItem);
    if (temItem) {
      temItem.qty = temItem?.qty + value;
      dispatch(updateCartSessionItem({ cartItem: temItem }));
    }
  };

  const handleCountIncrement = () => {
    doArithmatic(1);
  };
  const handleCountDecrement = () => {
    doArithmatic(-1);
  };
  const handleEditCount = (num: string) => {
    const parsedNum = parseInt(num);
    if (isNaN(parsedNum)) {
      doArithmatic(0);
    } else {
      doArithmatic(parsedNum);
    }
  };
  const handleDefocus = () => {
    if (selectedCartItem?.qty == 0) {
      doArithmatic(1);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.71rem",
        background: Colors.BACKGROUND_GRAY,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "3.21428571rem",
          padding: "0.71rem 2.85714286rem 0.71rem 2.85714286rem",
        }}
      >
        <Typography variant="h5">Count</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1.07142857rem",
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{
              borderRadius: "0.71rem 0 0 0.71rem",
              background: Colors.SECONDARY,
              width: "3.35714286rem",
              height: "2.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: Colors.WHITE,
              "&:hover": {
                background: `${Colors.SECONDARY} !important`,
              },
            }}
            onClick={handleCountDecrement}
            disabled={disabled || selectedCartItem.qty === 1}
          >
            <RemoveIcon />
          </IconButton>
          <Box
            sx={{
              borderRadius: "50%",
              width: "3.64rem",
              height: "3.64rem",
              background: Colors.SECONDARY,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: Colors.WHITE,
              outline: "none",
            }}
          >
            <TextField
              sx={{
                aspectRatio: "1/1",
                outline: "none",
                border: "none",
                "& input": { border: "none" },
                "& fieldset": { border: "none !important" },
                "& fieldset:hover": { border: "none !important" },
                padding: 0,
              }}
              value={selectedCartItem?.qty}
              onChange={(e) => handleEditCount(e.target.value)}
              onBlur={handleDefocus}
              inputProps={{
                // disableUnderline: true,
                style: {
                  color: Colors.WHITE,
                  textAlign: "center",
                  fontSize: "1.42rem",
                  outline: "none",

                  // width: "3.64rem",
                  // height: "3.64rem",
                },
              }}
              disabled={disabled}
            />
          </Box>
          <IconButton
            sx={{
              borderRadius: "0 0.71rem 0.71rem 0",
              background: Colors.SECONDARY,
              width: "3.35714286rem",
              height: "2.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: Colors.WHITE,
              "&:hover": {
                background: `${Colors.SECONDARY} !important`,
              },
            }}
            onClick={handleCountIncrement}
            disabled={disabled}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CartQtyHandlerSection;

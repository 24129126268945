import { FC } from "react";
import { Box, Button, Typography } from "@mui/material";

import { Colors } from "../../../configs";
import { SubcategoryResponse } from "../../../typings";
import { ITypeItem } from "../../../typings/interfaces/pos/typeItem";

export interface IGarmentTypeButtonProps {
  data: ITypeItem;
  selected: boolean;
  onClick(id: ITypeItem): void;
}

const GarmentTypeButton: FC<IGarmentTypeButtonProps> = ({
  data,
  selected,
  onClick,
}) => {
  const handleOnClickItem = () => {
    onClick(data);
  };

  return (
    <Button
      sx={{
        background: selected ? Colors.SECONDARY : Colors.MAIN_GRAY,
        borderRadius: "0.71rem",
        padding: "0.57rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
        width: "5.59rem",
        minWidth: "5.5rem",
        height: "8.357rem",
        "&:hover": {
          background: `${
            selected ? Colors.SECONDARY : Colors.MAIN_GRAY
          } !important`,
        },
      }}
      onClick={handleOnClickItem}
    >
      <Box
        component="img"
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
        src={`/images/dress/${data?.icon}`}
      />
      <Box
        sx={{
          position: "absolute",
          top: "0rem",
          left: "0rem",
          right: "0rem",
          bottom: "0rem",
          background: `linear-gradient(to bottom, transparent 60%, ${Colors.BLACK} 100%)`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          borderRadius: "0.71rem",
          padding: "0.57rem",
        }}
      >
        <Typography
          sx={{
            color: Colors.WHITE,
            textAlign: "center",
            fontSize: "0.785714286rem",
            textTransform: "capitalize",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          {data.name}
        </Typography>
      </Box>
    </Button>
  );
};

export default GarmentTypeButton;

import { FC } from "react";
import { Box, Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { Colors } from "../../../../configs/colors";
import GarmentTexture from "../../../../typings/enum/texture-type";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartItem } from "../../../../typings/interfaces/pos/cart-item";
import { cloneDeep } from "lodash";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { sortArray } from "../../../../util/array-functions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { CustomButton } from "../../../atoms";

const garmentTextures = [
  {
    label: "Striped",
    id: GarmentTexture.STRIPED,
  },
  {
    label: "Plaid",
    id: GarmentTexture.PLAID,
  },
  {
    label: "Plain",
    id: GarmentTexture.PLAIN,
  },
  {
    label: "Dots",
    id: GarmentTexture.DOTS,
  },
  {
    label: "Print",
    id: GarmentTexture.PRINT,
  },
  {
    label: "Paisley",
    id: GarmentTexture.PAISLEY,
  },
  {
    label: "Mixed",
    id: GarmentTexture.MIXED,
  },
  {
    label: "Check",
    id: GarmentTexture.CHECK,
  },
  {
    label: "Flowers",
    id: GarmentTexture.FLOWERS,
  },
  {
    label: "Glitter",
    id: GarmentTexture.GLITTER,
  },
  {
    label: "Camo.",
    id: GarmentTexture.CAMO,
  },
  {
    label: "Tweed",
    id: GarmentTexture.TWEED,
  },
];

export interface IGarmentTextureSectionProps {}
const GarmentTextureSection: FC<IGarmentTextureSectionProps> = ({}) => {
  const { selectedCartItem, selectedServiceType } = useAppSelector(
    (state) => state.cart
  );
  const dispatch = useAppDispatch();

  const handleOnSelectType = (texture: GarmentTexture) => {
    let cartItem: CartItem;
    if (selectedCartItem) {
      cartItem = cloneDeep(selectedCartItem);
      cartItem.texture = texture;
    } else {
      cartItem = {
        serviceType: selectedServiceType ? selectedServiceType : undefined,
        garmentType: undefined,
        materialType: undefined,
        texture: texture,
        garmentStyle: undefined,
        garmentColor: undefined,
        qty: 1,
        itemId: uuidv4(),
        finalized: false,
        unitPrice: 0,
        instructions: [],
        damages: [],
        upcharges: [],
        spots: [],
        alterationItems: [],
        note: null,
      };
    }
    dispatch(updateCartSessionItem({ cartItem }));
  };

  const garmentTextureSorted = sortArray({
    array: garmentTextures,
    sortByKey: "label",
  });
  /* duplicate last item for ui testing purposes */
  // if (garmentTextureSorted && garmentTextureSorted.length > 0) {
  //   const duplicatedLastItem = { ...garmentTextureSorted[garmentTextureSorted.length - 1] };
  //   garmentTextureSorted.push(duplicatedLastItem);
  //   garmentTextureSorted.push(duplicatedLastItem);
  // }

  const itemsPerColumn =
    garmentTextureSorted?.length <= 12
      ? 4
      : Math.ceil(garmentTextureSorted?.length / 3);
  const totalRows = Math.ceil(
    (garmentTextureSorted?.length || 0) / itemsPerColumn
  );
  let nextPrevCount: number;
  let nextPrevLastIndex: number;

  return (
    <Box
      sx={{
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "21.5rem",
        height: "11.5714286rem",
      }}
    >
      {/* <Box sx={{display: "flex", flexDirection: "column", rowGap: "0.61571rem", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "flex-start", width: "19.9571rem", minWidth: "19.9571rem", height: "8.855rem",
        overflowX: "auto",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        touchAction: "pan-x"}}>
          {[...Array(totalRows)].map((_, rowIndex) => {
          const previousItemsCount = nextPrevCount;
          const previousLastIndex = nextPrevLastIndex;
          const startingIndex = previousLastIndex!==undefined ? previousLastIndex : rowIndex*itemsPerColumn;
          const lastIndex = startingIndex+itemsPerColumn;
          const curentItemsCount = garmentTextureSorted.slice(startingIndex, lastIndex).length;

          const nextStartingIndex = lastIndex;
          const nextLastIndex = nextStartingIndex+itemsPerColumn;
          const nextItemsCount = garmentTextureSorted.slice(nextStartingIndex, nextLastIndex).length;

          const previousStartingIndex = (rowIndex-1) * itemsPerColumn;

          // const isPreviousShorter = previousLastIndex!==undefined && previousItemsCount!==0 && previousItemsCount < itemsPerColumn;
          const isNextShorter = nextItemsCount!==0 && curentItemsCount-1 > nextItemsCount;

          


          const currentRowItems = garmentTextureSorted
            ? garmentTextureSorted.slice(
                startingIndex,
                (lastIndex)
              )
            : [];
            
          
            nextPrevCount = curentItemsCount;
            nextPrevLastIndex = (lastIndex);

        return (
          <Box sx={{display: "flex", flexDirection: "row", gap: "0.253214286rem"}}>
            {currentRowItems.map((texture, i) => (
            <CustomButton
              sx={{
                width: "4.79578571rem",
                height: "2.541rem",
                maxWidth: "4.79578571rem",
                maxHeight: "2.541rem",
                minWidth: "4.79578571rem",
                minHeight: "2.541rem",
                fontSize: "1rem",
                background:
                  selectedCartItem?.texture === (texture as any).id
                    ? Colors.SECONDARY
                    : Colors.MAIN_GRAY,
                borderRadius: "0.71rem",
                textTransform: "capitalize",
                color: Colors.WHITE,
                "&:hover": {
                  background: `${
                    selectedCartItem?.texture === (texture as any).id
                      ? Colors.SECONDARY
                      : Colors.MAIN_GRAY
                  } !important`,
                },
                padding:"0",
                boxSizing: "border-box",
              }}
              key={i}
              onClick={() => handleOnSelectType((texture as any).id)}
            >
              {(texture as any).label}
            </CustomButton>
              ))}
            </Box>
          );
        })}
        </Box> */}
      <Swiper
        style={{ width: "19.9571rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(garmentTextureSorted.length / 12))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.2rem",
                  rowGap: "0.616rem",
                  flexWrap: "wrap",
                  width: "19.9571rem",
                  // height: "8.855rem",
                  // background: "blue",
                }}
              >
                {garmentTextureSorted
                  .slice(swipePageIndex * 12, (swipePageIndex + 1) * 12)
                  .map((texture, i) => (
                    // <GarmentTypeButton
                    //   data={mapItem as any}
                    //   selected={
                    //     selectedCartItem?.garmentType?.id ===
                    //     (mapItem as any).id
                    //   }
                    //   onClick={() => handleOnSelectType(mapItem as any)}
                    //   key={i}
                    // />
                    <CustomButton
                      sx={{
                        width: "4.79578571rem",
                        height: "2.541rem",
                        maxWidth: "4.79578571rem",
                        maxHeight: "2.541rem",
                        minWidth: "4.79578571rem",
                        minHeight: "2.541rem",
                        fontSize: "1rem",
                        background:
                          selectedCartItem?.texture === (texture as any).id
                            ? Colors.SECONDARY
                            : Colors.MAIN_GRAY,
                        borderRadius: "0.71rem",
                        textTransform: "capitalize",
                        color: Colors.WHITE,
                        "&:hover": {
                          background: `${
                            selectedCartItem?.texture === (texture as any).id
                              ? Colors.SECONDARY
                              : Colors.MAIN_GRAY
                          } !important`,
                        },
                        padding: "0",
                        boxSizing: "border-box",
                      }}
                      key={i}
                      onClick={() => handleOnSelectType((texture as any).id)}
                    >
                      {(texture as any).label}
                    </CustomButton>
                  ))}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default GarmentTextureSection;

import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FC } from "react";
// import { HeaderTitle } from "../../components/atoms/header-title/header-title";
import { AdminLayout } from "../../components/layouts/admin-layout/admin-layout";
import BarChart from "./demo/demo-bar-chart";

export const AdminDashboardPage: FC = () => {
  return (
    <AdminLayout>
      <Grid container>
        {/* <DateRangePicker label={""} value={[]} onChange={function (newValue: [Date | null, Date | null]): void {
                  throw new Error("Function not implemented.");
              } }/> */}
        <Grid xs={6}>
          <Box sx={{ height: "28.5714286rem" }}>
            <Typography
              sx={{
                color: "textHeader.main",
                fontSize: "1.14rem",
                fontWeight: "bold",
              }}
            >
              Saled Overview
            </Typography>
            <BarChart />
          </Box>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

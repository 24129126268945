import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { AddServicePinTagDrawer } from "../../../../components/admin/add-pintag-drawe/add-service-pintag-drawer";
import { useParams } from "react-router-dom";
import { CustomButton } from "../../../../components/atoms";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import {
  deleteServicePinTagAction,
  getAllServicePinTagsAction,
} from "../../../../redux/actions/pintagAction";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";
import EditIcon from "@mui/icons-material/Edit";

export interface IPinTagServicesProps {}

// const servicePintags = [
//   {
//     fixedItem: true,
//     service: "Alteration",
//     item: null,
//     description: "Lorem Ipsum dsvbjsbdv l jsavd",
//     tagCount: 7,
//   },
//   {
//     fixedItem: false,
//     service: "Dry Clean",
//     item: "Waist",
//     description: "Lorem Ipsum dsvbjsbdv l jsavd",
//     tagCount: 5,
//   },
// ];

export const PinTagServices: FC<IPinTagServicesProps> = () => {
  const [openAddServicePinTagDrawer, setOpenAddPinTagDrawer] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState<null | number>(null);

  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();
  const {
    getServicePintags,
    getServicePintagsError,
    getServicePintagsLoading,
  } = useAppSelector((state) => state.pintag);

  useEffect(() => {
    dispatch(getAllServicePinTagsAction());
  }, []);
  useEffect(() => {
    getServicePintagsError &&
      snackbar.showSnackbar(getServicePintagsError, "error");
  }, [getServicePintagsError]);

  const handleOnToggleAddServicePinTagDrawer = () => {
    setEditMode(false);
    setOpenAddPinTagDrawer((state) => !state);
  };
  const params = useParams();
  const storeID = Number(params.storeID);

  const handleOnDeleteServicePinTag = (servicePinTagId: number) => {
    dispatch(deleteServicePinTagAction(servicePinTagId))
      .then(() => {
        dispatch(getAllServicePinTagsAction());
        snackbar.showSnackbar(
          "Service Pin Tag deleted successfully",
          "success"
        );
      })
      .catch((err) => {
        snackbar.showSnackbar(err.message, "error");
      });
  };
  const handleOnEditServicePinTag = (servicePinTagId: number) => {
    setEditMode(true);
    setEditId(servicePinTagId);
    setOpenAddPinTagDrawer((state) => !state);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        // background: "red",
        pt: "1rem",
        height: "55rem",
      }}
    >
      {storeID && (
        <AddServicePinTagDrawer
          open={openAddServicePinTagDrawer}
          toggleDrawer={handleOnToggleAddServicePinTagDrawer}
          storeId={storeID}
          editMode={editMode}
          populateData={getServicePintags?.body.find(
            (tag) => tag.id === editId
          )}
        />
      )}

      <Box
        sx={{
          width: "76.0714286rem",
          display: "flex",
          flexDirection: "column",
          gap: "3.5rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
          <CustomButton
            sx={{ px: "3rem" }}
            onClick={handleOnToggleAddServicePinTagDrawer}
          >
            Add New Pin Tag Book
          </CustomButton>
        </Box>

        <Box
          sx={{
            maxHeight: "45.42857143rem",
            width: "100%",
            overflowY: "auto",
            // make scrollbar style looks better
            "&::-webkit-scrollbar": {
              width: "0.5rem",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Tag Count</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {getAllRackBillsLoading || registerRackBillLoading ? (
      <TableRow>
        <TableCell colSpan={4} align="center">
          <CircularProgress />
        </TableCell>
      </TableRow>
    ) : null}
    {!(registerRackBillLoading || getAllRackBillsLoading) &&
      bagRacks.map((bagRack, i) => ( */}
              {getServicePintagsLoading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : null}
              {getServicePintags?.body?.map((servicePintag, i) => (
                <TableRow key={i}>
                  <TableCell>
                    ID:{servicePintag.serviceTypeId}
                    <br />
                    Name:N/A
                  </TableCell>
                  <TableCell>{servicePintag.description}</TableCell>
                  <TableCell>{servicePintag.tagCount}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        handleOnEditServicePinTag(servicePintag.id)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        handleOnDeleteServicePinTag(servicePintag.id)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {/* ))} */}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};

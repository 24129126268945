import { CustomerSearchMode } from "../typings/enum/CustomerSearchMode";

export const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = "http://192.168.8.158:8080";
// export const BASE_URL = "https://b267-2402-4000-1182-b432-e9fb-74a1-7b46-38d7.ap.ngrok.io"
// export const BASE_URL = "http://prestovaletdev-env.eba-gd225xxa.us-east-1.elasticbeanstalk.com";

// export const BASE_URL = "https://api.drycleanx.com";

// Auth endpoints
export const LOGIN_URL = `${BASE_URL}/auth/sign-in`;
export const SEARCH_CUSTOMER = (query: string) =>
  `${BASE_URL}/customer/search?query=${query}`;
export const CREATE_CUSTOMER = `${BASE_URL}/customer/create`;
export const UPDATE_CUSTOMER = `${BASE_URL}/customer/update`;
export const CHANGE_PASSWORD = `${BASE_URL}/auth/password/change`;
export const LOGIN_USERS = `${BASE_URL}/user/get-login-users`;

// Pricing endpoints
export const GET_ALL_CATEGORIES = `${BASE_URL}/category`;
export const GET_ALL_SECOND_SUB_CATEGORIES = `${BASE_URL}/second-subcategory`;
export const GET_ALL_SUB_CATEGORIES = (id: number) =>
  `${BASE_URL}/subcategory?category=${id}`;
export const GET_ALL_PRICINGS = (id: number) =>
  `${BASE_URL}/price?category=${id}`;
export const UPLOAD_PRICE_LIST = `${BASE_URL}/price/upload-prices`;

export const GET_CATEGORY_RELATIONSHIP = `${BASE_URL}/price/relationship/category`;
export const GET_CATEGORY_MATERIAL_RELATIONSHIP = `${BASE_URL}/price/relationship/category-material`;
export const GET_SUB_CATEGORY_MATERIAL_RELATIONSHIP = `${BASE_URL}/price/relationship/sub-category-material`;

export const GET_ALTERATION_SUB_CATEGORIES = `${BASE_URL}/alteration-sub-category`;
export const GET_ALL_ALTERATION_PRICE = `${BASE_URL}/alteration`;

export const GET_FABRIC_TYPES = (id: number) =>
  `${BASE_URL}/fabrics?category=${id}`;

// StoreCredit endpoints
export const ADD_STORE_CREDIT = `${BASE_URL}/store-credit/add`;
export const GET_STORE_CREDIT_HISTORY = (id: number) =>
  `${BASE_URL}/store-credit/customers/${id}/history`;
export const DELETE_STORE_CREDIT = (customerId: number, noteId: number) =>
  `${BASE_URL}/store-credit/customer/${customerId}/credit/${noteId}`;

// Customer Endpoints
export const GET_CUSTOMER_BY_ID = (id: string) => `${BASE_URL}/customer/${id}`;
export const GET_CUSTOMER_BILLS_BY_ID = (id: string) =>
  `${BASE_URL}/bill/customer/${id}`;
export const GET_CUSTOMER_CART_INFO_BY_ID = (id: string) =>
  `${BASE_URL}/customer/${id}/cart-info`;

export const NEW_BILL = `${BASE_URL}/bill/new-bill`;
export const GET_BILL_BY_ID = (billId: string) => `${BASE_URL}/bill/${billId}`;

// Rack Endpoints
export const GET_ALL_RACKS = `${BASE_URL}/rack`;
export const ADD_RACK = GET_ALL_RACKS;
export const REGISTER_RACK_BILL = `${BASE_URL}/rack-bill-register`;
export const GET_ALL_RACK_BILL = `${BASE_URL}/rack-bill-register/get-all`;

// User Notes Endpoints
export const CREATE_AND_UPDATE_USER_NOTE = `${BASE_URL}/user-note`;
export const GET_USER_NOTES_BY_ID = (id: string) =>
  `${BASE_URL}/user-note/customer/${id}`;
export const DELETE_USER_NOTE = (id: string) => `${BASE_URL}/user-note/${id}`;

// Bill Notes Endpoints
export const CREATE_AND_UPDATE_BILL_NOTE = `${BASE_URL}/bill-note`;
export const GET_BILL_NOTES_BY_ID = (id: string) =>
  `${BASE_URL}/bill-note/bill/${id}`;
export const DELETE_BILL_NOTE = (id: string) => `${BASE_URL}/bill-note/${id}`;

// Discount endpoints
export const GET_ALL_STORE_DISCOUNTS = (id: number) =>
  `${BASE_URL}/discount/store/${id}`;
export const CREATE_DISCOUNT = `${BASE_URL}/discount`;
export const DELETE_DISCOUNT = (id: string) => `${BASE_URL}/discount/${id}`;
export const UPDATE_DISCOUNT = `${BASE_URL}/discount`;

// Merchant endpoints
export const GET_MERCHANT_DETAILS = (whiteLabel: string) =>
  `${BASE_URL}/merchant/${whiteLabel}`;
export const GET_MERCHANT_STORES = (whiteLabel: string) =>
  `${BASE_URL}/merchant/${whiteLabel}/stores`;
export const GET_ALL_MERCHANTS = `${BASE_URL}/merchant/all`;

// Payment endpoints
export const ADD_PAYMENT = `${BASE_URL}/payment/create`;

// store endpoints
export const ADD_STORE_CONFIG = `${BASE_URL}/store-config`;
export const GET_STORE_CONFIG = (storeId: number, merchantId: number) =>
  `${BASE_URL}/store-config/merchant/${merchantId}/store/${storeId}`;

// Pintag endpoints
export const GET_ALL_COLOR_PINTAGS = `${BASE_URL}/pintag-color`;
export const CREATE_COLOR_PINTAG = GET_ALL_COLOR_PINTAGS;
export const UPDATE_COLOR_PINTAG = GET_ALL_COLOR_PINTAGS;
export const DELETE_COLOR_PINTAG = (id: number) =>
  `${BASE_URL}/pintag-color/${id}`;
export const UPDATE_COLOR_PINTAG_ORDER = `${BASE_URL}/pintag-color/reorder`;

export const GET_ALL_SERVICE_PINTAGS = `${BASE_URL}/service-pintag`;
export const CREATE_SERVICE_PINTAG = GET_ALL_SERVICE_PINTAGS;
export const UPDATE_SERVICE_PINTAG = GET_ALL_SERVICE_PINTAGS;
export const DELETE_SERVICE_PINTAG = (id: number) =>
  `${BASE_URL}/service-pintag/${id}`;

import {
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState, MouseEvent } from "react";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs";
import { ReOrderPinTagsDrawer } from "../../../../components/admin/reorder-pintag-drawer/ReOrderPinTagDrawer";
import { useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AddPinTagDrawer } from "../../../../components/admin/add-pintag-drawe/add-pintag-drawer";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import {
  deleteColorPinTagAction,
  getAllColorPinTagsAction,
} from "../../../../redux/actions/pintagAction";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StoreIcon from "@mui/icons-material/Store";
import { set } from "lodash";

export interface IPinTagColorsProps {}

export const PinTagColors: FC<IPinTagColorsProps> = () => {
  const [openOrderPinTagDrawer, setOpenOrderPinTagDrawer] = useState(false);
  const [openAddPinTagDrawer, setOpenAddPinTagDrawer] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useAppDispatch();
  const {
    getColorPintags,
    getColorPintagsLoading,
    deleteColorPintagLoading,
    getColorPintagsError,
  } = useAppSelector((state) => state.pintag);
  const snackbar = useSnackbarContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openContextMenu = Boolean(anchorEl);
  const [contextMenuId, setContextMenuId] = useState<null | number>(null);
  const [editId, setEditId] = useState<null | number>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setContextMenuId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setContextMenuId(null);
  };

  useEffect(() => {
    dispatch(getAllColorPinTagsAction()).catch((err) => {
      snackbar.showSnackbar(err.message, "error");
    });
  }, []);
  // useEffect(() => {
  //   getColorPintagsError &&
  //     snackbar.showSnackbar(getColorPintagsError, "error");
  // }, [getColorPintagsError]);

  const handleOnToggleOrderPinTagDrawer = () => {
    setOpenOrderPinTagDrawer((state) => !state);
  };
  const handleOnToggleAddPinTagDrawer = () => {
    setEditMode(false);
    setOpenAddPinTagDrawer((state) => !state);
  };

  const params = useParams();
  const storeID = Number(params.storeID);

  const sortedTags = [...(getColorPintags?.body ?? [])].sort(
    (a, b) => a.orderIndex - b.orderIndex
  );

  const handleOnDeleteColorPinTag = (colorPinTagId: number) => {
    dispatch(deleteColorPinTagAction(colorPinTagId))
      .then(() => {
        snackbar.showSnackbar("Color PinTag deleted successfully", "success");
        dispatch(getAllColorPinTagsAction());
        handleClose();
      })
      .catch((err) => snackbar.showSnackbar(err.message, "error"));
  };
  const handleOnEditColorPinTag = (colorPinTagId: number) => {
    setEditId(colorPinTagId);
    setEditMode(true);
    setOpenAddPinTagDrawer(true);
    handleClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        // background: "red",
        pt: "1rem",
        height: "55rem",
      }}
    >
      {storeID && (
        <ReOrderPinTagsDrawer
          open={openOrderPinTagDrawer}
          toggleDrawer={handleOnToggleOrderPinTagDrawer}
          storeId={storeID}
        />
      )}
      {storeID && (
        <AddPinTagDrawer
          open={openAddPinTagDrawer}
          toggleDrawer={handleOnToggleAddPinTagDrawer}
          storeId={storeID}
          editMode={editMode}
          populateData={getColorPintags?.body.find((tag) => tag.id === editId)}
        />
      )}

      <Box
        sx={{
          width: "76.0714286rem",
          display: "flex",
          flexDirection: "column",
          gap: "3.5rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
          <CustomButton
            sx={{
              px: "3rem",
              color: Colors.WHITE,
              background: Colors.MAIN_GRAY,
            }}
            onClick={handleOnToggleOrderPinTagDrawer}
          >
            Order Pin Tags
          </CustomButton>
          <CustomButton
            sx={{ px: "3rem" }}
            onClick={handleOnToggleAddPinTagDrawer}
          >
            Add New Pin Tag
          </CustomButton>
        </Box>
        <Box
          sx={{
            maxHeight: "45.42857143rem",
            width: "100%",
            overflowY: "auto",
            // make scrollbar style looks better
            "&::-webkit-scrollbar": {
              width: "0.5rem",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell>Color</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Identification</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getColorPintagsLoading || deleteColorPintagLoading ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : null}
              {!getColorPintagsLoading &&
                !deleteColorPintagLoading &&
                sortedTags.map((tag, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Box
                        sx={{
                          width: "6.64285714rem",
                          height: "1.28rem",
                          background: tag.color,
                        }}
                      />
                    </TableCell>
                    <TableCell>{tag.description}</TableCell>
                    <TableCell>{tag.alias}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={(e) => handleClick(e, tag.id)}>
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openContextMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    contextMenuId && handleOnEditColorPinTag(contextMenuId);
                  }}
                >
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() =>
                    contextMenuId && handleOnDeleteColorPinTag(contextMenuId)
                  }
                >
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};

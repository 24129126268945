import { Box, IconButton } from "@mui/material";
import MoreIcon from "../../../../../assets/images/more";
import { Colors } from "../../../../../configs";
import { FC } from "react";
import GarmentTypeSection from "../../../../common/pos/garment-type-section/garment-type-section";
import GarmentModificationSection from "../../../../common/pos/garment-modification-section/garment-modification-section";
import ReplayIcon from "@mui/icons-material/Replay";
import { useAppSelector } from "../../../../../hooks";
import AlterationSecondSubSelection from "../../../../common/pos/alteration-second-sub-section/alteration-second-sub-section";
import { Link, useParams } from "react-router-dom";
import { CustomButton } from "../../../../atoms";

const Alteration: FC = () => {
  const params = useParams();
  const { selectedCartItem } = useAppSelector((state) => state.cart);

  const isDisabled = () => {
    return selectedCartItem?.alterationItems.some(
      (item) => !item.alterationPrice || !item.alterationSubCategory
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.71rem",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ flex: 8 }}>
          <GarmentTypeSection />
        </Box>
        <Box sx={{ flex: 4 }}>
          <GarmentModificationSection />
        </Box>
      </Box>
      <AlterationSecondSubSelection />
      {/* Alteration Second Sub Selection */}
      <Box
        sx={{
          marginTop: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0rem 0.928571429rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "2.5rem",
          }}
        >
          <IconButton
            style={{
              width: "6.39rem",
              height: "5.85rem",
              borderRadius: "50%",
            }}
          >
            <MoreIcon color={Colors.ACCENT_GRAY_DISABLED} size={45} />
          </IconButton>

          <Link to={`/pos/customer/pickup/${params.id}/`}>
            <CustomButton
              sx={{
                width: "18.92rem",
                height: "5.85rem",
                background: "none",
                border: `0.214285714rem solid ${Colors.TEXT_GRAY_DARK}`,
                color: Colors.TEXT_GRAY_DARK,
                fontSize: "2.57rem",
                lineHeight: "3rem",
                padding: "1.15142857rem 3rem",
                borderRadius: "0.71rem",
                textTransform: "capitalize",
                fontWeight: 400,
              }}
            >
              Pickups
            </CustomButton>
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1.21428571rem",
            height: "5.85rem",
          }}
        >
          <IconButton
            sx={{
              height: "100%",
              aspectRatio: "1/1",
              background: Colors.SECONDARY,
              borderRadius: "0.71rem",
              "&:hover": {
                background: `${Colors.SECONDARY} !important`,
              },
            }}
          >
            <ReplayIcon style={{ fontSize: "3rem", color: Colors.WHITE }} />
          </IconButton>
          <CustomButton
            onClick={() => {}}
            disabled={isDisabled()}
            sx={{
              background: Colors.PRIMARY,
              color: Colors.WHITE,
              fontSize: "2.57142857rem",
              lineHeight: "3rem",
              padding: "1.15142857rem 3rem",
              borderRadius: "0.71rem",
              textTransform: "capitalize",
              fontWeight: 400,
              height: "100%",
              whiteSpace: "nowrap",
              "&:hover": {
                background: `${Colors.PRIMARY} !important`,
              },
            }}
          >
            Add Item
          </CustomButton>
        </Box>
      </Box>
    </>
  );
};

export default Alteration;

import { FC, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import { Colors } from "../../../configs";

import PickUpLayout from "../../../components/layouts/pos-layout/PickUpLayout";
import CustomerDetailSection from "../../../components/common/pos/customer-detail-section/customer-detail-section";
import OrderDetailSection from "../../../components/common/pos/order-detail-section/order-detail-section";
import CartSection from "../../../components/common/pos/cart-section/cart-section";
import { useAppDispatch } from "../../../hooks";
import { CustomerSliceAction } from "../../../redux/slice/customerSlice";
import { CustomButton } from "../../../components/atoms";

export interface IPickupProps {}

const Pickup: FC<IPickupProps> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(CustomerSliceAction.clearCustomerNotes());
    };
  }, []);
  return (
    <PickUpLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "2.28571429rem",
          height: "100%",
          gap: "0.928571429rem",
        }}
      >
        <Box
          sx={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
            gap: "1.07142857rem",
            height: "100%",
            maxHeight: "100% !important",
          }}
        >
          <CustomerDetailSection
            showAnalysisButton={true}
            showEditButton={true}
            showHistoryButton={true}
          />
          <CartSection onSelect={() => {}} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1.07142857rem",
              flexShrink: 0,
            }}
          >
            <OrderDetailSection />
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.71rem" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: "0.71rem",
                  padding: "2.21428571rem",
                  background: Colors.BACKGROUND_GRAY,
                  borderRadius: "0.71rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.71428571rem",
                    color: Colors.TEXT_GRAY_DARK,
                    lineHeight: "1.71428571rem",
                  }}
                >
                  Invoice Status
                </Typography>
                <Typography
                  sx={{
                    fontSize: "2.85714286rem",
                    color: Colors.TEXT_GRAY_DARK,
                    lineHeight: "2.85714286rem",
                    fontWeight: 400,
                  }}
                >
                  Not Paid
                </Typography>
              </Box>
              <CustomButton
                sx={{
                  width: "100%",
                  fontSize: "2.28571429rem",
                  borderRadius: "0.71rem",
                  background: Colors.PRIMARY,
                  color: Colors.WHITE,
                  textTransform: "capitalize",
                  flexGrow: 1,
                  padding: "0rem 8.21428571rem",
                }}
              >
                Check Out
              </CustomButton>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ width: "0.142857143rem", background: Colors.ACCENT_GRAY }}
        ></Box>
        <Box
          sx={{
            flex: 5,
            display: "flex",
            flexDirection: "column",
            gap: "1.42857143rem",
          }}
        >
          <Box
            sx={{
              flex: 1,
              padding: "1.07142857rem",
              background: Colors.BACKGROUND_GRAY,
              borderRadius: "0.71rem",
            }}
          >
            table
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", gap: "1.42857143rem" }}
          >
            <Box
              sx={{
                borderRadius: "0.71rem",
                padding: "1.64285714rem",
                background: Colors.BACKGROUND_GRAY,
                flex: 5,
              }}
            >
              details
            </Box>
            <Box
              sx={{
                borderRadius: "0.71rem",
                padding: "2.35714286rem",
                background: Colors.BACKGROUND_GRAY,
                flex: 9,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <CustomButton
                sx={{
                  width: "calc((100% - (1*1rem))/2)",
                  height: "calc((100% - (2*1rem))/3)",
                  borderRadius: "0.71rem",
                  padding: "1.64285714rem",
                  color: Colors.WHITE,
                  fontSize: "1.71428571rem",
                  background: Colors.SECONDARY,
                  textTransform: "capitalize",
                }}
              >
                Pre Pay - Not Pickup
              </CustomButton>
              <CustomButton
                sx={{
                  width: "calc((100% - (1*1rem))/2)",
                  height: "calc((100% - (2*1rem))/3)",
                  borderRadius: "0.71rem",
                  padding: "1.64285714rem",
                  color: Colors.WHITE,
                  fontSize: "1.71428571rem",
                  background: Colors.PRIMARY,
                  textTransform: "capitalize",
                }}
              >
                Pay and Pickup
              </CustomButton>
              <CustomButton
                sx={{
                  width: "calc((100% - (1*1rem))/2)",
                  height: "calc((100% - (2*1rem))/3)",
                  borderRadius: "0.71rem",
                  padding: "1.64285714rem",
                  color: Colors.WHITE,
                  fontSize: "1.71428571rem",
                  background: Colors.MAIN_GRAY,
                  textTransform: "capitalize",
                }}
              >
                Print invoice Location
              </CustomButton>
              <CustomButton
                sx={{
                  width: "calc((100% - (1*1rem))/2)",
                  height: "calc((100% - (2*1rem))/3)",
                  borderRadius: "0.71rem",
                  padding: "1.64285714rem",
                  color: Colors.WHITE,
                  fontSize: "1.71428571rem",
                  background: Colors.MAIN_GRAY,
                  textTransform: "capitalize",
                }}
              >
                Add retail item
              </CustomButton>
              <CustomButton
                sx={{
                  width: "calc((100% - (1*1rem))/2)",
                  height: "calc((100% - (2*1rem))/3)",
                  borderRadius: "0.71rem",
                  padding: "1.64285714rem",
                  color: Colors.WHITE,
                  fontSize: "1.71428571rem",
                  background: Colors.MAIN_GRAY,
                  textTransform: "capitalize",
                }}
              >
                Partial Pickup
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </PickUpLayout>
  );
};

export default Pickup;

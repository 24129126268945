import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { Colors } from "../../../configs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import { CustomButton, CustomTextBox } from "../../atoms";
import "./notesCard.css";
import {
  NoteOccasionLabels,
  NoteOccasion,
} from "../../../typings/enum/noteOccasions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useParams } from "react-router-dom";
import {
  deleteUserNoteAction,
  getUserNotesByIdAction,
  updateUserNoteAction,
} from "../../../redux/actions/customerAction";
import ConfirmDialog from "../../atoms/confirm-box/confirm-box";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";

export interface INotesCardProps {
  noteId: number;
  description: string;
  noteBy: string;
  date: Date | null;
  occasions: NoteOccasion[];
}

const UserNotesCard: FC<INotesCardProps> = ({
  noteId,
  description,
  noteBy,
  date,
  occasions,
}) => {
  const [note, setNote] = useState(description);
  const [selectedOccations, setSelectedOccations] =
    useState<NoteOccasion[]>(occasions);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const params = useParams();
  const customerId = params.id;
  const { user } = useAppSelector((state) => state.auth);
  const snackbar = useSnackbarContext();

  interface IHandleExpandChangeProps {
    newExpanded?: boolean;
  }

  const handleExpandChange = ({
    newExpanded,
  }: IHandleExpandChangeProps = {}) => {
    if (newExpanded !== undefined) {
      setExpanded(newExpanded);
    } else {
      setExpanded((prevExpanded) => !prevExpanded);
    }
  };
  const handleOnCheckBoxChange = (key: string) => {
    const index = selectedOccations.indexOf(key as NoteOccasion);
    if (index > -1) {
      setSelectedOccations(selectedOccations.filter((item) => item !== key));
    } else {
      setSelectedOccations([...selectedOccations, key as NoteOccasion]);
    }
  };
  const handleNoteEdit = (newNote: string) => {
    setNote(newNote);
  };
  const mapOccations = () => {
    return Object.keys(NoteOccasion).map((key) => (
      <FormControlLabel
        control={<Checkbox />}
        label={NoteOccasionLabels[key as keyof typeof NoteOccasion]}
        sx={{
          "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
          height: "1.55rem",
          width: "13rem",
          // background: "blue",
        }}
        key={key} // You should also include a key for each mapped element
        value={key}
        checked={selectedOccations.includes(key as NoteOccasion)}
        onChange={() => handleOnCheckBoxChange(key)}
      />
    ));
  };

  const handleOnUpdateNote = async () => {
    if (note.length > 0) {
      if (customerId && user) {
        await dispatch(
          updateUserNoteAction({
            id: noteId,
            note: note,
            occasions: selectedOccations as NoteOccasion[],
            // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
            merchantUserId: 1,
            customerId: Number(customerId),
          })
        ).then((response) => {
          // if (response) {
          //   console.log(JSON.stringify(response));
          // } else {
          // }
        });
        dispatch(getUserNotesByIdAction(customerId as string));
      }
    } else {
      snackbar.showSnackbar("Cannot add empty notes", "warning");
    }
  };

  const handleOnDeleteNote = async () => {
    if (customerId && user) {
      await dispatch(deleteUserNoteAction(noteId.toString())).then(
        (response) => {
          // if (response) {
          //   console.log(JSON.stringify(response));
          // } else {
          // }
        }
      );
      dispatch(getUserNotesByIdAction(customerId as string));
    }
  };

  return (
    <>
      <ConfirmDialog
        open={deleteConfirmation}
        title="Delete Confirmation"
        message={<Typography>Do you want to delete this note?</Typography>}
        positiveButtonText="Yes"
        negativeButtonText="No"
        onCancel={() => setDeleteConfirmation(false)}
        onConfirm={() => handleOnDeleteNote()}
      />
      <Box
        sx={{
          borderRadius: "0.714285714rem",
          padding: "1.35232143rem",
          background: Colors.BACKGROUND_GRAY,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "2.28571429rem",
          paddingLeft: "2.28571429rem",
          // marginTop: "0.91rem",
        }}
      >
        <IconButton
          sx={{
            background: Colors.SECONDARY,
            borderRadius: "0.303571429rem",
            width: "2.28571429rem",
            height: "2rem",
            boxShadow: `0rem 0.214285714rem 0.285714286rem rgba(0, 0, 0, 0.25)`,
            color: Colors.WHITE,
          }}
          onClick={() => setDeleteConfirmation(true)}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            gap: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.1rem",
              fontWeight: 500,
            }}
          >
            {description}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1.57142857rem",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: Colors.SECONDARY, marginRight: "auto" }}>
              Added by {noteBy}
            </Typography>
            <Box
              sx={{
                color: Colors.SECONDARY,
                gap: "0.714285714rem",
                alignItems: "center",
                display: date === null ? "none" : "flex",
              }}
            >
              <CalendarMonthIcon
                sx={{ display: date === null ? "none" : "inline-flex" }}
              />
              <Typography>{date?.toLocaleDateString("en-US")}</Typography>
            </Box>
            <IconButton
              sx={{
                width: "1.71428571rem",
                height: "1.71428571rem",
                borderRadius: "0.214285714rem",
                color: Colors.WHITE,
                background: Colors.SECONDARY,
              }}
              onClick={() => handleExpandChange()}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box
        className={
          expanded ? " collapsingSection" : "collapsedSection collapsingSection"
        }
        sx={{
          transition: "all 0.5s",
          marginBottom: "13.75rem",
          marginTop: "0.91rem",
          height: "8.17rem",
        }}
      >
        <Box
          sx={{
            borderRadius: "0.714285714rem",
            padding: "1.35232143rem",
            background: Colors.BACKGROUND_GRAY,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "1.86rem",
            marginBottom: "0.91rem",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <CustomTextBox
              multiline
              sx={{
                width: "100%",
                background: Colors.WHITE,
                border: "none",
                "& .MuiInputBase-input": {
                  height: "4.5rem",
                  minHeight: "4.5rem",
                  maxHeight: "4.5rem",
                },
              }}
              value={note}
              onChange={(event) => handleNoteEdit(event.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "1.75rem",
              rowGap: "0.38rem",
              // paddingInline: "6rem",
              // background: "red",
            }}
          >
            {/* <FormControlLabel
              control={<Checkbox defaultChecked />}
              label={<span>Occasion 1</span>}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
                height: "1.55rem",
              }}
            />
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label={<span>Occasion 2</span>}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
                height: "1.55rem",
              }}
            />
            <FormControlLabel
              control={<Checkbox />}
              label={<span>Occasion 3</span>}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
                height: "1.55rem",
              }}
            />
            <FormControlLabel
              control={<Checkbox />}
              label={<span>Occasion 4</span>}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
                height: "1.55rem",
              }}
            />
            <FormControlLabel
              control={<Checkbox />}
              label={<span>Occasion 5</span>}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
                height: "1.55rem",
              }}
            />
            <FormControlLabel
              control={<Checkbox />}
              label={<span>Occasion 6</span>}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: "1.57rem" },
                height: "1.55rem",
              }}
            /> */}
            {mapOccations()}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "row",
              gap: "0.57rem",
              width: "100%",
            }}
          >
            <IconButton
              type="button"
              sx={{
                border: `0.0714285714rem solid ${Colors.MAIN_GRAY}`,
                borderRadius: "0.214285714rem",
                background: "none",
                height: "2.62571429rem",
                aspectRatio: "1/1",
              }}
              onClick={() => setDeleteConfirmation(true)}
            >
              <DeleteIcon
                sx={{
                  color: Colors.MAIN_GRAY,
                  fontSize: "1.42857143rem",
                  marginTop: "-0.0714285714rem",
                }}
              />
            </IconButton>
            <CustomButton
              type="button"
              onClick={() => {
                handleExpandChange({ newExpanded: false });
              }}
              sx={{
                border: `0.0714285714rem solid ${Colors.MAIN_GRAY}`,
                borderRadius: "0.214285714rem",
                background: "none",
                color: Colors.MAIN_GRAY,
                fontSize: "0.91rem",
                fontWeight: "600",
                padding: "0.46rem 2.31rem",
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="button"
              sx={{
                border: "none",
                borderRadius: "0.214285714rem",
                background: "#018752",
                color: "#f8f8f8",
                fontSize: "0.91rem",
                fontWeight: "600",
                padding: "0.46rem 2.31rem",
              }}
              disabled={note.length === 0}
              onClick={handleOnUpdateNote}
            >
              Update
            </CustomButton>
          </Box>
        </Box>
        <Box
          sx={{
            width: "5.31rem",
            height: "0rem",
            borderTop: "0.142857143rem solid #d9d9d9",
            margin: "0.357142857rem auto",
          }}
        ></Box>
      </Box>
    </>
  );
};

export default UserNotesCard;

import React from "react";
import Button from "@mui/material/Button";
import LockIcon from "@mui/icons-material/Lock";
import ReplyIcon from "@mui/icons-material/Reply";

interface Props {
  variation: "Lock" | "Back";
  onClick?: () => void;
}

const TopRightButton: React.FC<Props> = ({ variation, onClick }) => {
  const icon =
    variation === "Lock" ? (
      <LockIcon sx={{ fontSize: "2.85714286rem" }} />
    ) : (
      <ReplyIcon sx={{ fontSize: "3.57142857rem" }} />
    );
  // const buttonText = variation === 'Lock' ? 'Lock' : 'Back';

  return (
    <Button
      variant="contained"
      sx={{
        position: "fixed",
        top: "3.57142857rem",
        right: variation === "Lock" ? "0" : "0",
        width: variation === "Lock" ? "5rem" : "5rem",
        height: variation === "Lock" ? "5rem" : "4.07142857rem",
        marginRight: variation === "Lock" ? "1.42857143rem" : "0",
        backgroundColor: "#930000",
        "&:hover": {
          backgroundColor: "#930000",
        },
        color: "white",
        borderRadius:
          variation === "Lock"
            ? "1.07142857rem"
            : "1.07142857rem 0 0 1.07142857rem",
        zIndex: "999",
      }}
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};

export default TopRightButton;

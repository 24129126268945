import { FC, useCallback, useEffect, useState } from "react";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../../configs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { DraggableCard } from "../../atoms/draggable-card/draggable-card";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import {
  getAllColorPinTagsAction,
  reOrderColorPinTagAction,
} from "../../../redux/actions/pintagAction";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { IColorPintag } from "../../../typings/interfaces/pos/pintag";
import { IReOrderColorPintagRequest } from "../../../typings/interfaces/requests/pintag-requests";
import { CustomButton } from "../../atoms";

interface IReOrderPinTagsDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  storeId: number;
}
interface ITag {
  color: string;
  description: string;
  identification: string;
}

export const ReOrderPinTagsDrawer: FC<IReOrderPinTagsDrawerProps> = ({
  open,
  toggleDrawer,
  storeId,
}) => {
  const [tags, setTags] = useState<IColorPintag[]>([]);

  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();
  const { getColorPintags, getColorPintagsLoading, getColorPintagsError } =
    useAppSelector((state) => state.pintag);

  useEffect(() => {
    dispatch(getAllColorPinTagsAction()).catch((err) => {
      snackbar.showSnackbar(err.message, "error");
    });
  }, []);
  useEffect(() => {
    if (getColorPintags) {
      // Create a copy of the array before sorting
      const sortedTags = [...(getColorPintags.body ?? [])].sort(
        (a, b) => a.orderIndex - b.orderIndex
      );
      setTags(sortedTags);
    }
  }, [getColorPintags]);

  const handleOnChange = () => {
    if (tags.length === 0) {
      snackbar.showSnackbar("Empty Array Detected", "error");
    } else {
      const reorderedTags: IReOrderColorPintagRequest = tags.map((tag) => ({
        id: tag.id,
      }));
      dispatch(reOrderColorPinTagAction(reorderedTags))
        .unwrap()
        .then(() => {
          snackbar.showSnackbar("Reordering was successful!", "success");
          dispatch(getAllColorPinTagsAction());
        })
        .catch((err) => {
          snackbar.showSnackbar(err.message, "error");
        });
    }
  };

  const handleOnSubmit = () => {};

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setTags((prevCards: IColorPintag[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as IColorPintag],
        ],
      })
    );
    handleOnChange();
  }, []);

  const renderCard = useCallback((card: IColorPintag, index: number) => {
    return (
      <DraggableCard
        key={index}
        index={index}
        id={index}
        text={card.description}
        color={card.color}
        moveCard={moveCard}
      />
    );
  }, []);
  return (
    <CustomDrawer
      isOpen={open}
      toggleDrawer={toggleDrawer}
      sx={{ width: "34.2857143rem" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3.57142857rem",
        }}
      >
        <IconButton
          onClick={toggleDrawer}
          sx={{
            alignSelf: "flex-end",
            background: "none",
            border: `0.2rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            width: "2.5rem",
            height: "2.5rem",
          }}
        >
          <CloseIcon
            sx={{ fontSize: "1.8rem", color: Colors.ACCENT_GRAY_DISABLED }}
          />
        </IconButton>
        <Typography
          fontWeight={600}
          color={Colors.TEXT_GRAY_DARK}
          sx={{ fontSize: "2rem" }}
        >
          Order Pintags
        </Typography>
        <Box
          sx={{
            height: "53rem",
            width: "100%",
            overflowY: "auto",
            // make scrollbar style looks better
            "&::-webkit-scrollbar": {
              width: "0.5rem",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              {tags.map((tag, i) => renderCard(tag, i))}
            </Box>
          </DndProvider>
        </Box>
        <CustomButton
          sx={{ fontSize: "1.5rem", py: "0.65rem" }}
          onClick={handleOnSubmit}
        >
          Save
        </CustomButton>
      </Box>
    </CustomDrawer>
  );
};

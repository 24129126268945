import { FC } from "react";
import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import { Box, Typography } from "@mui/material";
import { CustomButton } from "../../../../components";
import CustomTabPanel from "../../../../components/atoms/custom-tab-panel/customTabPanel";
import { PinTagColors } from "./pin-tag-colors";
import { PinTagServices } from "./pin-tag-services";
import { Colors } from "../../../../configs";

export interface IPinTagConfigPageProps {}

export const PinTagConfigPage: FC<IPinTagConfigPageProps> = () => {
  return (
    <AdminLayout>
      <Box>
        <Typography
          sx={{ mb: "1rem" }}
          variant="h3"
          fontWeight={500}
          color={Colors.TEXT_GRAY_DARK}
        >
          Pin Tag Config
        </Typography>
        <CustomTabPanel
          tabs={[
            { label: "PinTag Colors", content: <PinTagColors /> },
            {
              label: "PinTag Books",
              content: <PinTagServices />,
            },
          ]}
        />
      </Box>
    </AdminLayout>
  );
};

import { FC } from "react";
import { Box, Tooltip, Zoom, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

import { Colors } from "../../../configs";

export interface ISingleDetailProps {
  label: string;
  value: string | number;
  numberOfColumns?: number;
  color?: string;
  onClick?: () => void;
}

const SingleDetail: FC<ISingleDetailProps> = ({
  label,
  value,
  numberOfColumns = 1,
  color = Colors.PRIMARY,
  onClick,
}) => {
  return (
    <Tooltip
      title={<Typography style={{ fontSize: "1rem" }}>{value}</Typography>}
      placement="top"
      TransitionComponent={Zoom}
      leaveDelay={100}
      disableFocusListener
      arrow
    >
      <Box
        sx={{
          background: Colors.WHITE,
          flexDirection: "row",
          borderRadius: "0.57rem",
          alignItems: "center",
          fontSize: "1rem",
          width:
            "calc((100% - " +
            (numberOfColumns > 1 ? (numberOfColumns - 1) * 1.78571429 : 0) +
            "rem) / " +
            numberOfColumns +
            ")",
          boxSizing: "border-box",
          display: "flex",
          alignSelf: "stretch",
          cursor: onClick ? "pointer" : "auto",
        }}
        onClick={onClick ? onClick : undefined}
      >
        <Box
          sx={{
            background: color,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "0.57rem",
            padding: "0.57rem 0.928571429rem",
            paddingRight: "1.14rem",
            color: Colors.WHITE,
            minWidth: "11.4285714rem",
            boxSizing: "border-box",
            height: "100%",
          }}
        >
          <Typography style={{ fontSize: "1rem", lineHeight: "1rem" }}>
            {label}
          </Typography>
          {onClick && <LaunchIcon />}
        </Box>
        <Box
          sx={{
            padding: "0.60rem 0.928571429rem 0.60rem 1.14rem",
            width: "calc(100% - 11.4285714rem)",
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              fontSize: "1rem",
              lineHeight: "1rem",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: Colors.BLACK,
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default SingleDetail;

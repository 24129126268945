import { FC } from "react";
import CustomModal from "../../custom-modal/custom-modal";
import { PinTagsSection } from "./pin-tags-section";
import { Box, Typography } from "@mui/material";
import { CustomButton, CustomTextBox } from "../../../atoms";
import { Colors } from "../../../../configs";

export interface IAssignPinTagModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceTypeId: number;
  itemsCount: number;
  sessionId: string;
  onSubmit: () => void;
}

export const AssignPinTagModal: FC<IAssignPinTagModalProps> = ({
  isOpen,
  onClose,
  serviceTypeId,
  itemsCount,
  sessionId,
  onSubmit,
}) => {
  return (
    <CustomModal
      title="Assign Pintag"
      openModal={isOpen}
      onClose={onClose}
      width="45.5rem"
      height="48.1935714rem"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4.32928571rem",
          mt: "3rem",
        }}
      >
        <PinTagsSection
          serviceTypeId={serviceTypeId}
          itemsCount={itemsCount}
          sessionId={sessionId}
          isPintagModalSection={true}
        />
        <CustomButton
          sx={{
            ml: "auto",
            width: "21.6428571rem",
            flexGrow: 1,
            py: "0.3rem",
            fontSize: "1.5rem",
          }}
          onClick={onSubmit}
        >
          Done
        </CustomButton>
      </Box>
    </CustomModal>
  );
};
